<template>
    <div  class="common-wrap" >
<!--        <div>-->
<!--            <el-input v-model="content"></el-input>-->
<!--        </div>-->

<!--        <div >-->

         <div  class="content-item-wrap" >
             <template v-for="(item,index) of contentList" >
                 <div class="text-wrap"  v-if="item.type==='text'" :key="'text_'+index">
                     <div>{{item.content}}</div>
                     <div class="edit-btns">
                         <el-button  v-if="editProp" @click="editContent(index,'text')" type="primary" size="mini" icon="el-icon-edit" circle></el-button>
                         <el-button  v-if="editProp" @click="deleteContent(index)" type="danger" size="mini" icon="el-icon-delete" circle></el-button>
                     </div>
                 </div>
                 <div v-if="item.type==='image'" class="img-wrap" :key="'image_'+index">
                     <span v-if="!!item.header">{{item.header}}</span>
                     <img    :src="item.content" />
                     <span v-if="!!item.remark">{{item.remark}}</span>
                     <div class="edit-btns">
                         <el-button  v-if="editProp" @click="editContent(index,'image')" type="primary" size="mini" icon="el-icon-edit" circle></el-button>
                         <el-button  v-if="editProp" @click="deleteContent(index)" type="danger" size="mini" icon="el-icon-delete" circle></el-button>
                     </div>
                 </div>
                 <div v-if="item.type==='table'"  :key="'table_'+index">
                     <div  class="table-wrap">
                         <span v-if="!!item.header">{{item.header}}</span>
                         <table :border="1"  >

                             <tr v-for="(tableItem,tableIndex) of item.content" :key="'table_'+tableIndex">
                                 <td v-for="(subItem,subIndex) of tableItem.columns" :key="'table_'+subIndex+'_'+tableIndex">
                                     {{subItem.value}}
                                 </td>
                             </tr>
                         </table>
                         <span v-if="!!item.remark">{{item.remark}}</span>
                     </div>
                     <div class="edit-btns">
                         <el-button  v-if="editProp" @click="editContent(index,'table')" type="primary" size="mini" icon="el-icon-edit" circle ></el-button>
                         <el-button  v-if="editProp" @click="deleteContent(index)" type="danger" size="mini" icon="el-icon-delete" circle></el-button>
                     </div>
                 </div>

             </template>


         </div>


        <div class="action-wrap">
            <rich-fullscreen ref="richFullscreen"/>
            <rich-text-input   ref="richTextInput" @ok="contentInputOk"/>
            <rich-text-image  ref="richTextImage" @ok="contentInputOk"/>
            <rich-text-table  ref="richTextTable" @ok="contentInputOk"/>
        </div>

        <div class="action-btns"  v-if="editProp">
                <el-button v-if="paragraphProp" @click="addContent('text')" size="mini" type="primary" icon="el-icon-edit">添加段落</el-button>
                <el-button v-if="imageProp" @click="addContent('image')" size="mini" type="primary" icon="el-icon-picture-outline">添加图片</el-button>
                <el-button v-if="tableProp" @click="addContent('table')" size="mini" type="primary" icon="el-icon-s-grid">添加表格</el-button>
                <el-button @click="showFullscreen" size="mini" type="primary" icon="el-icon-full-screen">预览</el-button>
        </div>


<!--        <div :class="pageScrollFlag?'page-action-btns':'action-btns'"  :style="{top:scollTop}">-->
<!--            <template v-if="editProp">-->
<!--                <el-button v-if="paragraphProp" @click="addContent('text')" size="mini" type="primary" icon="el-icon-edit">添加段落</el-button>-->
<!--                <el-button v-if="imageProp" @click="addContent('image')" size="mini" type="primary" icon="el-icon-picture-outline">添加图片</el-button>-->
<!--                <el-button v-if="tableProp" @click="addContent('table')" size="mini" type="primary" icon="el-icon-s-grid">添加表格</el-button>-->
<!--                <el-button @click="showFullscreen" size="mini" type="primary" icon="el-icon-full-screen">预览</el-button>-->
<!--            </template>-->
<!--        </div>-->


    </div>
</template>

<script>
    import RichTextImage from "./RichTextImage";
    import RichTextTable from "./RichTextTable";
    import RichTextInput from "./RichTextInput";
    import RichFullscreen from "./RichFullscreen";
    /**
     * 总共3中格式类型
     */
    export default {
        name: "CommonRichText",
        props:{
          editProp:{
              type:Boolean,
              default:true
          },
          // 段落添加
          paragraphProp:{
              type:Boolean,
              default:true
          },
          // 图片添加
          imageProp:{
              type:Boolean,
              default:true
          }  ,
          // 表格添加
          tableProp:{
              type:Boolean,
              default:true
          }
        },
        components: {RichFullscreen, RichTextInput, RichTextTable, RichTextImage},
        data(){
            return {
                contentList:[],
                // 组件类型
                type:'',
                globalIndex:-1,
                scollTop:0,
                pageScrollFlag:false
            }
        },
        mounted(){
            // window.addEventListener('scroll',this.pageScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
        },
        methods:{
            // handleScroll(){
            //     let aa=this.$el
            //     if (!this.pageScrollFlag) {
            //         this.scollTop=aa.scrollTop+'px';
            //
            //     }
            //
            //     console.log(aa.scrollTop)
            // },
            // pageScroll(){
            //     var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            //     if (scrollTop >= 381) {
            //         this.scrollTop="381px"
            //         this.pageScrollFlag=true
            //
            //     }else{
            //         this.pageScrollFlag=false
            //     }
            // },
            // 设置数据
            setData(e){
                this.contentList=e
            },
            // 组件初始化
            init(nodeContent=''){

                this.$refs.richTextInput.hide()
                this.contentList=[]
                if(typeof  nodeContent =='object'){
                    this.contentList=nodeContent
                }

                this.type=""
                this.globalIndex=-1
            },
            /**
             * 组件添加完成
             * @param content 内容
             * @param remark 备注
             * @param action 操作
             * @param header 表头
             */
            contentInputOk(content,remark,action,header){
                console.log(header)
                if(action==='add'){
                    // 添加组件
                    let type=this.type
                    this.contentList.push({
                        type:type,
                        content:content,
                        remark:remark,
                        header:header
                    })
                }else if(action==='edit'){
                    // 编辑组件
                    let globalIndex=this.globalIndex
                    let data=this.contentList[globalIndex]
                    data.content=content
                    data.remark=remark
                    data.header=header
                    this.contentList.splice(globalIndex,1,data)
                }

                this.$emit("ok",this.contentList)
            },
            // 删除内容
            deleteContent(globalIndex){
                let obj=this.contentList[globalIndex]
                let type=obj.type
                let descList={
                    text:'段落',
                    image:'图片',
                    table:'表格',
                }
                let that=this
                this.$confirm('是否删除'+descList[type]+'?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    that.contentList.splice(globalIndex,1)
                    that.$emit("ok",this.contentList)
                }).catch(() => {

                });
            },
            // 编辑组件内容
            editContent(globalIndex,type){
                this.globalIndex=globalIndex
                let content=this.contentList[globalIndex].content

                if(type==='text'){
                    this.$refs.richTextInput.edit(content)
                }
                if(type==='image'){
                    let remark=this.contentList[globalIndex].remark
                    let header=this.contentList[globalIndex].header
                    this.$refs.richTextImage.edit(content,remark,header)
                }
                if(type==='table'){
                    let remark=this.contentList[globalIndex].remark
                    let header=this.contentList[globalIndex].header
                    this.$refs.richTextTable.edit(content,remark,header)
                }
                this.$emit("ok",this.contentList)

            },
            // 添加组件数据
            addContent(type){
                this.type=type
                if(type==='text'){
                    this.$refs.richTextInput.show()
                }
                if(type==='image'){
                    this.$refs.richTextImage.show()
                }
                if(type==='table'){
                    this.$refs.richTextTable.show()
                }

            },
            // 全屏预览
            showFullscreen(){
                this.$refs.richFullscreen.show(this.contentList)
            }
        }
    }
</script>

<style scoped lang="scss">
.common-wrap{
    display: flex;
    flex-direction: column;
    max-height:800px;
    overflow: -moz-scrollbars-none;
    overflow-y:scroll;
    width: 100%;
    max-width: 600px;
    position: relative;
    .edit-btns{
        margin: 10px 0;
    }
    .text-wrap{
        width: 100%;
    }
    .img-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            object-fit: contain;
            margin-right: 10px;
            /*width:200px;*/
            max-width: 1000px;
        }
    }
    .table-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
       table{
           border-collapse: collapse;
           margin-top: 0px;
           width: 100%;
           tr {
               td{
                   padding:0 10px;
               }
           }
       }
    }

    .action-wrap{
        .rich-class{
            background: #ecf5ff;
            padding:10px;
        }
    }
    .content-item-wrap{

        padding-right: 10px;
        padding-bottom: 50px;

    }

    .action-btns{
        background: #f1f1f1;
        padding: 10px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }



}
</style>
