<template>
    <div class="wrap">
        <div class="top-wrap">
            <div class="header">
                开放时间：{{openTime}}
                <el-tag v-if="!opening" size="small" type="danger">已关闭</el-tag>
            </div>
            <div class="submit-data" v-if="alreadySubmitData">
                <slot name="rechecking"></slot>

<!--                <el-tag class="tag" size="mini" v-if="result.checkFlag===0" type="warning">待检查</el-tag>-->
<!--                <el-tag class="tag" size="mini" v-else-if="result.checkFlag===1" type="success">检查通过</el-tag>-->
<!--                <div v-else-if="result.checkFlag===2" class="reSubmit-tag">-->
<!--                    <el-tag size="mini" class="tag" type="danger">检查驳回</el-tag>-->
<!--                    <div class="feedback-wrap" v-if="!!result.feedback">驳回原因：{{result.feedback}}</div>-->
<!--                    <el-button size="mini" type="danger" @click="reSubmit">重新提交</el-button>-->
<!--                </div>-->
<!--                <div class="time-wrap">-->
<!--                    <span>提交时间:</span>-->
<!--                    <span>{{result.createTime}}</span>-->
<!--                </div>-->
                <common-tip :result="result" @reSubmit="reSubmit"/>
            </div>
            <div class="wrap-btn" v-else>
                <common-action-button v-if="!alreadySubmitData&&opening"
                                      @copyLunwenOk="handleCopyData"
                                      :load-stash="handleLoadStash"
                                      :save-stash="handleSaveStash"
                                      :commit="handleOk"/>
            </div>
        </div>


        <el-tabs class="tab-wrap" v-model="tabIndex">
            <el-tab-pane name="tab1" label="基本信息"></el-tab-pane>
            <el-tab-pane name="tab2" label="摘要"></el-tab-pane>
            <el-tab-pane name="tab3" label="引言"></el-tab-pane>
            <el-tab-pane name="tab4" label="正文"></el-tab-pane>
            <el-tab-pane name="tab5" label="参考文献"></el-tab-pane>
        </el-tabs>





        <!------------------------------------------------------ 基本信息 ------------------------------------------------->
        <div v-show="tabIndex==='tab1'" class="module">
            <h1>浙江工商大学</h1>

            <div class="title">
                <span>学生毕业论文</span>
            </div>

            <div class="content">
                <div class="row">
                    <span class="label">题目：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.title}}</span>
                    <el-input v-else v-model="baseInfo.title" placeholder="请输入题目"></el-input>
                </div>


                <div class="row">
                    <span class="label">姓名：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.name}}</span>
                    <el-input v-else v-model="baseInfo.name" placeholder="请输入姓名"></el-input>
                </div>
                <div class="row">
                    <span class="label">学号：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.stuNo}}</span>
                    <el-input v-else v-model="baseInfo.stuNo" placeholder="请输入学号"></el-input>
                </div>

                <div class="row">
                    <span class="label">学习形式：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.learnType}}</span>
                    <el-input v-else v-model="baseInfo.learnType" placeholder="请输入学习形式"></el-input>
                </div>

                <div class="row">
                    <span class="label">层次：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.level}}</span>
                    <el-input v-else v-model="baseInfo.level" placeholder="请输入层次"></el-input>
                </div>


                <div class="row">
                    <span class="label">专业：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.major}}</span>
                    <el-input v-else v-model="baseInfo.major" placeholder="请输入专业"></el-input>
                </div>


                <div class="row">
                    <span class="label">教学点、函授站：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.college}}</span>
                    <el-input v-else v-model="baseInfo.college" placeholder="请输入教学点、函授站"></el-input>
                </div>

                <div class="row">
                    <span class="label">班级</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.className}}</span>
                    <el-input v-else v-model="baseInfo.className" placeholder="请输入班级"></el-input>
                </div>


            </div>

        </div>
        <!------------------------------------------------------ 摘要   ------------------------------------------------->
        <div v-show="tabIndex==='tab2'">
            <div class="content-body">
                <div class="headline">
                    <div class="name">摘要</div>
                    <div class="notice">字数要求要求200-300字</div>
                </div>

                <div class="content-border" style="display: flex;flex-direction: column;">
                    <div class="wenxian-content" style="padding:20px">
                        <common-rich-text v-if="alreadySubmitData" :edit-prop="false" ref="abstractRichText"
                                          @ok="contentInputOk($event,'abstractText')" style="max-width: 100%;color:#606266;"/>
                        <common-rich-text v-else
                                          :image-prop="false"
                                          :table-prop="false"
                                          ref="abstractRichText" @ok="contentInputOk($event,'abstractText')" style="max-width: 100%"/>
                    </div>

                    <div class="keyword-wrap">
                        <div class="title">关键词</div>
                        <div class="notice">关键词需要填写3-8个，以分号（；）分隔</div>
                        <div class="content" v-if="alreadySubmitData">
                            关键词:{{keywordChinese}}
                        </div>
                        <el-input v-else class="content" v-model="keywordChinese" placeholder="请输入关键词"></el-input>
                    </div>
                </div>

            </div>

        </div>
        <!------------------------------------------------------ 引言 ------------------------------------------------->
        <div v-show="tabIndex==='tab3'">
            <div class="content-body">
                <div class="headline">
                    <div class="name">引言</div>
                </div>
                <div class="content-border" >
                    <div class="wenxian-content" style="padding:20px">
                        <common-rich-text v-if="alreadySubmitData" :edit-prop="false"
                                          ref="introRichText" @ok="contentIntroInputOk" style="max-width: 100%;color:#606266"/>
                        <common-rich-text :image-prop="false"
                                          :table-prop="false" v-else ref="introRichText"
                                          @ok="contentIntroInputOk" style="max-width: 100%"/>
                    </div>
                </div>
            </div>
        </div>
        <!------------------------------------------------------ 正文   ------------------------------------------------->
        <div v-show="tabIndex==='tab4'">
            <!-- 正文-->
            <common-doc-main-content v-if="alreadySubmitData" :editProp="false"
                                     notice="注意不要手动添加标题序号，系统会自动生成目录，并添加对应的序号！添加标题后，选中标题进行添加内容"
                                     ref="commonDocMainContent"
                                     title="论文正文"

            />
            <common-doc-main-content v-else ref="commonDocMainContent"
                                     notice="注意不要手动添加标题序号，系统会自动生成目录，并添加对应的序号！添加标题后，选中标题进行添加内容"
                                     title="论文正文"
            />
        </div>
        <!------------------------------------------------------ 参考文献 ------------------------------------------------->
        <div v-show="tabIndex==='tab5'">
            <div class="content-body">
                <div class="headline">
                    <div class="name">参考文献</div>
                    <div class="notice">参考文献需要填写10条以上</div>
                </div>


                <div class="content-border" style="width:800px;border:none;flex-direction: column">
                    <div class="example-wrap">
                        <div class="tip-wrap">
                            <el-tag v-for="(item,index) of tipList" :key="index" size="mini" type="danger" class="tip">
                                {{item}}
                            </el-tag>
                        </div>
                        <div class="example-item-wrap">
                            <span>例子：</span>
                            <div v-for="(item,index) in examples" :key="index">{{item}}</div>
                        </div>
                        <div style="padding:10px 20px;color:#dd6161;font-weight: bold;font-size: 20px;width:600px">
                            注意：请不要在开头添加序号，系统自动生成！一次添加一条！
                        </div>
                    </div>

                    <!--  这里是已经提交的内容显示-->
                    <div class="wenxian-content" v-if="alreadySubmitData">
                        <div class="list" v-for="(item,index) of alreadySubmitData.wenxianContentList" :key="index">
                          <span >
                            [{{index+1}}] {{item}}
                          </span>
                        </div>
                    </div>
                    <!--   这里是准备提交的内容显示-->
                    <div class="wenxian-content" v-else>
                        <div class="list" v-for="(item,index) of wenxianContentList" :key="index">
                          <span >
                            [{{index+1}}] {{item}}
                          </span>
                            <el-popconfirm
                                    @confirm="deleteWenxian(index)"
                                    title="这是一段内容确定删除吗？">
                                <i class="el-icon-close delete-icon" slot="reference" ></i>

                            </el-popconfirm>
                        </div>
                        <div class="content-input">
                            <el-input class="input" placeholder="请输入参考文献，确认按回车键" v-model="wenxianContent"
                                      @keyup.enter.native="addWenxianContent"></el-input>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import {saveLunwenContent, getCurrentLunwenContent,getLunwenStashContent,stashLunwenContent} from "@/api/lunwen.js";
    import CommonDocMainContent from '@/components/common/CommonDocMainContent'
    import CommonRichText from "../../common/CommonRichText";
    import CommonActionButton from "../../common/CommonActionButton";
    import {translateString} from "../../../api/common";
    import CommonTip from "../../common/CommonTip";
    // 丽水学院 论文模板
    export default {
        name: "gongshang_lunwen",
        props:{
            // 是否在开放时间内
            opening:{
                type:Boolean,
                default:false
            },
            node:{
                type:String,

            }
        },
        components: {CommonTip, CommonActionButton, CommonRichText, CommonDocMainContent},
        data() {
            return {
                baseInfo:{
                    // 题目
                    title: "",
                    // 姓名
                    name: "",
                    // 学号
                    stuNo: "",
                    // 学习形式
                    learnType: "",
                    // 层次
                    level: "",
                    // 专业
                    major: "",
                    // 教学点、函授站
                    college: "",
                    // 班级
                    className: ""
                },
                // 英文题目
                englishTitle: "",
                // 英文专业
                englishMajor: "",
                // 英文班级
                englishClassname: "",
                // 英文姓名
                englishName: "",
                alreadySubmitData: null,
                openTime: null,
                result: null,

                wenxianList: [],
                // 文献综述总金额
                wenxianResult: [],
                levelTitle: '',
                data: '',
                showLevelTitle: false,
                showSubLevelTitle: false,
                showEditLevelTitle: false,
                showLevelContent: false,
                levelTitleDialogVisible: false,
                nodeContent: '',

                // 当前节点数据
                currentData: '',
                // 当前节点
                currentNode: '',




                // 开题文献输入
                kaitiContent:'',
                // 开题文献内容列表
                kaitiContentList:[],

                // 标签标记
                tabIndex: 'tab1',

                // 摘要对象
                abstractObj:{
                    // 摘要中文版
                    abstractText:[],
                    // 摘要英文版
                    abstractEnglishText:[]
                },
                // 中文关键词
                keywordChinese:'',
                // 英文关键词
                keywordEnglish: '',


                // 文献内容输入
                wenxianContent: '',
                // 文献内容列表
                wenxianContentList: [],
                // 文献说明
                tipList: [
                    '期刊文献书写规范：作者，论文篇名，刊物名，出版年，卷（期），论文在刊物中的页码。',
                    '图书文献书写规范：作者，书名，出版地，出版社，出版日期，引用内容所在页。',
                ],
                // 文献例子
                examples: [
                    '王建华, 雷文, 王远亮. Schiff碱及其配合物的抑菌活性[J]. 化学通报, 2002, 65.',
                    '郑允飞, 陈文纳, 李德昌, 韦善怀, 等. Schiff碱及其配合物的应用研究进展[J]. 化工技术与开发, 2004, 33(4).'
                ],
                // 结束语
                conclusionList:[],

                // 引言
                introList:[],
                // 致谢
                thankList:[],
                menuNode:''
            };
        },

        watch:{
            node:function(e){
                this.getCheckedData();
            }
        },
        created() {
            this.getCheckedData();
            this.menuNode=this.node
        },

        methods: {
            // 将中文翻译成英文
            async translate(){

                this.$notify.info({
                    title: '消息',
                    message: '系统正在翻译，请稍后...'
                });

                let keywordChinese=this.keywordChinese
                let abstractObj = this.abstractObj;
                let abstractText=abstractObj.abstractText
                let abstractEnglishText=[]
                let flag=true
                for (let i = 0; i < abstractText.length; i++) {
                    let word=abstractText[i].content
                    let uuid=this.uuid()
                    let result=await this.handleTranslate(word)
                    if(result.data.success){
                        abstractEnglishText.push({
                            id:uuid,
                            content:result.data.result,
                            type:'text',
                            remark:''
                        })
                    }else{
                        this.$notify.error({
                            title: '错误',
                            message: '翻译失败'
                        });
                        flag=false
                        break;

                    }

                }



                if(flag){
                    let result=await this.handleTranslate(keywordChinese)
                    if (!result.data.success) {
                        this.$notify.error({
                            title: '错误',
                            message: '翻译失败'
                        });
                        return;
                    }


                    this.$notify({
                        title: '提示',
                        message: '翻译成功！',
                        type: 'success'
                    });
                    this.keywordEnglish=result.data.result
                    this.$set(this.abstractObj, 'abstractEnglishText', abstractEnglishText);
                    this.$refs.abstractEnglishRichText.setData(this.abstractObj.abstractEnglishText);
                }


            },
            handleTranslate(word){
                return new Promise(resolve => {
                    translateString({word:word}).then(res=>{
                        resolve(res)
                    })
                })
            },


            // 结束语富文本内容回调
            contentConclusionInputOk(e){
                this.conclusionList=e
            },
            // 引言富文本内容回调
            contentIntroInputOk(e){
                this.introList=e
            },
            // 致谢富文本内容回调
            contentThanksInputOk(e){
                this.thankList=e
            },

            // 富文本内容回调赋值
            contentInputOk(e,key){
                this.abstractObj[key]=e
            },
            // 删除开题文献
            deleteKaitiContent(index){
                this.kaitiContentList.splice(index,1)
            },
            // 删除文献
            deleteWenxian(index){
                this.wenxianContentList.splice(index,1)
            },
            // 获取文献数据
            getWenxinData() {
                return this.$refs.commonDocMainContent.getData();
            },
            // 获取开题数据
            getKaitiData() {
                return this.$refs.commonDocMainContentKaiti.getData();
            },
            // 添加文献内容
            addWenxianContent() {
                if (!this.wenxianContent) {
                    this.$message({
                        type: "warning",
                        message: "参考文献内容不能为空！"
                    });
                    return;
                }
                this.wenxianContentList.push(this.wenxianContent)
                this.wenxianContent = ""
            },
            uuid() {
                var s = [];
                var hexDigits = "0123456789abcdef";
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = "-";

                var uuid = s.join("");
                return uuid;
            },

            //重新提交
            reSubmit() {
                this.$refs.commonDocMainContent.setData(this.alreadySubmitData.mainBodyContent)
                // 摘要
                this.abstractObj =this.alreadySubmitData.abstractObj;
                this.$refs.abstractRichText.setData(this.alreadySubmitData.abstractObj.abstractText)
                // this.$refs.abstractEnglishRichText.setData(this.alreadySubmitData.abstractObj.abstractEnglishText)

                // 参考文献
                this.wenxianContentList = this.alreadySubmitData.wenxianContentList;

                // 引言
                this.introList = this.alreadySubmitData.introList||[];
                this.$refs.introRichText.setData(this.alreadySubmitData.introList||[])


                this.baseInfo.title = this.alreadySubmitData.baseInfo.title;
                this.baseInfo.name = this.alreadySubmitData.baseInfo.name;
                this.baseInfo.stuNo = this.alreadySubmitData.baseInfo.stuNo;
                this.baseInfo.learnType = this.alreadySubmitData.baseInfo.learnType;
                this.baseInfo.level = this.alreadySubmitData.baseInfo.level;
                this.baseInfo.major = this.alreadySubmitData.baseInfo.major;
                this.baseInfo.college = this.alreadySubmitData.baseInfo.college;
                this.baseInfo.className = this.alreadySubmitData.baseInfo.className;

                this.alreadySubmitData = null;
            },
            getCheckedData() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                this.openTime = sessionStorage.getItem("openTime");
                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    let result = res.data.result;
                    if (result) {
                        let content = result.content;
                        if (content) {
                            this.alreadySubmitData = JSON.parse(content);

                            this.$refs.commonDocMainContent.setData(this.alreadySubmitData.mainBodyContent)
                            // 摘要
                            this.$refs.abstractRichText.setData(this.alreadySubmitData.abstractObj.abstractText)
                            // this.$refs.abstractEnglishRichText.setData(this.alreadySubmitData.abstractObj.abstractEnglishText)
                            // 关键词
                            this.keywordChinese=this.alreadySubmitData.keywordChinese
                            this.keywordEnglish=this.alreadySubmitData.keywordEnglish


                            // 引言
                            let introList=this.alreadySubmitData.introList||[]
                            this.$refs.introRichText.setData(introList);

                            this.result = result;
                        }
                    }else{
                        this.reset()
                    }
                });
            },
            handleCopyData(subId)  {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = subId;
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                this.openTime = sessionStorage.getItem("openTime");
                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    let result = res.data.result;
                    if (result) {
                        let content = result.content;
                        if (content) {
                            this.alreadySubmitData = JSON.parse(content);

                            this.$refs.commonDocMainContent.setData(this.alreadySubmitData.mainBodyContent)
                            // 摘要
                            this.$refs.abstractRichText.setData(this.alreadySubmitData.abstractObj.abstractText)
                            // this.$refs.abstractEnglishRichText.setData(this.alreadySubmitData.abstractObj.abstractEnglishText)
                            // 关键词
                            this.keywordChinese=this.alreadySubmitData.keywordChinese
                            this.keywordEnglish=this.alreadySubmitData.keywordEnglish


                            // 引言
                            let introList=this.alreadySubmitData.introList||[]
                            this.$refs.introRichText.setData(introList);

                            this.result = result;
                            this.reSubmit()
                        }
                    }else{
                        this.reset()
                    }
                });
            },
            reset(){
                this.alreadySubmitData = null
                this.$refs.commonDocMainContent.setData([])
                // 摘要
                this.$refs.abstractRichText.setData([])
                // this.$refs.abstractEnglishRichText.setData(this.alreadySubmitData.abstractObj.abstractEnglishText)
                // 关键词
                this.keywordChinese=''
                this.keywordEnglish=''

                // 引言
                let introList=[]
                this.$refs.introRichText.setData(introList);

                this.result = null;
            },
            // 载入论文草稿
            handleLoadStash(){
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getLunwenStashContent({studentId, stepSubId}).then(res => {
                    if (res.data.success) {

                        let result = res.data.result;
                        let content = result.content;
                        if (content) {
                            let data = JSON.parse(content);
                            this.$refs.commonDocMainContent.setData(data.mainBodyContent)
                            // 摘要
                            this.abstractObj =data.abstractObj;
                            this.$refs.abstractRichText.setData(this.abstractObj.abstractText)
                            // 关键词
                            this.keywordChinese=data.keywordChinese
                            // 参考文献
                            this.wenxianContentList = data.wenxianContentList;

                            // 引言
                            this.introList = data.introList;
                            this.$refs.introRichText.setData(this.introList)

                            this.baseInfo.title = data.baseInfo.title;
                            this.baseInfo.name = data.baseInfo.name;
                            this.baseInfo.stuNo = data.baseInfo.stuNo;
                            this.baseInfo.learnType = data.baseInfo.learnType;
                            this.baseInfo.level = data.baseInfo.level;
                            this.baseInfo.major = data.baseInfo.major;
                            this.baseInfo.college = data.baseInfo.college;
                            this.baseInfo.className = data.baseInfo.className;

                            this.$message({
                                type: "success",
                                message: '载入成功！'
                            });
                        }
                    }else{
                        this.$message({
                            type: "success",
                            message: '载入失败:'+res.data.message
                        });
                    }


                });
            },
            // 暂存论文数据
            handleSaveStash(){
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                // 中文关键词
                let keywordChinese = this.keywordChinese;

                // 参考文献
                let wenxianContentList = this.wenxianContentList
                // 论文正文
                let mainBodyContent=this.$refs.commonDocMainContent.getData()


                // 引言
                let introList=this.introList
                let content = {
                    // 基本信息
                    baseInfo:this.baseInfo,
                    // 中文关键词
                    keywordChinese:keywordChinese,
                    // 摘要的基本信息 （中文版和英文版）
                    abstractObj:this.abstractObj,
                    // 正文内容
                    mainBodyContent:mainBodyContent,
                    // 参考文献
                    wenxianContentList:wenxianContentList,
                    // 引言
                    introList:introList

                };


                // 如果名字为2个字，则中间空一隔
                let name=content.baseInfo.name
                if (name.length === 2) {
                    let first = name.substr(0, 1);
                    let second = name.substr(1, 1);
                    content.baseInfo.name = first + " " + second;
                }

                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),

                };

                stashLunwenContent(form).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: "success",
                            message: '暂存成功'
                        });
                    } else {
                        this.$message({
                            type: "info",
                            message: res.data.message
                        });
                    }

                });
            },
            // 数据提交
            handleOk() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                // 中文关键词
                let keywordChinese = this.keywordChinese;
                // 参考文献
                let wenxianContentList = this.wenxianContentList
                // 论文正文
                let mainBodyContent=this.$refs.commonDocMainContent.getData()
                // 引言
                let introList=this.introList
                let content = {
                    // 基本信息
                    baseInfo:this.baseInfo,
                    // 中文关键词
                    keywordChinese:keywordChinese,
                    // 摘要的基本信息 （中文版和英文版）
                    abstractObj:this.abstractObj,
                    // 正文内容
                    mainBodyContent:mainBodyContent,
                    // 参考文献
                    wenxianContentList:wenxianContentList,
                    // 引言
                    introList:introList,
                };

                if(!this.checkData(content)){
                    return;
                }

                // 如果名字为2个字，则中间空一隔
                let name=content.baseInfo.name
                if (name.length === 2) {
                    let first = name.substr(0, 1);
                    let second = name.substr(1, 1);
                    content.baseInfo.name = first + " " + second;
                }

                let node = sessionStorage.getItem("menuNode");
                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),
                    node,
                    docFilePath
                };
                let _this = this;

                this.$confirm("是否提交?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    type: "warning"
                })
                    .then(() => {
                        _this.$notify.info({
                            title: '消息',
                            message: '数据提交中，请稍后...'
                        });
                        saveLunwenContent(form).then(res => {
                            if (res.data.success) {
                                _this.$message({
                                    type: "success",
                                    message: res.data.message
                                });
                            } else {
                                _this.$message({
                                    type: "info",
                                    message: res.data.message
                                });
                            }

                            this.getCheckedData();
                        });
                    })
                    .catch(() => {
                    });
            },
            checkData(content) {

                // 基本信息
                let baseInfo=content.baseInfo
                // 中文关键词
                let keywordChinese=content.keywordChinese
                // 摘要的基本信息 （中文版和英文版）
                let abstractObj=content.abstractObj
                // 正文内容
                let mainBodyContent=content.mainBodyContent
                // 参考文献
                let  wenxianContentList=content.wenxianContentList
                // 引言
                let  introList=content.introList


                let nameList={
                    title: '题目',
                    name: '姓名',
                    stuNo: '学号',
                    learnType: '学习形式',
                    level: '层次',
                    major: '专业',
                    college: '教学点、函授站',
                    className: '班级',
                }
                for (let key in baseInfo) {

                    if (key&&!baseInfo[key]) {
                        this.$message({
                            type: "warning",
                            message: nameList[key] + ":不能为空！"
                        });
                        this.tabIndex='tab1'
                        return false;
                    }
                }

                // 摘要中文版
                if (abstractObj.abstractText.length === 0) {
                    this.$message({
                        type: "warning",
                        message: '摘要内容不能为空！'
                    });
                    this.tabIndex='tab2'
                    return false;
                }

                let abstractTextArr=[]
                for (let i = 0; i < abstractObj.abstractText.length; i++) {
                    let abstractTextElement = abstractObj.abstractText[i];
                    abstractTextArr.push(abstractTextElement.content)
                }
                let s = abstractTextArr.join("");
                // 摘要中文版 字数要求


                // let tmp=s;
                // tmp=tmp.replace(/\b([a-z0-9]+)\b/gi,"a");
                // tmp=tmp.replace(/\s/g,"");
                // console.log(tmp.length)
                // if (tmp.length < 200 || tmp.length > 300) {
                //     // 判断是不是全英文的
                //     this.tabIndex='tab2';
                //     this.$message({
                //         type: "warning",
                //         message: '摘要内容字数在200-300字！'
                //     });
                //     return false;
                // }


                if(!keywordChinese){
                    this.$message({
                        type: "warning",
                        message: '关键字不能为空！'
                    });
                    this.tabIndex='tab2'
                    return false;
                }
                // keywordChinese=keywordChinese.replace(";","；")
                // let words = keywordChinese.split("；");
                // words=words.filter(a => !!a);
                // if(words.length<3 || words.length>8){
                //     this.$message({
                //         type: "warning",
                //         message: '关键词需要填写3-8个！'
                //     });
                //     this.tabIndex='tab2'
                //     return false;
                // }

                // 引言
                if (introList.length === 0) {
                    this.$message({
                        type: "warning",
                        message: '引言内容不能为空！'
                    });
                    this.tabIndex='tab3'
                    return false;
                }



                // 正文
                if (mainBodyContent.length===0) {
                    this.$message({
                        type: "warning",
                        message: '正文内容不能为空！'
                    });
                    this.tabIndex='tab4'
                    return false;
                }



                // 参考文献
                if (wenxianContentList.length === 0) {
                    this.$message({
                        type: "warning",
                        message: '参考文献内容不能为空！'
                    });
                    this.tabIndex='tab5'
                    return false;
                }

                // 参考文献要求
                if (wenxianContentList.length <10) {
                    this.$message({
                        type: "warning",
                        message: '参考文献需要10条以上！'
                    });
                    this.tabIndex='tab5'
                    return false;
                }


                return true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    /*/deep/ .el-input__inner {*/
    /*    border: none;*/
    /*    border-bottom: 1px solid #dcdfe6;*/
    /*    border-radius: 0;*/
    /*    padding-left: 0px;*/
    /*}*/

    .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;

        .top-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .header {
                /*width: 100%;*/
            }
            .submit-data {
                display: flex;
                /*flex-direction: column;*/
                align-items: center;
                /*margin-bottom: 20px;*/
                /*margin-top: 20px;*/

                .time-wrap {
                    color: #aaa;
                    /*margin-top: 10px;*/
                    font-size: 14px;
                    margin-left:10px;
                }

                .reSubmit-tag {
                    display: flex;
                    /*flex-direction: column;*/
                    align-items: center;

                    .tag {
                        /*margin-bottom: 10px;*/
                        margin-right: 10px;
                    }

                    .feedback-wrap {
                        /*margin-bottom: 10px;*/
                        color: #dd6161;
                        text-decoration: underline;
                    }
                }
            }

        }
        .tab-wrap {
            width: 100%;
            margin-top: 10px;
        }




        h1 {
            color: #004ea3;
            letter-spacing: 3px;
            text-align: center;
        }

        .title {
            // font-weight: bold;
            font-size: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                &:nth-child(1) {
                    font-weight: bold;
                }
            }
        }

        .content {
            margin-top: 20px;
            width: 600px;

            .row {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .label {
                    width: 100px;
                }

                .label-gray {
                    color: #aaa;
                }
            }
        }

        .content-border {
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 50px;
            width: 1200px;
            border: 1px solid #f1f1f1;
            display: flex;
            flex-direction: column;

            .row-border-container{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .row-border {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    padding:20px 0 0 20px;
                    width: 44%;

                    .label {
                        width: 100px;
                    }

                    .label-gray {
                        color: #aaa;
                    }
                }
            }

            .keyword-wrap{
                text-align: center;
                padding-bottom: 20px;
                .content{
                    width: 80%;
                    color:#606266;
                }
            }

            .wenxian-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .list {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /*flex-direction: column;*/

                    span {
                        color: #606266;
                        /*margin-bottom: 4px;*/
                    }
                    .delete-icon{
                        font-size: 20px;
                        color:#dd6161;
                        &:hover{
                            cursor: pointer;
                            background: #dd6161;
                            color:#ffffff;
                        }
                    }
                }

                .content-input {
                    display: flex;

                    .input {
                        flex: 1;
                    }
                }
            }


            .content-left {
                flex: 1;
                padding: 20px;

                .main-content-wrap {
                    .level-content-wrap {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                    }
                }

                .tip {
                    margin-bottom: 10px;
                }
            }

            .middle {
                width: 1px;
                background: #f1f1f1;
            }

            .content-right {
                flex: 1;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .content-right-tip {
                    display: flex;
                    color: #82848a;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .fullscreen {
                        cursor: pointer;
                        font-size: 14px;

                        &:hover {
                            color: #409EFF;
                        }
                    }
                }


                .level-content {
                    margin-top: 20px;
                    width: 100%;
                }

                .clear-content {
                    margin-top: 10px;
                    align-self: flex-end;
                }
            }
        }
    }


    .content-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        &:nth-child(even){
            background: #ecf5ff;
            padding-top: 10px;
        }
        .example-wrap{
            .tip-wrap {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .tip {
                    margin-bottom: 6px;
                }
            }

            .example-item-wrap {
                background: #f1f1f1;
                margin-top: 8px;
                color: #aaaaaa;
                font-size: 12px;
                padding: 4px;
                border-radius: 10px;
                margin-bottom: 10px;
            }
        }


        .lable {
            align-self: start;
            // color:#82848a;
        }

        .headline {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 400px;

            .name {
                font-size: 30px;
                font-weight: bold;
            }

            .notice {
                align-self: center;
                font-size: 10px;
                color: #dd6161;
                margin-top: 10px;

                &:before {
                    content: '说明：';
                }
            }
        }
    }
    .notice {
        align-self: center;
        font-size: 10px;
        color: #dd6161;
        margin-top: 10px;

        &:before {
            content: '说明：';
        }
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        color: #303133;
        font-weight: bold;

    }

    .add-level-wrap {
        margin-bottom: 20px;

        .level-input {
            display: flex;
            margin-bottom: 20px;
        }
    }
    .wrap-btn{
        /*width: 100%;*/
        text-align: right;
    }
</style>
