<template>
	<div class="wrap">
		<template v-if="!visible">
			<Header @select="select" ref="header"></Header>
			<div class="content-wrap">
				<!-- 个人信息 -->
				<user-info v-if="menuNode==-1" @nodeClick="nodeClick($event)"></user-info>
				<template v-else>
					<xuanti :opening="opening" v-if="menuNode=='node1'"></xuanti>
					<!-- 开题报告 -->
					<lunwen-template ref="lunwenTemplate"
						v-else-if="menuNode=='node2'||menuNode=='node3'||menuNode=='node4'||menuNode=='node5'||menuNode=='node6'||menuNode=='node7'">
			
					</lunwen-template>
					<!-- 论文-->
					<!--        <lunwen-template :opening="opening" :name="lunwenTemplateName" v-else-if="menuNode=='node4'||menuNode=='node5'||menuNode=='node6'"></lunwen-template>-->
					<div v-else>
						开放时间（{{openTime}}）
					</div>

				</template>
			</div>
		</template>
		
		<el-dialog top="10px" v-if="visible" :visible="visible" :close-on-click-modal="false" :show-close="false">
			<div v-html="qianmingObj.qianmingHtmlInfo"></div>
			<div style="text-align: center;display: flex;flex-direction: column;align-items: center;">
				<el-button type="primary" @click="doGetQianmingImg">提交</el-button>
				<span style="color:#aaa;margin-top: 10px;">签名完成后，再点击提交按钮</span>
			</div>
		</el-dialog>
		<el-dialog title="我的签名" width="400px" append-to-body :visible="qianmingVisible" @close="qianmingVisible=false">
			<!-- <img :src="'/api/sys/common/view/lunwen_qianming/2023-10-27/3744801440140795_1334*750.png'+qianmingedImg"/> -->
			<div style="display: flex;flex-direction: column;align-items: center;position: relative;">
				<img style="rotate: 270deg;object-fit: contain;margin-bottom: 100px" :style="{width:width+'px',height:height+'px'}"  :src="'/api/sys/common/view/'+qianmingedImg"/>
				<el-button style="position: absolute;bottom: 0;left:0;right:0;text-align: center;z-index: 999;" type="primary" @click="hanldeCommitQianming()">确认提交</el-button>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import Header from "@/components/Header";
	import UserInfo from "@/components/UserInfo";
	import Xuanti from "@/components/lunwenStep/Xuanti";
	import lunwenTemplate from '@/components/lunwenTemplate/lunwenTemplate'
	import {
		getCurrentStepSub,getQianmingInfo,getQianmingImage,commitQianming,checkQianming
	} from '@/api/lunwen.js'
	export default {
		components: {
			Header,
			UserInfo,
			Xuanti,
			lunwenTemplate
		},
		data() {
			return {
				menuNode: "-1",
				subId: null,
				opening: false,
				openTime: "",
				// 论文模版名称
				lunwenTemplateName: '',
				qianmingObj:{},
				visible:false,
				qianmingVisible:false,
				qianmingedImg:'',
				width:200,
				height:200
			};
		},
		computed: {
			isLunwen: function() {
				return this.menuNode === 'node2' || this.menuNode === 'node3' || this.menuNode === 'node4' || this
					.menuNode === 'node5' || this.menuNode === 'node6' || this.menuNode === 'node7'
			}
		},
		mounted() {
			// 菜单node  menuNode=-1为我的姓名 ，menuNode=开题报告，其他节点为论文节点
			this.menuNode = sessionStorage.getItem("menuNode");
			// 节点下的内容是否开放
			this.opening = sessionStorage.getItem("menuOpen") == "true" ? true : false;
			// 模板名称
			this.lunwenTemplateName = sessionStorage.getItem("docFtl");
			// 开放时间
			this.openTime = sessionStorage.getItem("openTime");

			this.$nextTick(() => {
				if (this.isLunwen) {
					// 设置论文模板的参数
					this.$refs.lunwenTemplate.setData(this.lunwenTemplateName, this.opening, this.menuNode)
				}

			});

			this.setStudentLunwenStepSub()
			
			// this.initQianmmingCode()
			this.handleCheckQianming()
		},
		methods: {
			handleCheckQianming(){
				let userInfo = sessionStorage.getItem("userInfo")||{};
				try{
					userInfo=JSON.parse(userInfo)
					checkQianming({studentId:userInfo.id}).then(res=>{
						if(res.data.code==200){
							let result=res.data.result
							if(result==='0'){
								getQianmingInfo({studentId:userInfo.id}).then(res=>{
									this.visible=true;
									this.qianmingObj=res.data.result
								})
							}
						}
					})
					
				}catch(e){
					sessionStorage.clear()
					this.$router.push({
						name: 'login'
					})
				}
				
			},
			// 获取论文签名信息内容
			initQianmmingCode(){
				let userInfo = sessionStorage.getItem("userInfo")||{};
				try{
					
					userInfo=JSON.parse(userInfo)
					getQianmingInfo({studentId:userInfo.id}).then(res=>{
						this.visible=true;
						this.qianmingObj=res.data.result
					})
				}catch(e){
					sessionStorage.clear()
					this.$router.push({
						name: 'login'
					})
				}
			},
			// 确认提交
			hanldeCommitQianming(){
				
				let userInfo = sessionStorage.getItem("userInfo")||{};
				try{
					userInfo=JSON.parse(userInfo)
				}catch(e){
					console.log(e)
				}
				commitQianming({code:this.qianmingObj.code}).then(res=>{
					if(res.data.code==200){
						this.$message({
						    type: "success",
						    message: '提交成功！'
						});
						this.visible=false
						this.qianmingVisible=false
						userInfo.qianmingPdf=res.data.result
						sessionStorage.setItem("userInfo",JSON.stringify(userInfo))
					}else{
						this.$message({
						    type: "error",
						    message:res.data.message
						});
					}
				})
			},
			doGetQianmingImg(){
				getQianmingImage({code:this.qianmingObj.code}).then(res=>{
					if(res.data.code==200){
						let result=res.data.result
						this.width=result.width * 2
						this.height=result.height * 2
						this.qianmingedImg=result.qianmingImg
						this.qianmingVisible=true
						
					}else{
						this.$message({
						    type: "error",
						    message:res.data.message
						});
					}
				})
			},
			nodeClick(node){
				console.log(node)
				this.$refs.header.handleSelect(node)
			},
			/** 设置当前学生步骤id **/
			setStudentLunwenStepSub() {
				try {
					let userInfo = sessionStorage.getItem("userInfo");
					if (userInfo) {
						userInfo = JSON.parse(userInfo);
						let sysStudentId = userInfo.sysStudentId;
						getCurrentStepSub(sysStudentId).then(res => {
							if (res.data.success) {
								userInfo['currentStepSubId'] = res.data.result;
								sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
							}

						})
					}

				} catch (e) {}
			},
			select(opening, openTime, stepSubId) {
				// 是否开放
				this.opening = opening;
				// 开放时间
				this.openTime = openTime;
				// 节点
				this.menuNode = sessionStorage.getItem("menuNode");
				// 子步骤id
				this.subId = stepSubId;

				// 匹配后台的freemarker模板文件
				this.lunwenTemplateName = sessionStorage.getItem("docFtl");
				this.$nextTick(() => {
					if (this.isLunwen) {
						// 设置论文模板的参数
						this.$refs.lunwenTemplate.setData(this.lunwenTemplateName, this.opening, this.menuNode)
					}

				})

			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrap {
		.content-wrap {
			padding: 20px;
			margin: 0 20px;
			border-radius: 10px;
			background-color: #ffffff;
			min-height: 650px;
		}
	}
</style>>