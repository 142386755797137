<template>
    <div class="wrap">
        <div class="top-wrap">
            <div class="header">
                开放时间：{{openTime}}
                <el-tag v-if="!opening" size="small" type="danger">已关闭</el-tag>
            </div>
            <div  v-if="alreadySubmitData">
<!--                <el-tag class="tag" size="mini" v-if="result.checkFlag===0" type="warning">待检查</el-tag>-->
<!--                <el-tag class="tag" size="mini" v-else-if="result.checkFlag===1" type="success">检查通过</el-tag>-->
<!--                <div v-else-if="result.checkFlag===2" class="reSubmit-tag">-->
<!--                    <el-tag size="mini" class="tag" type="danger">检查驳回</el-tag>-->
<!--                    <div class="feedback-wrap" v-if="!!result.feedback">驳回原因：{{result.feedback}}</div>-->
<!--                    <el-button size="mini" type="danger" @click="reSubmit">重新提交</el-button>-->
<!--                </div>-->
<!--                <div class="time-wrap">-->
<!--                    <span>提交时间:</span>-->
<!--                    <span>{{result.createTime}}</span>-->
<!--                </div>-->
                <CommonTip :result="result" @reSubmit="reSubmit"/>
            </div>
            <div class="wrap-btn" v-else>
                <common-action-button v-if="!alreadySubmitData&&opening" :load-stash="handleLoadStash" :save-stash="handleSaveStash" :commit="handleOk"/>
<!--                <el-button v-if="!alreadySubmitData&&opening" type="success"  @click="handleLoadStash">载入草稿</el-button>-->
<!--                <el-button v-if="!alreadySubmitData&&opening" type="warning"  @click="handleSaveStash">暂存草稿</el-button>-->
<!--                <el-button v-if="!alreadySubmitData&&opening" type="primary"  @click="handleOk">提交</el-button>-->
            </div>
        </div>


        <el-tabs class="tab-wrap" v-model="tabIndex">
            <el-tab-pane name="tab1" label="基本信息"></el-tab-pane>
            <el-tab-pane name="tab2" label="文献综述"></el-tab-pane>
            <el-tab-pane name="tab3" label="开题报告"></el-tab-pane>
<!--            <el-tab-pane name="tab4" label="预览">预览</el-tab-pane>-->
        </el-tabs>





        <!------------------------------------------------------基本信息 ------------------------------------------------->





        <div v-show="tabIndex==='tab1'" class="module">
            <h1>浙大宁波理工学院</h1>

            <div class="title">
                <span>毕业设计（论文）开题报告</span>
                <span>（含文献综述）</span>
            </div>

            <div class="content">
                <div class="row">
                    <span class="label">题目：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.title}}</span>
                    <el-input v-else v-model="baseInfo.title" placeholder="请输入题目"></el-input>
                </div>

                <div class="row">
                    <span class="label">姓名：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.name}}</span>
                    <el-input v-else v-model="baseInfo.name" placeholder="请输入姓名"></el-input>
                </div>
                <div class="row">
                    <span class="label">学号：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.stuNo}}</span>
                    <el-input v-else v-model="baseInfo.stuNo" placeholder="请输入学号"></el-input>
                </div>
                <div class="row">
                    <span class="label">专业班级：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.className}}</span>
                    <el-input v-else v-model="baseInfo.className" placeholder="请输入专业班级"></el-input>
                </div>
<!--                <div class="row">-->
<!--                    <span class="label">指导老师:</span>-->
<!--                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.adviser}}</span>-->
<!--                    <el-input v-else v-model="baseInfo.adviser" placeholder="请输入指导老师"></el-input>-->
<!--                </div>-->
                <div class="row">
                    <span class="label">分院：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.college}}</span>
                    <el-input v-else v-model="baseInfo.college" placeholder="请输入分院"></el-input>
                </div>
                <div class="row">
                    <span class="label" >开题日期：</span>

<!--                    <el-date-picker-->
<!--                            disabled-->
<!--                            v-if="alreadySubmitData"-->
<!--                            format="yyyy 年 MM 月 dd 日"-->
<!--                            value-format="yyyy年MM月dd日"-->
<!--                            v-model="alreadySubmitData.baseInfo.openDate"-->
<!--                            type="date"-->
<!--                            placeholder="选择日期">-->
<!--                    </el-date-picker>-->
                    <span class="label-gray"  v-if="alreadySubmitData" >{{alreadySubmitData.baseInfo.openDate}}</span>
<!--                    <el-input v-else v-model="baseInfo.openDate" placeholder="请输入开题日期"></el-input>-->
                    <el-date-picker
                            style="width: 100%"
                            v-else
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy年MM月dd日"
                            v-model="baseInfo.openDate"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>

                </div>
            </div>

        </div>


        <!------------------------------------------------------文献综述   ------------------------------------------------->


        <div v-show="tabIndex==='tab2'">
            <!-- 文献综述-->
            <common-doc-main-content v-if="alreadySubmitData" :editProp="false" ref="commonDocMainContent"
                                     notice="说明：文献综述是综合人家已经做过的相关研究和观点，并指出出处和作者
                                 ，最后做一个总的评述,即有哪些是做得好的,哪些不足,然后说明你的出发点或角度。具体要求：
                                 (1) 查阅与选题相关文献3篇以上；(2) 文献综述的字数在1500字以上。"
                                     stepName="文献综述"
                                     :title="alreadySubmitData.baseInfo.title"
            />
            <common-doc-main-content v-else ref="commonDocMainContent"
                                     notice="说明：文献综述是综合人家已经做过的相关研究和观点，并指出出处和作者
                                 ，最后做一个总的评述,即有哪些是做得好的,哪些不足,然后说明你的出发点或角度。具体要求：
                                 (1) 查阅与选题相关文献3篇以上；(2) 文献综述的字数在1500字以上。"
                                     stepName="文献综述"
                                     :title="baseInfo.title"
            />

            <!-- 文献参考综述总结-->
            <div class="content-body">
                <div class="headline">
                    <div class="name">文献综述总结</div>
                    <div class="notice">
                        文献参考综述总结是对文献综述的评价：本文献综述的内容切题、完整，层次清晰，阅读的文献比较有代表性，而且也比较多。
                    </div>
                </div>

                <div class="content-border" >
                    <div class="wenxian-content" style="padding:20px">

                        <common-rich-text v-if="alreadySubmitData" :edit-prop="false" ref="wenxianResultRichText" @ok="contentInputOk" style="max-width: 100%"/>
                        <common-rich-text v-else ref="wenxianResultRichText" @ok="contentInputOk" style="max-width: 100%"/>
<!--                        <div class="content-input">-->
<!--                            <el-input class="input" type="textarea" :rows="8" placeholder="请输入文献综述总结"-->
<!--                                      v-model="wenxianResult"></el-input>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>

            <!-- 参考文献-->
            <div class="content-body">
                <div class="headline">
                    <div class="name">参考文献</div>
                </div>
                <div style="padding:10px 20px;color:#dd6161;font-weight: bold;font-size: 20px;width:600px">
                    注意：请不要在开头添加序号，系统自动生成！一次添加一条！
                </div>

                <div class="content-border" style="width:800px;border:none">
                    <div class="wenxian-content" v-if="alreadySubmitData">
                        <div class="list" v-for="(item,index) of alreadySubmitData.wenxianContentList" :key="index">
                          <span >
                            [{{index+1}}].{{item}}
                          </span>
                        </div>
                    </div>
                    <div class="wenxian-content" v-else>
                        <div class="list" v-for="(item,index) of wenxianContentList" :key="index">
                          <span >
                            [{{index+1}}].{{item}}
                          </span>
                            <el-popconfirm
                                    @confirm="deleteWenxin(index)"
                                    title="这是一段内容确定删除吗？">
                                <i class="el-icon-close delete-icon" slot="reference" ></i>

                            </el-popconfirm>
                        </div>
                        <div class="content-input">
                            <el-input class="input" placeholder="请输入参考文献，确认按回车键" v-model="wenxianContent"
                                      @keyup.enter.native="addWenxianContent"></el-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!------------------------------------------------------开题报告 ------------------------------------------------->
        <div v-show="tabIndex==='tab3'">

            <!--开题报告-->
            <common-doc-main-content v-if="alreadySubmitData" :editProp="false" ref="commonDocMainContentKaiti"
                                     stepName="开题报告"
                                     notice="说明：开题报告的内容包括选题的背景和意义、研究内容（论文提纲）、研究的重点与难点分析、研究方法、实施进度计划、参考文献等。
具体要求：（1）字数在1500字左右；（2）在文献综述的基础上，根据选题拟定切实可行的论文写作提纲。"
                                     :title="alreadySubmitData.baseInfo.title"
            />
            <!--开题报告-->
            <common-doc-main-content v-else ref="commonDocMainContentKaiti"
                                     stepName="开题报告"
                                     notice="说明：开题报告的内容包括选题的背景和意义、研究内容（论文提纲）、研究的重点与难点分析、研究方法、实施进度计划、参考文献等。
具体要求：（1）字数在1500字左右；（2）在文献综述的基础上，根据选题拟定切实可行的论文写作提纲。"
                                     :title="baseInfo.title"
            />
<!--            <el-button @click="getData2()">获取数据</el-button>-->


            <div class="content-body">
                <div class="headline">
                    <div class="name">参考文献</div>
                </div>

                <div style="padding:10px 20px;color:#dd6161;font-weight: bold;font-size: 20px;width:600px">
                    注意：请不要在开头添加序号，系统自动生成！一次添加一条！
                </div>

                <div class="content-border" style="width:800px;border:none">
                    <div class="wenxian-content" v-if="alreadySubmitData">
                        <div class="list" v-for="(item,index) of alreadySubmitData.kaitiContentList" :key="index">
                          <span >
                            [{{index+1}}].{{item}}
                          </span>
                        </div>

                    </div>
                    <div class="wenxian-content" v-else>
                        <div class="list" v-for="(item,index) of kaitiContentList" :key="index">
                          <span >
                            [{{index+1}}].{{item}}
                          </span>
                            <el-popconfirm
                                    @confirm="deleteKaitiContent(index)"
                                    title="这是一段内容确定删除吗？">
                                <i class="el-icon-close delete-icon" slot="reference" ></i>

                            </el-popconfirm>
                        </div>
                        <div class="content-input">
                            <el-input class="input" placeholder="请输入参考文献，确认按回车键" v-model="kaitiContent"
                                      @keyup.enter.native="addKaitiContent"></el-input>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import {saveLunwenContent, getCurrentLunwenContent,getLunwenStashContent,stashLunwenContent} from "@/api/lunwen.js";
    import CommonDocMainContent from '@/components/common/CommonDocMainContent'
    import CommonRichText from "../../common/CommonRichText";
    import CommonActionButton from "../../common/CommonActionButton";
    import CommonTip from "../../common/CommonTip";

    export default {
        name: "ligong",
        props:{
            // 是否在开放时间内
            opening:{
                type:Boolean,
                default:false
            }
        },
        components: {CommonTip, CommonActionButton, CommonRichText, CommonDocMainContent},
        data() {
            return {
                baseInfo:{
                    // 题目
                    title: "",
                    // 姓名
                    name: "",
                    // 学号
                    stuNo: "",
                    // 班级
                    className: "",
                    // 指导老师
                    // adviser: "",
                    // 学院
                    college: "继续教育学院",
                    // 开题日期
                    openDate:'',
                },

                alreadySubmitData: null,
                openTime: null,
                result: null,

                wenxianList: [],
                // 文献综述总金额
                wenxianResult: [],
                levelTitle: '',
                data: '',
                showLevelTitle: false,
                showSubLevelTitle: false,
                showEditLevelTitle: false,
                showLevelContent: false,
                levelTitleDialogVisible: false,
                nodeContent: '',

                // 当前节点数据
                currentData: '',
                // 当前节点
                currentNode: '',

                // 文献内容输入
                wenxianContent: '',
                // 文献内容列表
                wenxianContentList: [],


                // 开题文献输入
                kaitiContent:'',
                // 开题文献内容列表
                kaitiContentList:[],

                // 标签标记
                tabIndex: 'tab1'
            };
        },

        created() {
            this.getCheckedData();
        },
        mounted() {
            this.openTime = sessionStorage.getItem("openTime");
        },
        methods: {
            contentInputOk(e){
                this.wenxianResult=e
                console.log(e)

            },
            // 删除开题文献
            deleteKaitiContent(index){
                this.kaitiContentList.splice(index,1)
            },
            // 删除文献
            deleteWenxin(index){
                this.wenxianContentList.splice(index,1)
            },
            // 获取文献数据
            getWenxinData() {
                return this.$refs.commonDocMainContent.getData();
            },
            // 获取开题数据
            getKaitiData() {
                return this.$refs.commonDocMainContentKaiti.getData();
            },
            // 清空节点内容
            clearContent() {
                let that = this
                this.$confirm('是否清空内容?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    that.$set(that.currentData, "content", "")

                }).catch(() => {

                });

            },
            // 文献综述总结内容赋值
            wenxianResultInput(e) {
                console.log(e)
            },
            // 添加开题文献内容
            addKaitiContent() {
                if (!this.kaitiContent) {
                    this.$message({
                        type: "warning",
                        message: "参考文献内容不能为空！"
                    });
                    return;
                }
                this.kaitiContentList.push(this.kaitiContent)
                this.kaitiContent = ""
            },
            // 添加文献内容
            addWenxianContent() {
                if (!this.wenxianContent) {
                    this.$message({
                        type: "warning",
                        message: "参考文献内容不能为空！"
                    });
                    return;
                }
                this.wenxianContentList.push(this.wenxianContent);
                this.wenxianContent = ""
            },
            nodeClick(data, node) {
                console.log(data)
                this.currentData = data
                this.currentNode = node
                this.nodeContent = data.content
            },

            showSubLevelTitleInput() {
                this.showSubLevelTitle = true
            },

            // 编辑标题
            editNode() {
                this.showEditLevelTitle = true
            },
            // 保存修改后的节点标题
            saveEditLevel() {
                this.showEditLevelTitle = false
            },


            // 取消节点标题
            appendTitleCancel() {
                this.levelTitleDialogVisible = false
                this.levelTitle = ''
            },
            // 删除节点
            removeNode(node, data) {

                this.$confirm('是否删除?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {

                    const parent = node.parent;
                    const children = parent.data.children || parent.data;
                    const index = children.findIndex(d => d.id === data.id);
                    children.splice(index, 1);

                }).catch(() => {

                });

            },
            // 取消一级标题添加
            cancelLevel() {
                this.levelTitle = ''
                this.showLevelTitle = false
                this.showSubLevelTitle = false
            },
            // 删除一级标题
            deleteLevel() {
                let currentNode = this.currentData
                let wenxianList = this.wenxianList
                let index = wenxianList.findIndex(d => d.id == currentNode.id);
                wenxianList.splice(index, 1)

            },

            addSubLevel() {
                let id = this.uuid();
                let currentData = this.currentData
                let label = this.levelTitle
                const newChild = {id: id, label: label, content: '', children: []};
                if (!currentData.children) {
                    this.$set(currentData, "children", []);
                }
                if (!currentData.content) {
                    this.$set(currentData, "content", '');
                }
                currentData.children.push(newChild);
                this.showSubLevelTitle = false
                this.levelTitle = ''
            },
            // 添加文献一级级别标题
            addLevel() {
                this.wenxianList.push({
                    id: this.uuid(),
                    label: this.levelTitle,
                    content: '',
                    children: []
                })
                this.showLevelTitle = false
                this.levelTitle = ''
            },

            uuid() {
                var s = [];
                var hexDigits = "0123456789abcdef";
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = "-";

                var uuid = s.join("");
                return uuid;
            },

            //重新提交
            reSubmit() {
                this.baseInfo.title = this.alreadySubmitData.baseInfo.title;
                this.baseInfo.name = this.alreadySubmitData.baseInfo.name;
                this.baseInfo.stuNo = this.alreadySubmitData.baseInfo.stuNo;
                this.baseInfo.className = this.alreadySubmitData.baseInfo.className;
                // this.baseInfo.adviser = this.alreadySubmitData.baseInfo.adviser;
                this.baseInfo.college = this.alreadySubmitData.baseInfo.college;
                this.baseInfo.openDate = this.alreadySubmitData.baseInfo.openDate;
                this.wenxianResult = this.alreadySubmitData.wenxianResult;
                this.wenxinData = this.alreadySubmitData.wenxinData;
                this.wenxianContentList = this.alreadySubmitData.wenxianContentList;
                this.kaitiData = this.alreadySubmitData.kaitiData;
                this.kaitiContentList = this.alreadySubmitData.kaitiContentList;

                this.alreadySubmitData = null;
            },
            getCheckedData() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    let result = res.data.result;
                    if (result) {
                        let content = result.content;
                        if (content) {
                            this.alreadySubmitData = JSON.parse(content);
                            this.$refs.commonDocMainContent.setData(this.alreadySubmitData.wenxinData)
                            this.$refs.wenxianResultRichText.setData(this.alreadySubmitData.wenxianResult)
                            this.$refs.commonDocMainContentKaiti.setData(this.alreadySubmitData.kaitiData)
                            this.result = result;
                        }
                    }
                });
            },
            // 载入论文草稿
            handleLoadStash(){
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getLunwenStashContent({studentId, stepSubId}).then(res => {
                    console.log(res)
                    if (res.data.success) {

                        let result = res.data.result;
                        let content = result.content;
                        if (content) {
                            let data = JSON.parse(content);
                            this.$refs.commonDocMainContent.setData(data.wenxinData)
                            this.$refs.wenxianResultRichText.setData(data.wenxianResult)
                            this.$refs.commonDocMainContentKaiti.setData(data.kaitiData)



                            this.baseInfo.title = data.baseInfo.title;
                            this.baseInfo.name = data.baseInfo.name;
                            this.baseInfo.stuNo = data.baseInfo.stuNo;
                            this.baseInfo.className = data.baseInfo.className;
                            // this.baseInfo.adviser = this.alreadySubmitData.baseInfo.adviser;
                            this.baseInfo.college = data.baseInfo.college;
                            this.baseInfo.openDate =data.baseInfo.openDate;
                            this.wenxianResult = data.wenxianResult;
                            this.wenxinData = data.wenxinData;
                            this.wenxianContentList = data.wenxianContentList;
                            this.kaitiData = data.kaitiData;
                            this.kaitiContentList = data.kaitiContentList;

                            this.$message({
                                type: "success",
                                message: '载入成功！'
                            });
                        }
                    }else{
                        this.$message({
                            type: "success",
                            message: '载入失败:'+res.data.message
                        });
                    }


                });
            },
            // 暂存论文数据
            handleSaveStash(){
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                // let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                // 文献综述
                let wenxinData = this.getWenxinData();
                // 文献总结
                let wenxianResult = this.wenxianResult

                // 开题报告
                let kaitiData = this.getKaitiData();
                let content = {
                    baseInfo:this.baseInfo,
                    wenxianResult: wenxianResult,
                    wenxinData: wenxinData, // 文献综述
                    wenxianContentList: this.wenxianContentList, // 文献综述文献列表
                    kaitiData: kaitiData, // 开题报告
                    kaitiContentList: this.kaitiContentList, // 开题报告参考列表
                };

                // 如果名字为2个字，则中间空一隔
                let name=content.baseInfo.name
                if (name.length === 2) {
                    let first = name.substr(0, 1);
                    let second = name.substr(1, 1);
                    content.baseInfo.name = first + " " + second;
                }

                // let node = sessionStorage.getItem("menuNode");
                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),
                    // node,
                    // docFilePath
                };

                stashLunwenContent(form).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: "success",
                            message: '暂存成功'
                        });
                    } else {
                        this.$message({
                            type: "info",
                            message: res.data.message
                        });
                    }

                });
            },
            // 数据提交
            handleOk() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                // 文献综述
                let wenxinData = this.getWenxinData();
                // 文献总结
                let wenxianResult = this.wenxianResult

                // 开题报告
                let kaitiData = this.getKaitiData();
                let content = {
                    baseInfo:this.baseInfo,
                    wenxianResult: wenxianResult,
                    wenxinData: wenxinData, // 文献综述
                    wenxianContentList: this.wenxianContentList, // 文献综述文献列表
                    kaitiData: kaitiData, // 开题报告
                    kaitiContentList: this.kaitiContentList, // 开题报告参考列表
                };
                let flag = this.checkData(content);
                if (!flag) {
                    return;
                }
                // 如果名字为2个字，则中间空一隔
                let name=content.baseInfo.name
                if (name.length === 2) {
                    let first = name.substr(0, 1);
                    let second = name.substr(1, 1);
                    content.baseInfo.name = first + " " + second;
                }

                let node = sessionStorage.getItem("menuNode");
                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),
                    node,
                    docFilePath
                };
                let _this = this;

                this.$confirm("是否提交?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    type: "warning"
                })
                    .then(() => {
                        console.log(form)
                        saveLunwenContent(form).then(res => {
                            if (res.data.success) {
                                _this.$message({
                                    type: "success",
                                    message: res.data.message
                                });
                            } else {
                                _this.$message({
                                    type: "info",
                                    message: res.data.message
                                });
                            }

                            this.getCheckedData();
                        });
                    })
                    .catch(() => {
                    });
            },
            checkData(content) {
                let baseInfo=content.baseInfo
                let wenxinData = content.wenxinData;
                let kaitiData = content.kaitiData;
                let wenxianResult = content.wenxianResult;
                let wenxianContentList = content.wenxianContentList;
                let kaitiContentList = content.kaitiContentList;
                let nameList={
                    title: '题目',
                    name: '姓名',
                    stuNo: '学号',
                    className: '专业班级',
                    // adviser: '指导老师',
                    college: '分院',
                    openDate: '开题日期',
                }
                for (let key in baseInfo) {
                    if (!baseInfo[key]) {
                        this.$message({
                            type: "warning",
                            message: nameList[key] + ":不能为空！"
                        });
                        this.tabIndex='tab1'
                        return false;
                    }
                }

                // 文献综述
                if (!wenxinData||wenxinData.length === 0) {
                    this.$message({
                        type: "warning",
                        message: '文献综述内容不能为空！'
                    });
                    this.tabIndex='tab2'
                    return false;
                }


                // 文献综述总结

                if (wenxianResult.length===0) {
                    this.$message({
                        type: "warning",
                        message: '文献综述总结内容不能为空！'
                    });
                    this.tabIndex='tab2'
                    return false;
                }
                // 文献综述参考文献
                if (wenxianContentList.length===0) {
                    this.$message({
                        type: "warning",
                        message: '文献综述参考文献不能为空！'
                    });
                    this.tabIndex='tab2'
                    return false;
                }

                // 开题报告
                if (!kaitiData||kaitiData.length === 0) {
                    this.$message({
                        type: "warning",
                        message: '开题报告内容不能为空！'
                    });
                    this.tabIndex='tab3'
                    return false;
                }
                // 开题报告参考文献
                if (kaitiContentList.length===0) {
                    this.$message({
                        type: "warning",
                        message: '开题报告参考文献不能为空！'
                    });
                    this.tabIndex='tab3'
                    return false;
                }

                return true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    /*/deep/ .el-input__inner {*/
    /*    border: none;*/
    /*    border-bottom: 1px solid #dcdfe6;*/
    /*    border-radius: 0;*/
    /*    padding-left: 0px;*/
    /*}*/

    .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;

        .top-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .header {
                /*width: 100%;*/
            }
            .submit-data {
                display: flex;
                /*flex-direction: column;*/
                align-items: center;
                /*margin-bottom: 20px;*/
                /*margin-top: 20px;*/

                .time-wrap {
                    color: #aaa;
                    /*margin-top: 10px;*/
                    font-size: 14px;
                    margin-left:10px;
                }

                .reSubmit-tag {
                    display: flex;
                    /*flex-direction: column;*/
                    align-items: center;

                    .tag {
                        /*margin-bottom: 10px;*/
                        margin-right: 10px;
                    }

                    .feedback-wrap {
                        /*margin-bottom: 10px;*/
                        color: #dd6161;
                        text-decoration: underline;
                    }
                }
            }

        }
        .tab-wrap {
            width: 100%;
            margin-top: 10px;
        }




        h1 {
            color: #004ea3;
            letter-spacing: 3px;
            text-align: center;
        }

        .title {
            // font-weight: bold;
            font-size: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                &:nth-child(1) {
                    font-weight: bold;
                }
            }
        }

        .content {
            margin-top: 20px;
            width: 600px;

            .row {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .label {
                    width: 100px;
                }

                .label-gray {
                    color: #aaa;
                }
            }
        }

        .content-border {
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 50px;
            width: 1200px;
            border: 1px solid #f1f1f1;
            display: flex;

            .wenxian-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .list {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /*flex-direction: column;*/

                    span {
                        color: #606266;
                        /*margin-bottom: 4px;*/
                    }
                    .delete-icon{
                        font-size: 20px;
                        color:#dd6161;
                        &:hover{
                            cursor: pointer;
                            background: #dd6161;
                            color:#ffffff;
                        }
                    }
                }

                .content-input {
                    display: flex;

                    .input {
                        flex: 1;
                    }
                }
            }


            .content-left {
                flex: 1;
                padding: 20px;

                .main-content-wrap {
                    .level-content-wrap {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                    }
                }

                .tip {
                    margin-bottom: 10px;
                }
            }

            .middle {
                width: 1px;
                background: #f1f1f1;
            }

            .content-right {
                flex: 1;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .content-right-tip {
                    display: flex;
                    color: #82848a;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .fullscreen {
                        cursor: pointer;
                        font-size: 14px;

                        &:hover {
                            color: #409EFF;
                        }
                    }
                }


                .level-content {
                    margin-top: 20px;
                    width: 100%;
                }

                .clear-content {
                    margin-top: 10px;
                    align-self: flex-end;
                }
            }
        }
    }


    .content-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        .lable {
            align-self: start;
            // color:#82848a;
        }

        .headline {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 400px;

            .name {
                font-size: 30px;
                font-weight: bold;
            }

            .notice {
                align-self: start;
                font-size: 10px;
                color: #dd6161;
                margin-top: 10px;

                &:before {
                    content: '说明：';
                }
            }
        }
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        color: #303133;
        font-weight: bold;

    }

    .add-level-wrap {
        margin-bottom: 20px;

        .level-input {
            display: flex;
            margin-bottom: 20px;
        }
    }
    .wrap-btn{
        /*width: 100%;*/
        text-align: right;
    }
</style>
