<template>

    <div>
            <el-dialog
                    title="添加段落"
                    :visible="visible&&showDialog"
                    width="1000px"
                    @close="visible=false"
                    :close-on-click-modal="false"
            >
                <div class="notice-wrap">
                    <el-tag type="danger" style="margin-bottom: 10px">注：**1.请不要自己手动进行缩进，系统会自动帮您缩进**</el-tag>
                    <el-tag type="danger" style="margin-bottom: 10px">注：**2.每次只能添加一段**</el-tag>
                </div>
                <el-input show-word-limit :maxlength="100000" type="textarea" :rows="10" v-model="content" placeholder="请输入"/>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible=false">取 消</el-button>
                    <el-button type="primary" @click="confirm">保 存</el-button>
                  </span>
            </el-dialog>
        <div v-if="visible&&!showDialog">
            <div class="notice-wrap">
                <el-tag type="danger" style="margin-bottom: 10px">注：**1.请不要自己手动进行缩进，系统会自动帮您缩进**</el-tag>
                <el-tag type="danger" style="margin-bottom: 10px">注：**2.每次只能添加一段**</el-tag>
            </div>
            <el-input show-word-limit :maxlength="100000" type="textarea" :rows="10" v-model="content" placeholder="请输入"/>
            <div class="btn-wrap">
                <el-button @click="visible=false">取 消</el-button>
                <el-button type="primary" @click="confirm">保 存</el-button>
            </div>
        </div>
    </div>



</template>

<script>
    export default {
        name: "RichTextInput",
        props:{
          showDialog:{
              type:Boolean,
              default:false
          }
        },
        data(){
            return {
                visible:false,
                content:'',
                action:'add'
            }
        },
        methods:{
            show(){
                this.visible=true
                this.content=''
                this.action='add'
            },
            edit(content){
                this.visible=true
                this.content=content
                this.action='edit'
            },
            hide(){
                this.visible=false
                this.content=''
            },
            // 保存退出
            confirm(){
                if(!this.content){
                    this.$message({
                        type: 'warning',
                        message: '内容不能为空！'
                    })
                    return;
                }
                this.$emit("ok",this.content,'',this.action);
                this.visible=false;
                this.content=''
            }
        }
    }
</script>

<style scoped lang="scss">
.notice-wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
    .btn-wrap{
        text-align: right;
        margin-top: 20px;
    }
</style>
