<template>
    <div class="wrap">





        <!------------------------------------------------------文献综述 ------------------------------------------------->


        <div class="content-body">
            <el-tag class="lable" v-if="stepName">{{stepName}}：</el-tag>
            <div class="headline">
                <div class="name">“{{title}}”{{stepName}}</div>
                <div class="notice" v-if="notice">
                    {{notice}}
<!--                    文献综述是综合人家已经做过的相关研究和观点，并指出出处和作者，最后做一个总的评述,-->
<!--                    即有哪些是做得好的,哪些不足,然后说明你的出发点或角度。具体要求：-->
<!--                    <br/>(1) 查阅与选题相关文献3篇以上；-->
<!--                    <br/>(2) 文献综述的字数在1500字以上。-->
                </div>
            </div>
        </div>

        <div class="content-border">
            <div class="content-left">




                    <div class="add-level-wrap">
                        <div v-if="showLevelTitle" class="level-input">
                            <el-input v-model="levelTitle" placeholder="请输入一级标题，按回车键确认"
                                      @keydown.enter.native="addLevel"></el-input>
                            <el-button type="text"  size="mini" @click="addLevel">确定</el-button>
                            <el-button type="text" style="color:#dd6161" size="mini" @click="cancelLevel">取消</el-button>
                        </div>

                        <div v-if="showSubLevelTitle" class="level-input">
                            <el-input v-model="levelTitle" placeholder="请输入子标题，按回车键确认"
                                      @keydown.enter.native="addSubLevel()"></el-input>
                            <el-button type="text"  size="mini" @click="addSubLevel">确定</el-button>
                            <el-button type="text" style="color:#dd6161" size="mini" @click="cancelLevel">取消</el-button>
                        </div>

                        <div v-if="showEditLevelTitle" class="level-input">
                            <el-input v-model="currentData.label" placeholder="请输入标题，按回车键确认"
                                      @keydown.enter.native="saveEditLevel()"></el-input>
                            <el-button type="text"  size="mini" @click="saveEditLevel">确定</el-button>
                            <el-button type="text" style="color:#dd6161" size="mini" @click="cancelLevel">取消</el-button>
                        </div>

                        <div v-if="showBtns">
                            <el-button  v-if="editProp" type="primary" size="mini" @click="showLevelTitle=true">添加一级标题</el-button>
                            <el-button  v-if="editProp" :disabled="!currentData" type="primary" size="mini"
                                       @click="showSubLevelTitleInput()">添加子标题
                            </el-button>
                            <el-button  v-if="editProp" :disabled="!currentData" type="warning" size="mini"
                                       @click="editNode()">编辑标题
                            </el-button>
                            <el-button  v-if="editProp" :disabled="!currentData" type="danger" size="mini"
                                       @click="removeNode(currentNode,currentData)">删除标题
                            </el-button>
                    </div>

                </div>
                <div>
                    <el-tag type="danger" style="margin-right: 10px"  size="mini" v-if="editProp" class="tip">**请不要自己添加开头序号，可拖动排序**</el-tag>
                    <el-tag type="info"  size="mini" v-if="editProp" class="tip"> 选中节点添加内容</el-tag>
                </div>


                <el-tree
                        draggable
                        empty-text=""
                        @node-click="nodeClick"
                        :data="wenxianList"
                        node-key="id"
                        default-expand-all
                        highlight-current
                        :expand-on-click-node="false"
                >
                    <div class="custom-tree-node" slot-scope="{ node,data }">
                        <span v-if="data.content.length===0">{{ node.label }}</span>
                        <el-badge v-else is-dot>{{ node.label }} </el-badge>
                    </div>
                </el-tree>

            </div>

            <div class="middle"></div>
            <div class="content-right" v-if="!!currentData">
                <common-rich-text :edit-prop="editProp" ref="commonRichText" @ok="contentInputOk"/>
            </div>
        </div>



    </div>
</template>

<script>

    import CommonRichText from "./CommonRichText";
    export default {

        name: "CommonDocMainContent.vue",
        components: {CommonRichText},
        props:{title:String,stepName:String,notice:String,
            editProp:{
                type:Boolean,
                default:true
            }

        },
        data() {
            return {


                wenxianList: [],
                // 文献综述总金额
                levelTitle: '',
                levelContent: '',
                data: '',
                showLevelTitle: false,
                showSubLevelTitle: false,
                showEditLevelTitle: false,
                levelContentDialogVisible: false,
                levelTitleDialogVisible: false,
                nodeContent: '',

                // 当前节点数据
                currentData: '',
                // 当前节点
                currentNode: '',

                // 文献内容输入
                wenxianContent: '',
            };
        },
        computed: {
            showBtns: function () {
                return !this.showLevelTitle && !this.showSubLevelTitle && !this.showEditLevelTitle;
            }
        },

        methods: {
            // 设置数据
            setData(data){
                this.$nextTick(()=>{
                    this.wenxianList=data

                })
            },
            // 获取数据
            getData(){
                return this.wenxianList;
            },
            contentInputOk(e){
                this.$set(this.currentData,"content",e);

            },

            // 文献综述总结内容赋值
            wenxianResultInput(e) {
                console.log(e)
            },
            nodeClick(data, node) {
                this.currentData = data
                this.currentNode = node
                this.nodeContent = data.content
                this.$nextTick(()=>{
                    this.$refs.commonRichText.init(this.nodeContent)

                })
            },

            showSubLevelTitleInput() {
                this.showSubLevelTitle = true
            },
            // 打开全屏编辑框
            openFullScreenLevelContent(){
                this.levelContentDialogVisible=true
                this.levelContent=this.currentData.content
            },
            // 编辑标题
            editNode() {
                this.showEditLevelTitle = true
            },
            // 保存修改后的节点标题
            saveEditLevel() {
                this.showEditLevelTitle = false
            },

            // 保存节点内容
            appendContent() {
                let data = this.data
                let content = this.levelContent
                this.$set(data, "content", content);
                this.levelContentDialogVisible = false
                this.levelContent = ''
                this.nodeContent = content;


            },
            // 取消节点标题
            appendTitleCancel() {
                this.levelTitleDialogVisible = false
                this.levelTitle = ''
            },
            saveEditLevelContent(){
                let content=this.levelContent
                this.$set(this.currentData,'content',content)
                this.levelContentDialogVisible=false
            },
            // 隐藏节点对话框
            closeEditLevelContentDialog() {
                this.levelContentDialogVisible = false
            },
            // 删除节点
            removeNode(node, data) {

                this.$confirm('是否删除?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {

                    const parent = node.parent;
                    const children = parent.data.children || parent.data;
                    const index = children.findIndex(d => d.id === data.id);
                    children.splice(index, 1);

                }).catch(() => {

                });

            },

            // 取消一级标题添加
            cancelLevel() {
                this.levelTitle = ''
                this.showLevelTitle = false
                this.showSubLevelTitle = false
                this.showEditLevelTitle = false
            },
            // 删除一级标题
            deleteLevel() {
                let currentNode = this.currentData
                let wenxianList = this.wenxianList
                let index = wenxianList.findIndex(d => d.id == currentNode.id);
                wenxianList.splice(index, 1)

            },
            // 添加子节点
            addSubLevel() {
                let id = this.uuid();
                let currentData = this.currentData
                let label = this.levelTitle
                const newChild = {id: id, label: label, content: [], children: []};
                if (!currentData.children) {
                    this.$set(currentData, "children", []);
                }
                if (!currentData.content) {
                    this.$set(currentData, "content", []);
                }
                currentData.children.push(newChild);
                this.showSubLevelTitle = false
                this.levelTitle = ''
            },
            // 添加文献一级级别标题
            addLevel() {

                if(this.levelTitle===''){
                    this.$message({
                        type: 'warning',
                        message: '标题内容不能为空！'
                    });
                    return;
                }

                this.wenxianList.push({
                    id: this.uuid(),
                    label: this.levelTitle,
                    content: [],
                    children: []
                })
                this.showLevelTitle = false
                this.levelTitle = ''
            },

            uuid() {
                var s = [];
                var hexDigits = "0123456789abcdef";
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = "-";

                var uuid = s.join("");
                return uuid;
            },
        }
    };
</script>

<style lang="scss" scoped>
    /*/deep/ .el-input__inner {*/
    /*    border: none;*/
    /*    border-bottom: 1px solid #dcdfe6;*/
    /*    border-radius: 0;*/
    /*    padding-left: 0px;*/
    /*}*/

    .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;



        .content-border {
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 50px;
            width: 1200px;
            border: 1px solid #f1f1f1;
            display: flex;

            .wenxian-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .list {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;

                    span {
                        color: #606266;
                        margin-bottom: 4px;
                    }
                }

                .content-input {
                    display: flex;

                    .input {
                        flex: 1;
                    }
                }
            }


            .content-left {
                flex: 1;
                padding: 20px;

                .main-content-wrap {
                    .level-content-wrap {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                    }
                }

                .tip {
                    margin-bottom: 10px;
                }
            }

            .middle {
                width: 1px;
                background: #f1f1f1;
            }

            .content-right {
                flex: 1;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .content-right-tip {
                    display: flex;
                    color: #82848a;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    .fullscreen{
                        cursor: pointer;
                        font-size: 14px;
                        &:hover{
                            color:#409EFF;
                        }
                    }
                }


                .level-content {
                    margin-top: 20px;
                    width: 100%;
                }
                .clear-content{
                    margin-top: 10px;
                    align-self: flex-end;
                }
            }
        }
    }


    .content-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        width: 600px;

        .lable {
            align-self: start;
            // color:#82848a;
        }

        .headline {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 400px;

            .name {
                font-size: 30px;
                font-weight: bold;
            }

            .notice {
                align-self: start;
                font-size: 10px;
                color: #dd6161;
                margin-top: 10px;

                &:before {
                    /*content: '说明：';*/
                }
            }
        }
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        color: #303133;
        font-weight: bold;

    }

    .add-level-wrap {
        margin-bottom: 20px;

        .level-input {
            display: flex;
            margin-bottom: 20px;
        }
    }
</style>
