import axios from '@/axios'



/* 获取暂存论文内容 */
export function getLunwenStashContent (data) {
    return axios({
        url: '/lunwen/getLunwenStashContent',
        data:data,
        method: 'post',

    })
}

/* 暂存论文内容 */
export function stashLunwenContent (data) {
    return axios({
        url: '/lunwen/stashLunwenContent',
        data:data,
        method: 'post',

    })
}



/* 获取论文步骤 */
export function getStepList (data) {
    return axios({
      url: '/lunwen/getStepList',
      params:data,
      method: 'get',

    })
  }




/* 保存论文内容 */
export function saveLunwenContent (data) {
    try{
        let userInfo=sessionStorage.getItem("userInfo")
        userInfo=JSON.parse(userInfo)
        let sysStudentId=userInfo.sysStudentId
        data=Object.assign(data,{sysStudentId})
    }catch(e){
        console.log(e)
    }
    return axios({
      url: '/lunwen/saveLunwenContent',
      data:data,
      method: 'post',

    })
  }
/* 获取对应已提交内容列表 */
export function getLunwenContentList (data) {

    return axios({
      url: '/lunwen/getLunwenContentList',
      data:data,
      method: 'post',

    })
  }

/* getCurrentLunwenContent */
export function getCurrentLunwenContent (data) {
    try{
        let userInfo=sessionStorage.getItem("userInfo")
        userInfo=JSON.parse(userInfo)
        let sysStudentId=userInfo.sysStudentId
        data=Object.assign(data,{sysStudentId})
    }catch(e){
        console.log(e)
    }
    return axios({
      url: '/lunwen/getCurrentLunwenContent',
      params:data,
      method: 'get',

    })
  }

/* 获取论文日志 */
export function getStudentLunwenLogList (data) {
    return axios({
      url: '/lunwen/getStudentLunwenLogList',
      params:data,
      method: 'get',

    })
  }
 /** 获取当前步骤 **/
export function getCurrentStepSub (sysStudentId) {
    return axios({
        url: '/lunwen/getCurrentStep/'+sysStudentId,
        method: 'get',

    })
}

 /** 获取首页信息 **/
export function getLunwenHomeInfo (data) {
    return axios({
        url: '/lunwen/getLunwenHomeInfo',
        method: 'get',
		params:data
    })
}

 /** 获取签名信息 **/
export function getQianmingInfo (data) {
    return axios({
        url: '/lunwen/getQianmingInfo',
        method: 'get',
		params:data
    })
}

 /** 获取签名图片 **/
export function getQianmingImage (data) {
    return axios({
        url: '/lunwen/getQianmingImage',
        method: 'get',
		params:data
    })
}

 /** 确认提交签名 **/
export function commitQianming (data) {
    return axios({
        url: '/lunwen/commitQianming',
        method: 'get',
		params:data
    })
}
 /** 检测是否已经签名 **/
export function checkQianming (data) {
    return axios({
        url: '/lunwen/checkQianming',
        method: 'get',
		params:data
    })
}
