import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import axios from "axios";

Vue.use(ElementUI);
Vue.config.productionTip = false


// const instance = axios.create({
//   // baseURL: 'http://127.0.0.1:8080/jeecg-boot',
//   baseURL: '/api',
//   timeout: 4000,
//   headers: {'X-Custom-Header': 'foobar'}
// });

// Vue.prototype.$axios = instance;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
