<template>
    <el-dialog :visible="visible" title="审核记录" @close="visible=false" >
		<div style="overflow-y: scroll; max-height: 500px;padding: 10px;">
			<el-timeline >
			    <el-timeline-item
			            style="display: flex;text-align: left"
			            v-for="(lunwenLog, index) in lunwenLogList"
			            :key="index"
			            :type="lunwenLog.type"
			            :timestamp="lunwenLog.createTime">
			        <div class="line">
			            <el-tag size="mini">{{lunwenLog.stepName}}</el-tag>
			            <el-tag v-if="lunwenLog.replyType==0" type="primary" size="mini">待审核</el-tag>
			            <el-tag v-if="lunwenLog.replyType==1" type="success" size="mini">通过</el-tag>
			            <el-tag v-if="lunwenLog.replyType==2" type="danger" size="mini">驳回</el-tag>
			        </div>
			        <div class="line">
			            <span class="label">审核意见：</span>
			            <span>{{lunwenLog.teacherReply||'无'}}</span>
			        </div>
			       <!-- <div class="line">
			            <span class="label">审核老师：</span>
			            <span>{{lunwenLog.teacherName||'未知'}}</span>
			        </div> -->
			        <div class="line">
			            <span class="label">提交内容：</span>
			
			            <span v-if="!lunwenLog.node">{{lunwenLog.content||'未知'}}</span>
			            <span v-else-if="lunwenLog.node=='node1'">{{lunwenLog.content||'未知'}}</span>
			            <el-link type="primary" v-else @click="loadDoc(lunwenLog.content)">下载文档</el-link>
			        </div>
			
			
			    </el-timeline-item>
			</el-timeline>
		</div>
     
    </el-dialog>
</template>

<script>
    import {getStudentLunwenLogList} from '@/api/lunwen.js'
    import axios from '@/axios'

    export default {
        name: "LunwenLogDialog",
        data(){
            return{
                visible:false,
                lunwenLogList: []
            }
        },
        methods:{
            loadDoc(docFilePath){
                window.open(axios.defaults.baseURL+"/sys/common/download/"+docFilePath)
            },
            show(){
                let stepSubId = sessionStorage.getItem("subId");
                let userInfo=sessionStorage.getItem("userInfo")
                userInfo=JSON.parse(userInfo)
                this.getList(stepSubId,userInfo.id)
            },
			showAll(){
				let userInfo=sessionStorage.getItem("userInfo")
				userInfo=JSON.parse(userInfo)
				this.getList(null,userInfo.id)
			},
            getList(stepSubId,studentId){
                this.lunwenLogList=[]
                getStudentLunwenLogList({stepSubId,studentId}).then(res=>{
                    this.visible=true
                    this.$nextTick(function(){
                        this.lunwenLogList=res.data.result||[]
                        // this.lunwenLogList.forEach(a=>{
                        //     let replyType=a.replyType
                        //     let type="info"
                        //     if(replyType===1){
                        //         type="success"
                        //     }else if(replyType==2){
                        //         type="danger"
                        //     }
                        //     a.type=type
                        // })

                    })


                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/ .el-dialog__header{
        display: flex;
    }
.line{
    margin-bottom: 10px;
    .label{
        color:#888;
    }
}
</style>
