import axios from '@/axios'



/* 翻译  中文翻译成英文 */
export function translateString (data) {
    return axios({
        url: '/lunwen/translateString',
        data:data,
        method: 'post',

    })
}

