import { render, staticRenderFns } from "./jx_ligong.vue?vue&type=template&id=cb6f3c2a&scoped=true&"
import script from "./jx_ligong.vue?vue&type=script&lang=js&"
export * from "./jx_ligong.vue?vue&type=script&lang=js&"
import style0 from "./jx_ligong.vue?vue&type=style&index=0&id=cb6f3c2a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb6f3c2a",
  null
  
)

export default component.exports