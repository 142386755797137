<template>

    <!--
       *** 这里是已经提交过的内容 *****
        这里是论文状态提示的通用组件
    -->

    <div class="submit-data">
        <div class="time-wrap">
            <span>提交时间:</span>
            <span>{{result.createTime}}</span>
            <el-button size="mini" style="margin-left: 10px" icon="el-icon-time" @click="handleLog">审核记录</el-button>
        </div>
        <template v-if="result.checkFlag===0">
            <el-tag class="tag" size="mini" v-if="result.checkFlag===0" style="margin-left: 10px" type="warning">待检查</el-tag>
            <el-button size="mini" icon="el-icon-edit" style="margin-left: 10px" type="primary" @click="reSubmit">点击修改</el-button>
        </template>
        <el-tag class="tag" size="mini" v-else-if="result.checkFlag===1" style="margin-left: 10px" type="success">检查通过</el-tag>
        <div v-else-if="result.checkFlag===2" class="reSubmit-tag" style="margin-left: 10px">
            <el-tag size="mini" class="tag" type="danger">检查驳回</el-tag>
            <div class="feedback-wrap" v-if="!!result.feedback">驳回原因：{{result.feedback}}</div>
            <el-button size="mini" type="danger" @click="reSubmit">重新提交</el-button>
        </div>
        <el-button size="mini" icon="el-icon-download" style="margin-left: 10px" type="primary" @click="loadDoc">下载预览</el-button>
        <lunwen-log-dialog ref="LunwenLogDialog"/>
    </div>
</template>

<script>
    import axios from '@/axios'
    import LunwenLogDialog from "./LunwenLogDialog";
    export default {
        name: "CommonTip",
        components: {LunwenLogDialog},
        props:{
            result:Object
        },
        methods:{
            // 显示论文提交记录弹框
            handleLog(){
                this.$refs.LunwenLogDialog.show()
            },
            loadDoc(){
              // window.open("http://127.0.0.1:8080/jeecg-boot/sys/common/download/"+this.result.docFilePath)
                window.open(axios.defaults.baseURL+"/sys/common/download/"+this.result.docFilePath)
            },
            reSubmit(){
                this.$emit("reSubmit")
            }
        }
    }
</script>

<style scoped lang="scss">
    .time-wrap {
        color: #aaa;
        font-size: 14px;
        margin-left:10px;
    }

    .reSubmit-tag {
        display: flex;
        align-items: center;

        .tag {
            margin-right: 10px;
        }

        .feedback-wrap {
            /*margin-bottom: 10px;*/
            color: #dd6161;
            text-decoration: underline;
        }
    }
</style>
