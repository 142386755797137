<template>
    <div class="wrap">
        <!--        <div class="header">-->
        <!--            <span class="time">开放时间：{{openTime}}  </span>-->
        <!--            <el-tag v-if="!opening" size="small" type="danger">已关闭</el-tag>-->
        <!--        </div>-->

        <div class="top-wrap">
            <div class="header">
                开放时间：{{openTime}}
                <el-tag v-if="!opening" size="small" type="danger">已关闭</el-tag>
            </div>
            <div class="submit-data" v-if="alreadySubmitData">
                <common-tip :result="result" @reSubmit="reSubmit"/>
            </div>
            <div class="wrap-btn" v-else>
                <common-action-button v-if="!alreadySubmitData&&opening" :load-stash="handleLoadStash"
                                      :save-stash="handleSaveStash" :commit="handleOk"/>
            </div>
        </div>


        <h1>江西理工大学继续教育与培训学院 毕业论文（设计）任务书
        </h1>
        <table border="1" class="table">
            <tr>
                <td>论文题目</td>
                <td colspan="5">
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.title}}</span>
                    <el-input v-else v-model="title" placeholder="请输入论文题目"></el-input>
                </td>
            </tr>
            <tr>
                <td>专业班级</td>
                <td colspan="2">
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.classname}}</span>
                    <el-input v-else v-model="classname" placeholder="请输入专业班级"></el-input>
                </td>
                <td>姓名</td>
                <td colspan="2">
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.name}}</span>
                    <el-input v-else v-model="name" placeholder="请输入姓名"></el-input>
                </td>
            </tr>

            <tr>
                <td colspan="8">
                    <div>
                        <h3>毕业论文（设计）的内容</h3>
                        <div>
                            <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.content1}}</span>
                            <el-input v-else type="textarea" rows="5" v-model="content1" placeholder="请输入内容"
                                      show-word-limit></el-input>
                        </div>
                    </div>
                    <div>
                        <h3>毕业论文（设计）要求</h3>
                        <div>
                            <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.content2}}</span>
                            <el-input v-else type="textarea" rows="5" v-model="content2" placeholder="请输入内容"
                                      show-word-limit></el-input>
                        </div>
                    </div>
                    <div>
                        <h3>毕业论文（设计）的主要阶段计划（分前期、中期、后期）</h3>
                        <div>
                            <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.content3}}</span>
                            <el-input v-else type="textarea" rows="5" v-model="content3" placeholder="请输入内容"
                                      show-word-limit></el-input>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>任务下发日期</td>
                <td colspan="2">
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.jobDate}}</span>
                    <el-date-picker
                            style="width: 100%"
                            v-else
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy年MM月dd日"
                            v-model="jobDate"
                            type="date"
                            placeholder="选择任务下发日期">
                    </el-date-picker>
                </td>
                <td>完成日期</td>
                <td colspan="2">
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.finishDate}}</span>
                    <el-date-picker
                            style="width: 100%"
                            v-else
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy年MM月dd日"
                            v-model="finishDate"
                            type="date"
                            placeholder="请输入完成日期">
                    </el-date-picker>
                </td>
            </tr>
        </table>

    </div>
</template>

<script>
    import {
        saveLunwenContent,
        getCurrentLunwenContent,
        getLunwenStashContent,
        stashLunwenContent
    } from "@/api/lunwen.js";
    import CommonActionButton from "../../common/CommonActionButton";
    import CommonTip from "../../common/CommonTip";

    export default {
        name: "jxLigong",
        components: {CommonTip, CommonActionButton},
        props: {
            // 是否在开放时间内
            opening: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                // 论文题目
                title: "",
                // 专业班级
                classname: "",
                // 姓名
                name: "",
                // 一、毕业论文（设计）的内容
                content1: "",
                // 二、毕业论文（设计）要求
                content2: "",
                // 三、毕业论文（设计）的主要阶段计划（分前期、中期、后期）
                content3: "",
                // 任务下发日期
                jobDate: "",
                // 完成日期
                finishDate: "",
                alreadySubmitData: null,
                openTime: null,
                result: null
            };
        },
        created() {
            this.getCheckedData();
        },
        mounted() {
            this.openTime = sessionStorage.getItem("openTime");
        },
        methods: {
            //重新提交
            reSubmit() {
                this.title = this.alreadySubmitData.title;
                this.classname = this.alreadySubmitData.classname;
                this.name = this.alreadySubmitData.name;
                this.content1 = this.alreadySubmitData.content1;
                this.content2 = this.alreadySubmitData.content2;
                this.content3 = this.alreadySubmitData.content3;
                this.jobDate = this.alreadySubmitData.jobDate;
                this.finishDate = this.alreadySubmitData.finishDate;
                this.alreadySubmitData = null;
            },
            getCheckedData() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    let result = res.data.result;
                    if (result) {
                        let content = result.content;
                        if (content) {
                            this.alreadySubmitData = JSON.parse(content);
                            this.result = result;
                        }
                    }
                });
            },
            handleOk() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                let content = {
                    title: this.title,
                    classname: this.classname,
                    name: this.name,
                    content1: this.content1,
                    content2: this.content2,
                    content3: this.content3,
                    jobDate: this.jobDate,
                    finishDate: this.finishDate,
                };
                let flag = this.checkData(content);
                if (!flag) {
                    return;
                }

                let node = sessionStorage.getItem("menuNode");
                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),
                    node,
                    docFilePath
                };
                let _this = this;

                this.$confirm("是否提交?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    type: "warning"
                })
                    .then(() => {
                        saveLunwenContent(form).then(res => {
                            if (res.data.success) {
                                _this.$message({
                                    type: "success",
                                    message: res.data.message
                                });
                            } else {
                                _this.$message({
                                    type: "info",
                                    message: res.data.message
                                });
                            }

                            this.getCheckedData();
                        });
                    })
                    .catch(() => {
                    });
            },
            // 暂存论文数据
            handleSaveStash() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                // let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                // 开题报告
                let content = {
                    title: this.title,
                    classname: this.classname,
                    name: this.name,
                    content1: this.content1,
                    content2: this.content2,
                    content3: this.content3,
                    jobDate: this.jobDate,
                    finishDate: this.finishDate,
                };


                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),

                };

                stashLunwenContent(form).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: "success",
                            message: '暂存成功'
                        });
                    } else {
                        this.$message({
                            type: "info",
                            message: res.data.message
                        });
                    }

                });
            },
            // 载入论文草稿
            handleLoadStash() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getLunwenStashContent({studentId, stepSubId}).then(res => {
                    if (res.data.success) {
                        let result = res.data.result;
                        let content = result.content;
                        if (content) {
                            let data = JSON.parse(content);

                            this.title = data.title;
                            this.classname = data.classname;
                            this.name = data.name;
                            this.content1 = data.content1;
                            this.content2 = data.content2;
                            this.content3 = data.content3;
                            this.jobDate = data.jobDate;
                            this.finishDate = data.finishDate;

                            this.$message({
                                type: "success",
                                message: '载入成功！'
                            });
                        }
                    } else {
                        this.$message({
                            type: "success",
                            message: '载入失败:' + res.data.message
                        });
                    }


                });
            },
            checkData(content) {
                let totalLength = 0
                let nameList = {
                    title: '论文题目',
                    classname: '专业班级',
                    name: '姓名',
                    content1: '毕业论文（设计）的内容',
                    content2: '毕业论文（设计）要求',
                    content3: '毕业论文（设计）的主要阶段计划（分前期、中期、后期）',
                    jobDate: '任务下发日期',
                    finishDate: '完成日期',
                }
                for (let key in content) {
                    if (!content[key]) {
                        console.log(key)
                        this.$message({
                            type: "warning",
                            message: nameList[key] + ":不能为空！"
                        });
                        return false;

                    }

                }



                return true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    /deep/ .el-input__inner {
        border: none;
        border-bottom: 1px solid #dcdfe6;
        border-radius: 0;
        padding-left: 0px;
    }

    .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        /*.header {*/
        /*    width: 100%;*/
        /*    text-align: left;*/
        /*    display: flex;*/
        /*    align-items: center;*/
        /*}*/
        .top-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .header {
                /*width: 100%;*/
            }

            .submit-data {
                display: flex;
                /*flex-direction: column;*/
                align-items: center;
                /*margin-bottom: 20px;*/
                /*margin-top: 20px;*/

                .time-wrap {
                    color: #aaa;
                    /*margin-top: 10px;*/
                    font-size: 14px;
                    margin-left: 10px;
                }

                .reSubmit-tag {
                    display: flex;
                    /*flex-direction: column;*/
                    align-items: center;

                    .tag {
                        /*margin-bottom: 10px;*/
                        margin-right: 10px;
                    }

                    .feedback-wrap {
                        /*margin-bottom: 10px;*/
                        color: #dd6161;
                        text-decoration: underline;
                    }
                }
            }

        }


        .label-gray {
            color: #aaa;
        }

        .content-list {
            display: flex;
            align-items: center;
            flex-direction: column;

            .add-line {
                margin-top: 10px;
            }

            .input-content {
                display: flex;
                align-items: center;
                width: 100%;

                .input {
                    margin-left: 10px;
                    margin-right: 10px;
                }

                .delete {
                    color: #dd6161;
                    font-weight: bold;

                    &:hover {
                        background: #dd6161;
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }
        }

        .table {
            width: 1000px;
            border-collapse: collapse;
            margin-bottom: 60px;

            tr {
                td {
                    padding: 4px;

                    .delete {
                        color: #dd6161;
                        font-weight: bold;

                        &:hover {
                            background: #dd6161;
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }
            }
        }


        h1 {
            color: #000000;
            align-self: center;
        }

        .title {
            font-weight: bold;
            font-size: 22px;
        }
    }
</style>
