<template>
    <div class="wrap-btn">
        <el-link @click="uploadRechecking" v-if="rechecking==='Y'" type="primary" icon="el-icon-upload" style="margin-right: 10px;">上传查重报告</el-link>
        <rechecking-upload-modal ref="recheckingUploadModal"></rechecking-upload-modal>
    </div>
</template>

<script>
    import RecheckingUploadModal from "../../upload/RecheckingUploadModal";
    export default {
        name: "Rechecking",
        components: {RecheckingUploadModal},
        computed:{
            // 是否显示查重报告
            rechecking:function () {
                let nodeData=this.$store.state.nodeData||sessionStorage.getItem("nodeData")
                // console.log(nodeData)
                if (nodeData) {
                    nodeData = JSON.parse(nodeData);
                    return nodeData.rechecking||'';
                }

                return '';


            }
        },
        methods:{
            // 上传查重报告
            uploadRechecking(){
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                this.$refs.recheckingUploadModal.show({stepSubId,studentId})
            }

        }
    }
</script>

<style scoped>

</style>
