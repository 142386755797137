<template>

    <!--

        这里是论文操作按钮的通用组件

    -->




    <div class="wrap-btn">
        <el-link @click="uploadRechecking" v-if="rechecking==='Y'" type="primary" icon="el-icon-upload" style="margin-right: 10px;">上传查重报告</el-link>
        <el-link @click="handleCopy" icon="el-icon-document-copy"  type="primary" style="margin-right: 10px;">拷贝论文</el-link>

        <el-button  type="success"  @click="handleLoadStash">载入草稿</el-button>
        <el-button  type="warning"  @click="handleSaveStash">暂存草稿</el-button>
        <el-button  type="primary"  @click="handleOk">提交</el-button>
        <el-link v-if="helpUrl" :href="helpUrl" icon="el-icon-question" target="_blank" type="primary" style="margin-left: 10px">帮助手册</el-link>
        <rechecking-upload-modal ref="recheckingUploadModal"></rechecking-upload-modal>
        <copy-lunwen-dialog @ok="copyLunwenOk" ref="copyLunwenDialog"/>
    </div>

</template>

<script>
    import RecheckingUploadModal from "../upload/RecheckingUploadModal";
    import CopyLunwenDialog from "./CopyLunwenDialog";

    export default {
        name: "CommonActionButton",
        components: {CopyLunwenDialog, RecheckingUploadModal},
        props:{
            // 载入草稿
            loadStash:{
                type:Function,
                require:true
            },
            // 暂存草稿
            saveStash:{
                type:Function,
                require:true
            },
            // 提交
            commit:{
                type:Function,
                require:true
            },
        },
        computed:{
          helpUrl:function () {
              let nodeData=sessionStorage.getItem("nodeData")
              if (nodeData) {
                  nodeData = JSON.parse(nodeData);
                  return nodeData.helpUrl||'';
              }
              return '';

          },
            // 是否显示查重报告
            rechecking:function () {
              let nodeData=sessionStorage.getItem("nodeData")
              if (nodeData) {
                  nodeData = JSON.parse(nodeData);
                  return nodeData.rechecking||'';
              }
              return '';

          }
        },
        methods:{
            copyLunwenOk(e){
                console.log(e)
              this.$emit("copyLunwenOk",e)
            },
            // 上传查重报告
            uploadRechecking(){
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                this.$refs.recheckingUploadModal.show({stepSubId,studentId})
            },
            // 拷贝论文
            handleCopy(){
                this.$refs.copyLunwenDialog.show()
            },
            handleLoadStash(){
                let that=this
                this.$confirm('是否载入上一次草稿?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.$notify.info({
                        title: '提示',
                        message: '数据载入中，请稍后...'
                    });
                    that.loadStash()
                }).catch(() => {

                });
            },
            handleSaveStash(){
                let that=this
                this.$confirm('是否暂存草稿?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.$notify.info({
                        title: '提示',
                        message: '数据暂存中，请稍后...'
                    });
                    that.saveStash()
                }).catch(() => {

                });
            },
            handleOk(){
                this.commit()
            }
        }
    }
</script>

<style scoped>

</style>
