<template>
    <div>
        <!-- 海洋大学 -->
        <el-link v-if="originTemplateName!=='common'" type="primary" style="margin-bottom: 10px" @click="changeUploadType">
            {{changeType==='common'?'切换模板模式':'切换word文件上传模式'}}
        </el-link>
        <haiyang :opening="opening" v-if="'haiyang'===templateName"></haiyang>
        <!-- 丽水学院 -->
        <lishui :opening="opening" v-else-if="'lishui'===templateName"></lishui>
        <!-- 浙大宁波理工学院 -->
        <ligong :opening="opening" v-else-if="'ligong'===templateName"></ligong>
        <!-- 浙江工商大学 -->
        <gongshang :opening="opening" v-else-if="'gongshang'===templateName"></gongshang>
        <!-- 杭州电子可以大学 -->
        <hangdian :opening="opening" v-else-if="'hangdian'===templateName"></hangdian>
        <!-- 江西理工大学-->
        <jx-ligong :opening="opening" v-else-if="'jx_ligong'===templateName"></jx-ligong>
        <!-- 杭州师范大学-->
        <hz-shifan :opening="opening" v-else-if="'hz_shifan'===templateName"></hz-shifan>
        <!-- 南昌交通学院-->
        <nc-jiaotong :opening="opening" v-else-if="'nc_jiaotong'===templateName"></nc-jiaotong>

        <!--------------      论文       ------------------------>

        <!-- 浙大宁波理工学院-论文 -->
        <ligong_lunwen :node="menuNode" :opening="opening" v-else-if="'ligong_lunwen'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </ligong_lunwen>
        <haiyang_lunwen :node="menuNode" :opening="opening" v-else-if="'haiyang_lunwen'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </haiyang_lunwen>
        <lishui_lunwen :node="menuNode" :opening="opening" v-else-if="'lishui_lunwen'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </lishui_lunwen>
        <gongshang_lunwen :node="menuNode" :opening="opening" v-else-if="'gongshang_lunwen'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </gongshang_lunwen>
        <hangdian_lunwen :node="menuNode" :opening="opening" v-else-if="'hangdian_lunwen'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </hangdian_lunwen>
        <hz_shifan_lunwen :node="menuNode" :opening="opening" v-else-if="'hz_shifan_lunwen'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </hz_shifan_lunwen>
        <jx_ligong_lunwen :node="menuNode" :opening="opening" v-else-if="'jx_ligong_lunwen'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </jx_ligong_lunwen>
        <huadong_lunwen :node="menuNode" :opening="opening" v-else-if="'huadong_lunwen'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </huadong_lunwen>

        <common_lunwen :node="menuNode" :opening="opening" v-else-if="'common'===templateName">
            <div slot="rechecking">
                <rechecking/>
            </div>
        </common_lunwen>
    </div>
</template>

<script>
    import haiyang from '@/components/lunwenTemplate/kaiti/haiyang'
    import lishui from '@/components/lunwenTemplate/kaiti/lishui'
    import ligong from '@/components/lunwenTemplate/kaiti/ligong'
    import gongshang from '@/components/lunwenTemplate/kaiti/gongshang'
    import hangdian from '@/components/lunwenTemplate/kaiti/hangdian'
    import Ligong_lunwen from "./lunwen/ligong_lunwen";
    import Haiyang_lunwen from "./lunwen/haiyang_lunwen";
    import Lishui_lunwen from "./lunwen/lishui_lunwen";
    import Gongshang_lunwen from "./lunwen/gongshang_lunwen";
    import Hangdian_lunwen from "./lunwen/hangdian_lunwen";
    import Common_lunwen from "./lunwen/common_lunwen";
    import Rechecking from "./rechecking/index";
    import JxLigong from "./kaiti/jx_ligong";
    import HzShifan from "./kaiti/hz_shifan";
    import NcJiaotong from "./kaiti/nc_jiaotong";
    import Hz_shifan_lunwen from "./lunwen/hz_shifan_lunwen";
    import Jx_ligong_lunwen from "./lunwen/jx_ligong_lunwen";
    import Huadong_lunwen from "./lunwen/huadong_lunwen";
    export default {
        components: {
            Huadong_lunwen,
            Jx_ligong_lunwen,
            Hz_shifan_lunwen,
            NcJiaotong,
            HzShifan,
            JxLigong,
            Rechecking,
            Hangdian_lunwen,
            Gongshang_lunwen,
            Haiyang_lunwen,
            Ligong_lunwen,
            Lishui_lunwen,
            Common_lunwen,
            haiyang, lishui, ligong, gongshang, hangdian,
        },
        props: {
            // // 论文名称
            // name: {
            //     type: String,
            //     required: true
            // },
            // // 是否在开放时间内
            // opening: {
            //     type: Boolean,
            //     default: false
            // }
        },
        data() {
            return {
                // 模版名称
                templateName: '',
                originTemplateName:'', // 原始模版名称
                opening:false,
                menuNode:'',
                changeType:'no-common',

            }
        },
        mounted() {
            this.templateName = this.name
            this.originTemplateName=this.name
        },
        methods:{
            // 切换上传模式
            changeUploadType(e){
                if (this.changeType === 'common') {
                    this.templateName=this.originTemplateName
                    this.changeType='no-common'
                }else{
                    this.templateName='common'
                    this.changeType='common'
                }
                sessionStorage.setItem("docFtl", this.templateName)

            },
            setData(templateName,opening,menuNode){
                this.originTemplateName=templateName
                this.changeType='no-common'

                this.templateName=templateName
                this.opening=opening
                this.menuNode=menuNode


            }
        }

    }
</script>

<style>

</style>
