<template>
    <div class="wrap">
        <div class="top-wrap">
            <div class="header">
                开放时间：{{openTime}}
                <el-tag v-if="!opening" size="small" type="danger">已关闭</el-tag>

            </div>
            <div class="submit-data" v-if="alreadySubmitData">
<!--                <el-tag class="tag" size="mini" v-if="result.checkFlag===0" type="warning">待检查</el-tag>-->
<!--                <el-tag class="tag" size="mini" v-else-if="result.checkFlag===1" type="success">检查通过</el-tag>-->
<!--                <div v-else-if="result.checkFlag===2" class="reSubmit-tag">-->
<!--                    <el-tag size="mini" class="tag" type="danger">检查驳回</el-tag>-->
<!--                    <div class="feedback-wrap" v-if="!!result.feedback">驳回原因：{{result.feedback}}</div>-->
<!--                    <el-button size="mini" type="danger" @click="reSubmit">重新提交</el-button>-->
<!--                </div>-->
<!--                <div class="time-wrap">-->
<!--                    <span>提交时间:</span>-->
<!--                    <span>{{result.createTime}}</span>-->
<!--                </div>-->
                <common-tip :result="result" @reSubmit="reSubmit"/>
            </div>
            <div class="wrap-btn" v-else>
                <common-action-button v-if="!alreadySubmitData&&opening" :load-stash="handleLoadStash" :save-stash="handleSaveStash" :commit="handleOk"/>
            </div>
        </div>


        <el-tabs class="tab-wrap" v-model="tabIndex">
            <el-tab-pane name="tab1" label="基本信息"></el-tab-pane>
            <el-tab-pane name="tab2" label="开题报告"></el-tab-pane>
            <el-tab-pane name="tab3" label="参考文献"></el-tab-pane>
<!--            <el-tab-pane name="tab4" label="预览">预览</el-tab-pane>-->
        </el-tabs>





        <!------------------------------------------------------基本信息 ------------------------------------------------->





        <div v-show="tabIndex==='tab1'" class="module">
            <h1>杭州电子科技大学</h1>

            <div class="title">
                <span>继续教育学院 开题报告</span>
            </div>

            <div class="content">
                <div class="row">
                    <span class="label">题目：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.title}}</span>
                    <el-input v-else v-model="baseInfo.title" placeholder="请输入题目"></el-input>
                </div>

                <div class="row">
                    <span class="label">学习类别：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.category}}</span>
                    <el-input v-else v-model="baseInfo.category" placeholder="请输入学习类别"></el-input>
                </div>
                <div class="row">
                    <span class="label">年级：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.className}}</span>
                    <el-input v-else v-model="baseInfo.className" placeholder="请输入年级"></el-input>
                </div>

                <div class="row">
                    <span class="label">专业：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.major}}</span>
                    <el-input v-else v-model="baseInfo.major" placeholder="请输入专业"></el-input>
                </div>


                <div class="row">
                    <span class="label">班级：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.grade}}</span>
                    <el-input v-else v-model="baseInfo.grade" placeholder="请输入班级"></el-input>
                </div>

                <div class="row">
                    <span class="label">学号：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.stuNo}}</span>
                    <el-input v-else v-model="baseInfo.stuNo" placeholder="请输入学号"></el-input>
                </div>
                <div class="row">
                    <span class="label">学生姓名：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.baseInfo.name}}</span>
                    <el-input v-else v-model="baseInfo.name" placeholder="请输入学生姓名"></el-input>
                </div>
                <div class="row">
                    <span class="label" >完成日期：</span>
                    <span class="label-gray"  v-if="alreadySubmitData" >{{alreadySubmitData.baseInfo.finishDate}}</span>
                    <el-date-picker
                            style="width: 100%"
                            v-else
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy年MM月dd日"
                            v-model="baseInfo.finishDate"
                            type="date"
                            placeholder="选择完成日期">
                    </el-date-picker>

                </div>
            </div>

        </div>





        <!------------------------------------------------------开题报告 ------------------------------------------------->
        <div v-show="tabIndex==='tab2'">

            <!--开题报告-->
            <common-doc-main-content v-if="alreadySubmitData" :editProp="false" ref="commonDocMainContentKaiti"
                                     stepName="开题报告"
                                     notice="说明：开题报告的内容包括选题的目的及意义（包含课题现状和发展趋势）、方案的分析论证及拟采取的解决办法、预期的成果、进度安排、参考文献等。
具体要求：在文献综述的基础上，根据选题拟定切实可行的论文写作提纲。"

                                     :title="alreadySubmitData.baseInfo.title"
            />
            <!--开题报告-->
            <common-doc-main-content v-else  ref="commonDocMainContentKaiti"
                                     stepName="开题报告"
                                     notice="说明：开题报告的内容包括选题的目的及意义（包含课题现状和发展趋势）、方案的分析论证及拟采取的解决办法、预期的成果、进度安排、参考文献等。
具体要求：在文献综述的基础上，根据选题拟定切实可行的论文写作提纲。"
                                     :title="baseInfo.title"
            />

        </div>

        <!------------------------------------------------------参考文献 ------------------------------------------------->
        <div v-show="tabIndex==='tab3'">

            <div class="content-body">
                <div class="headline">
                    <div class="name">参考文献</div>
                </div>

                <div class="content-border" style="width:800px;border:none">
                    <div class="wenxian-content" v-if="alreadySubmitData">
                        <div class="list" v-for="(item,index) of alreadySubmitData.kaitiContentList" :key="index">
                          <span >
                            [{{index+1}}].{{item}}
                          </span>
                        </div>

                    </div>
                    <div class="wenxian-content" v-else>
                        <div style="border:1px solid #f1f1f1;padding:10px;margin-bottom: 10px">
                            <el-tag size="mini" type="danger">例子：</el-tag>
                            <div class="list">
                                <span >林德金.消费心理与购买行为分析［M］.北京：机械工业出版社，2003，50-53.</span>
                            </div>
                            <div class="list">
                                <span >单世涛，朱文斌.大学科技园小企业集群化研究[J].浙江经济，2002，8.</span>
                            </div>
                            <div style="padding:10px 20px;color:#dd6161;font-weight: bold;font-size: 20px;width:600px">
                                注意：请不要在开头添加序号，系统自动生成！一次添加一条！
                            </div>
                        </div>
                        <div class="list" v-for="(item,index) of kaitiContentList" :key="index">
                          <span >
                            [{{index+1}}].{{item}}
                          </span>
                            <el-popconfirm
                                    @confirm="deleteKaitiContent(index)"
                                    title="这是一段内容确定删除吗？">
                                <i class="el-icon-close delete-icon" slot="reference" ></i>

                            </el-popconfirm>
                        </div>
                        <div class="content-input">
                            <el-input class="input" placeholder="请输入参考文献，确认按回车键" v-model="kaitiContent"
                                      @keyup.enter.native="addKaitiContent"></el-input>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import {saveLunwenContent, getCurrentLunwenContent,getLunwenStashContent,stashLunwenContent} from "@/api/lunwen.js";
    import CommonDocMainContent from '@/components/common/CommonDocMainContent'
    import CommonRichText from "../../common/CommonRichText";
    import CommonActionButton from "../../common/CommonActionButton";
    import CommonTip from "../../common/CommonTip";

    export default {
        name: "hangdian",
        props:{
            // 是否在开放时间内
            opening:{
                type:Boolean,
                default:false
            }
        },
        components: {CommonTip, CommonActionButton, CommonRichText, CommonDocMainContent},
        data() {
            return {
                baseInfo:{
                    // 题目
                    title: "",
                    // 学习类别
                    category: "",
                    // 年级
                    className: "",
                    // 专业
                    major:'',
                    // 班级
                    grade:'',
                    // 学号
                    stuNo:'',
                    // 学生姓名
                    name:'',
                    // 完成日期
                    finishDate:''

                },

                alreadySubmitData: null,
                openTime: null,
                result: null,

                wenxianList: [],
                // 文献综述总金额
                wenxianResult: [],
                levelTitle: '',
                data: '',
                showLevelTitle: false,
                showSubLevelTitle: false,
                showEditLevelTitle: false,
                showLevelContent: false,
                levelTitleDialogVisible: false,
                nodeContent: '',

                // 当前节点数据
                currentData: '',
                // 当前节点
                currentNode: '',

                // 文献内容输入
                wenxianContent: '',
                // 文献内容列表
                wenxianContentList: [

                ],


                // 开题文献输入
                kaitiContent:'',
                // 开题文献内容列表
                kaitiContentList:[],

                // 标签标记
                tabIndex: 'tab1'
            };
        },


        mounted() {
            this.openTime = sessionStorage.getItem("openTime");
            this.$refs.commonDocMainContentKaiti.setData([
                {
                    label:'选题的目的和意义',
                    id:this.uuid(),
                    content:[],
                    children:[
                        {label:'课题的背景',id:this.uuid(),content:[],children:[]},
                        {label:'选题的目的',id:this.uuid(),content:[], children:[]},
                        {label:'选题的现实意义',id:this.uuid(),content:[], children:[]},
                    ]
                },
                {
                    label:'国内外的研究动态',
                    id:this.uuid(),
                    content:[],
                    children:[
                        {label:'国内的研究情况',id:this.uuid(),content:[],children:[]},
                        {label:'国外的研究情况',id:this.uuid(),content:[], children:[]},
                        {label:'小结',id:this.uuid(),content:[], children:[]},
                    ]
                },
                {
                    label:'研究的难点与重点',
                    id:this.uuid(),
                    content:[],
                    children:[
                    ]
                },
                {
                    label:'研究方法与实施进度计划',
                    id:this.uuid(),
                    content:[],
                    children:[
                    ]
                },
                {
                    label:'预期成果',
                    id:this.uuid(),
                    content:[],
                    children:[
                    ]
                }
            ])
            this.getCheckedData();
        },
        methods: {

            // 删除开题文献
            deleteKaitiContent(index){
                this.kaitiContentList.splice(index,1)
            },

            // 获取开题数据
            getKaitiData() {
                return this.$refs.commonDocMainContentKaiti.getData();
            },

            // 添加开题文献内容
            addKaitiContent() {
                if (!this.kaitiContent) {
                    this.$message({
                        type: "warning",
                        message: "参考文献内容不能为空！"
                    });
                    return;
                }
                this.kaitiContentList.push(this.kaitiContent)
                this.kaitiContent = ""
            },



            uuid() {
                var s = [];
                var hexDigits = "0123456789abcdef";
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = "-";

                var uuid = s.join("");
                return uuid;
            },

            //重新提交
            reSubmit() {
                this.baseInfo.title = this.alreadySubmitData.baseInfo.title;
                this.baseInfo.category = this.alreadySubmitData.baseInfo.category;
                this.baseInfo.className = this.alreadySubmitData.baseInfo.className;
                this.baseInfo.major = this.alreadySubmitData.baseInfo.major;
                this.baseInfo.grade = this.alreadySubmitData.baseInfo.grade;
                this.baseInfo.stuNo = this.alreadySubmitData.baseInfo.stuNo;
                this.baseInfo.name = this.alreadySubmitData.baseInfo.name;
                this.baseInfo.finishDate = this.alreadySubmitData.baseInfo.finishDate;

                this.kaitiData = this.alreadySubmitData.kaitiData;
                this.kaitiContentList = this.alreadySubmitData.kaitiContentList;
                this.alreadySubmitData = null;
            },
            getCheckedData() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    let result = res.data.result;
                    if (result) {
                        let content = result.content;
                        if (content) {
                            this.alreadySubmitData = JSON.parse(content);
                            this.$refs.commonDocMainContentKaiti.setData(this.alreadySubmitData.kaitiData)
                            this.result = result;
                        }
                    }
                });
            },
            // 载入论文草稿
            handleLoadStash(){
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getLunwenStashContent({studentId, stepSubId}).then(res => {
                    if (res.data.success) {

                        let result = res.data.result;
                        let content = result.content;
                        if (content) {
                            let data = JSON.parse(content);
                            this.$refs.commonDocMainContentKaiti.setData(data.kaitiData)

                            this.baseInfo.title = data.baseInfo.title;
                            this.baseInfo.category = data.baseInfo.category;
                            this.baseInfo.className = data.baseInfo.className;
                            this.baseInfo.major = data.baseInfo.major;
                            this.baseInfo.grade = data.baseInfo.grade;
                            this.baseInfo.stuNo = data.baseInfo.stuNo;
                            this.baseInfo.name = data.baseInfo.name;
                            this.baseInfo.finishDate = data.baseInfo.finishDate;

                            this.kaitiContentList = data.kaitiContentList;

                            this.$message({
                                type: "success",
                                message: '载入成功！'
                            });
                        }
                    }else{
                        this.$message({
                            type: "success",
                            message: '载入失败:'+res.data.message
                        });
                    }


                });
            },
            // 暂存论文数据
            handleSaveStash(){
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                // let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                // 开题报告
                let kaitiData = this.getKaitiData();
                let content = {
                    baseInfo:this.baseInfo,
                    kaitiData: kaitiData, // 开题报告
                    kaitiContentList: this.kaitiContentList, // 开题报告参考列表
                };

                // 如果名字为2个字，则中间空一隔
                let name=content.baseInfo.name
                if (name.length === 2) {
                    let first = name.substr(0, 1);
                    let second = name.substr(1, 1);
                    content.baseInfo.name = first + " " + second;
                }

                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),

                };

                stashLunwenContent(form).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: "success",
                            message: '暂存成功'
                        });
                    } else {
                        this.$message({
                            type: "info",
                            message: res.data.message
                        });
                    }

                });
            },
            // 数据提交
            handleOk() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                // 开题报告
                let kaitiData = this.getKaitiData();
                let content = {
                    baseInfo:this.baseInfo,
                    wenxianContentList: this.wenxianContentList, // 文献综述文献列表
                    kaitiData: kaitiData, // 开题报告
                    kaitiContentList: this.kaitiContentList, // 开题报告参考列表
                };
                let flag = this.checkData(content);
                if (!flag) {
                    return;
                }
                // 如果名字为2个字，则中间空一隔
                // let name=content.baseInfo.name
                // if (name.length === 2) {
                //     let first = name.substr(0, 1);
                //     let second = name.substr(1, 1);
                //     content.baseInfo.name = first + " " + second;
                // }

                let node = sessionStorage.getItem("menuNode");
                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),
                    node,
                    docFilePath
                };
                let _this = this;

                this.$confirm("是否提交?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    type: "warning"
                })
                    .then(() => {
                        console.log(form)
                        saveLunwenContent(form).then(res => {
                            if (res.data.success) {
                                _this.$message({
                                    type: "success",
                                    message: res.data.message
                                });
                            } else {
                                _this.$message({
                                    type: "info",
                                    message: res.data.message
                                });
                            }

                            this.getCheckedData();
                        });
                    })
                    .catch(() => {
                    });
            },
            checkData(content) {
                let baseInfo=content.baseInfo
                let kaitiData = content.kaitiData;
                let kaitiContentList = content.kaitiContentList;
                let nameList={
                    title: '题目',
                    category: '学习类别',
                    className: '年级',
                    major: '专业',
                    grade: '班级',
                    stuNo: '学号',
                    name: '学生姓名',
                    finishDate: '完成日期',
                }
                for (let key in baseInfo) {
                    if (!baseInfo[key]) {
                        this.$message({
                            type: "warning",
                            message: nameList[key] + ":不能为空！"
                        });
                        this.tabIndex='tab1'
                        return false;
                    }
                }

                // 开题报告
                if (!kaitiData||kaitiData.length === 0) {
                    this.$message({
                        type: "warning",
                        message: '开题报告内容不能为空！'
                    });
                    this.tabIndex='tab3'
                    return false;
                }
                // 开题报告参考文献
                if (kaitiContentList.length===0) {
                    this.$message({
                        type: "warning",
                        message: '开题报告参考文献不能为空！'
                    });
                    this.tabIndex='tab3'
                    return false;
                }

                return true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    /*/deep/ .el-input__inner {*/
    /*    border: none;*/
    /*    border-bottom: 1px solid #dcdfe6;*/
    /*    border-radius: 0;*/
    /*    padding-left: 0px;*/
    /*}*/

    .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;

        .top-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .header {
                /*width: 100%;*/
            }
            .submit-data {
                display: flex;
                /*flex-direction: column;*/
                align-items: center;
                /*margin-bottom: 20px;*/
                /*margin-top: 20px;*/

                .time-wrap {
                    color: #aaa;
                    /*margin-top: 10px;*/
                    font-size: 14px;
                    margin-left:10px;
                }

                .reSubmit-tag {
                    display: flex;
                    /*flex-direction: column;*/
                    align-items: center;

                    .tag {
                        /*margin-bottom: 10px;*/
                        margin-right: 10px;
                    }

                    .feedback-wrap {
                        /*margin-bottom: 10px;*/
                        color: #dd6161;
                        text-decoration: underline;
                    }
                }
            }

        }
        .tab-wrap {
            width: 100%;
            margin-top: 10px;
        }




        h1 {
            color: #004ea3;
            letter-spacing: 3px;
            text-align: center;
        }

        .title {
            // font-weight: bold;
            font-size: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                &:nth-child(1) {
                    font-weight: bold;
                }
            }
        }

        .content {
            margin-top: 20px;
            width: 600px;

            .row {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .label {
                    width: 280px;

                }

                .label-gray {
                    color: #aaa;
                }
            }
        }

        .content-border {
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 50px;
            width: 1200px;
            border: 1px solid #f1f1f1;
            display: flex;

            .wenxian-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .list {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /*flex-direction: column;*/

                    span {
                        color: #606266;
                        /*margin-bottom: 4px;*/
                    }
                    .delete-icon{
                        font-size: 20px;
                        color:#dd6161;
                        &:hover{
                            cursor: pointer;
                            background: #dd6161;
                            color:#ffffff;
                        }
                    }
                }

                .content-input {
                    display: flex;

                    .input {
                        flex: 1;
                    }
                }
            }


            .content-left {
                flex: 1;
                padding: 20px;

                .main-content-wrap {
                    .level-content-wrap {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                    }
                }

                .tip {
                    margin-bottom: 10px;
                }
            }

            .middle {
                width: 1px;
                background: #f1f1f1;
            }

            .content-right {
                flex: 1;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .content-right-tip {
                    display: flex;
                    color: #82848a;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .fullscreen {
                        cursor: pointer;
                        font-size: 14px;

                        &:hover {
                            color: #409EFF;
                        }
                    }
                }


                .level-content {
                    margin-top: 20px;
                    width: 100%;
                }

                .clear-content {
                    margin-top: 10px;
                    align-self: flex-end;
                }
            }
        }
    }


    .content-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        .lable {
            align-self: start;
            // color:#82848a;
        }

        .headline {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 400px;

            .name {
                font-size: 30px;
                font-weight: bold;
            }

            .notice {
                align-self: start;
                font-size: 10px;
                color: #dd6161;
                margin-top: 10px;

                &:before {
                    content: '说明：';
                }
            }
        }
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        color: #303133;
        font-weight: bold;

    }

    .add-level-wrap {
        margin-bottom: 20px;

        .level-input {
            display: flex;
            margin-bottom: 20px;
        }
    }
    .wrap-btn{
        /*width: 100%;*/
        text-align: right;
    }
</style>
