<template>
    <el-dialog title="查重报告上传" :visible="visible" @close="visible=false">
        <div v-if="data&&data.recheckingUrl" style="margin-bottom: 20px">
            <span>已上传文件：</span>
            <a :href="'/api/sys/common/download/'+data.recheckingUrl" target="_blank">点击查看</a>
        </div>
        <el-upload
                ref="upload"
                accept=".zip,.rar,.pdf"
                drag
                :data="params"
                :headers="{authorization: token}"
                :on-success="fileUploadSuccess"
                :before-upload="fileBeforeUpload"
                action="/api/lunwen/recheckingUpload"
                :auto-upload="false"
                >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传zip/rar/pdf文件，且不超过50M</div>


        </el-upload>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="toUpload">上传</el-button>
            <el-button @click="cancel">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {getCurrentLunwenContent} from '@/api/lunwen.js'

    export default {
        name: "RecheckingUploadModal",
        data(){
            return{
                visible:false,
                token:null,
                params:{},
                data:null,
                loading:null
            }
        },
        created(){
            this.token = sessionStorage.getItem('token');
        },
        methods:{
            show(params){
                this.visible=true
                this.params=params

                this.$nextTick(()=>{
                    this.$refs.upload.clearFiles()

                })

                getCurrentLunwenContent(params).then(res=>{
                    this.data=res.data.result
                })
            },
            // 取消
            cancel(){
                this.visible=false
                this.$refs.upload.clearFiles()
            },
            // 上传
            toUpload(){
                this.loading = this.$loading({
                    lock: true,
                    text: '文件上传中，请稍后...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

              this.$refs.upload.submit()
            },
            fileUploadSuccess(response, file, fileList){

                if(response.success){
                    this.$notify({
                        title: '提示',
                        message: '文件上传成功！',
                        type: 'success'
                    });
                    this.visible=false
                    if (this.loading) {
                        this.loading.close();
                    }
                }else{
                    this.$notify({
                        title: '提示',
                        message: `${response.message}`,
                        type: 'error'
                    });
                }
            },
            fileBeforeUpload( file){
                // 50M
                let limitSize=50*1024*1024
                let fileSize=file.size
                if (fileSize > limitSize) {
                    this.$notify({
                        title: '警告',
                        message: '文件大小超出限制,最多只能上传50M大小的文件!',
                        type: 'warning'
                    });
                    return false;

                }
            }
        }
    }
</script>

<style scoped lang="scss">
/deep/ .el-dialog__header{
    text-align: center !important;
}
/deep/ .el-dialog__body{
    text-align: center !important;
}
    .dialog-footer{
        text-align: center;
    }
</style>
