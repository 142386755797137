<template>
  <div class="wrap">
    <div class="card">
        <div class="title">学生论文管理系统</div>
        <div class="input-wrap">
            <span class="label">账号</span>
            <el-input v-model="name" placeholder="请输入姓名" @keydown.enter.native="login"></el-input>
        </div>
        <div class="input-wrap">
            <span class="label">密码</span>
            <el-input v-model="cardId" placeholder="请输入身份证号" @keydown.enter.native="login"></el-input>
        </div>
        <div class="btn-wrap">
            <el-button style="width:200px" type="primary" @click="login" >点击登录</el-button>
        </div>
    </div>

      <el-dialog title="请选择登录" :visible="visible" @close="visible=false">

          <el-radio-group v-model="select" class="info-row" v-if="stuInfoList">
              <el-radio v-for="(item,index) in stuInfoList" :key="index" :label="index" class="radio">
                      <span>
                          <i class="el-icon-user"></i>
                          <span>{{item.name}}</span>
                      </span>
                      <span>
                          <i class="el-icon-school"></i>
                          <span>{{item.toSchool}}</span>
                      </span>
                      <span>
                          <i class="el-icon-date"></i>
                          <span>{{item.batch}}批次</span>
                      </span>
                      <span>
                          <i class="el-icon-tickets"></i>
                          <span>{{eduStatusObj[item.eduStatus]}}</span>
                      </span>

              </el-radio>

          </el-radio-group>
          <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="selectLogin">登 录</el-button>
          </span>
      </el-dialog>
  </div>
</template>

<script>
import {handleLogin,multiSelectLogin} from '@/api/login'
export default {

    data(){
        return{
            name:'',
            cardId:'',
            select:'',
            visible:false,
            token:'',
            stuInfoList:[],
            eduStatusObj:{
                "3":'在读',
                "4":'毕业'
            }
        }
    },
    methods:{
        login(){
            let form={
                name:this.name,
                cardId:this.cardId
            }
            handleLogin(form).then(res=>{
                this.token=''
                this.stuInfoList=[]
                if(res.data.success){
                    let result=res.data.result
                    if(result.multi){
                        this.visible=true
                        this.token=result.token
                        this.stuInfoList=result.data
                    }else{
                        sessionStorage.setItem("userInfo",JSON.stringify(res.data.result.data))
                        sessionStorage.setItem("token",res.data.result.token)
                        sessionStorage.setItem("subId",-1)
                        sessionStorage.setItem("menuNode",-1)
                        this.$router.replace({name:'index'})
                    }

                }
            })
        },
        selectLogin(){
            let studentPage=this.stuInfoList[this.select]
            multiSelectLogin(studentPage,{token:this.token}).then(res=>{
                if(res.data.success){
                    sessionStorage.setItem("userInfo",JSON.stringify(res.data.result.data))
                    sessionStorage.setItem("token",res.data.result.token)
                    sessionStorage.setItem("subId",-1)
                    sessionStorage.setItem("menuNode",-1)
                    this.$router.replace({name:'index'})
                    this.visible=false
                }
            })
        }
    }

}
</script>
<style lang="scss" scoped>
.wrap{
    width:100%;
    margin-top: 80px;
    .info-row{
        display: flex;
        flex-direction: column;
        width: 100%;
        .radio{
            margin-bottom: 20px;
            span{
                margin-right: 10px;
                font-size: 20px;
            }
        }
    }
    .card{
        text-align: center;
        background: #ffffff;
        padding:20px;
        width:500px;
        border-radius: 10px;
        margin:0 auto;
        .title{
            margin-bottom: 20px;
            font-size: 20px;
        }
        .input-wrap{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .label{
                width:100px;
            }
        }
        .btn-wrap{
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>>
