<template>

    <!--
        这里是海洋大学的 添加一行的通用组件
     -->
    <div>

        <div class="paragraph" v-for="(item,index) of dataList" :key="index">
            <span class="label">[{{index+1}}] &nbsp;</span>
            <el-input class="input" v-model="item.value"  placeholder="请输入内容"></el-input>
            <div class="actions">
                <i class="el-icon-close del-paragraph" @click="delLineContent(index)"></i>

            </div>
        </div>
        <el-button class="add-btn" type="primary" @click="addLineContent">添加一行</el-button>
    </div>
</template>

<script>
    export default {
        name: "CommonLineText",
        props:{
            contentList:{
                type:Array,
                require:true
            }
        },
        data(){
          return {
              dataList:[]
          }
        },
        created(){
            this.dataList=this.contentList
        },
        methods:{
            getData(){
               return this.dataList;
            },
            setData(contentList){
                this.dataList=contentList
            },
            delLineContent(index){
                let that=this
                this.$confirm('是否删除这段内容?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    that.dataList.splice(index,1)

                })
            },
            addLineContent(){
                this.dataList.push({
                    value:''
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/ .el-input__inner {
        padding-left: 10px !important;
    }
    .paragraph{
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .label{
            margin-right: 10px;
            width: 20px;

        }



    }
    .add-btn{
        margin-top:10px;
    }
    .actions{

        .edit-paragraph{
            color:#3a8ee6;
            font-weight: bold;
            &:hover{
                background: #3a8ee6;
                color:#ffffff;
                cursor: pointer;
            }
        }
        .del-paragraph{
            color:#dd6161;
            font-weight: bold;
            margin-left: 10px;
            &:hover{
                background: #dd6161;
                color:#ffffff;
                cursor: pointer;
            }
        }
    }
</style>
