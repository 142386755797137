<template>
    <el-dialog
            :visible="visible"
            fullscreen
            :close-on-click-modal="true"
            @close="visible=false"
    >

        <template v-for="(item,index) of contentList" >
            <div class="text-wrap"  v-if="item.type==='text'" :key="'text_'+index">
                {{item.content}}
            </div>
            <div v-if="item.type==='image'" class="img-wrap" :key="'image_'+index">
                <img    :src="item.content" />
                <span v-if="!!item.remark">{{item.remark}}</span>
            </div>
            <div class="table-wrap" v-if="item.type==='table'"  :key="'table_'+index">
                <table :border="1"  >

                    <tr v-for="(tableItem,tableIndex) of item.content" :key="'table_'+tableIndex">
                        <td v-for="(subItem,subIndex) of tableItem.columns" :key="'table_'+subIndex+'_'+tableIndex">
                            {{subItem.value}}
                        </td>
                    </tr>
                </table>
                <span v-if="!!item.remark">{{item.remark}}</span>
            </div>
        </template>

    </el-dialog>
</template>

<script>
    import RichTextImage from "./RichTextImage";
    import RichTextTable from "./RichTextTable";
    import RichTextInput from "./RichTextInput";
    /**
     * 总共3中格式类型
     */
    export default {
        name: "RichFullscreen",
        components: {RichTextInput, RichTextTable, RichTextImage},
        data(){
            return {
                contentList:[],
                visible:false
            }
        },
        methods:{
            show(contentList){
                this.visible=true
                this.contentList=contentList
            }
        }
    }
</script>

<style scoped lang="scss">
    .wrap{
        display: flex;
        flex-direction: column;

        .text-wrap{
            width: 100%;
        }
        .img-wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
                object-fit: contain;
                margin-right: 10px;
            }
        }
        .table-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            table{
                border-collapse: collapse;
                margin-top: 30px;
                width: 100%;
                tr {
                    td{
                        padding:0 10px;
                    }
                }
            }
        }
        .action-btns{
            margin-top: 20px;
        }
    }
</style>
