<template>
    <!--    论文模板拷贝-->
    <el-dialog width="400px" title="请选择需要拷贝的论文" :visible="visible"  @close="visible=false">
        <el-select v-model="stepId" placeholder="请选择">
            <el-option
                    v-for="(item,index) in dataList"
                    :key="item.id"
                    :label="item.stepName"
                    :value="item.id">
            </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getStepList} from '@/api/lunwen'
    export default {
        name: "CopyLunwenDialog",
        data() {
            return{
                visible:false,
                dataList:[],
                stepId:null
            }
        },
        methods:{
            confirm(){
                this.$emit("ok",this.stepId)
                this.visible=false
                this.$notify.success({
                    title: '提示',
                    message: '操作成功！'
                });
                this.stepId=null
            },
            cancel(){
              this.visible=false
              this.stepId=null
            },
            show(){
                this.visible=true

                let userInfo=sessionStorage.getItem("userInfo")
                userInfo=JSON.parse(userInfo)
                let sysStudentId=userInfo.sysStudentId

                let schoolName=userInfo.toSchool
                let learnType=userInfo.learnType
                getStepList({sysStudentId}).then(res=>{
                    if(res.data.success){
                        this.dataList=res.data.result
                    }
                })
                // getStepList({schoolName,learnType}).then(res=>{
                //     if(res.data.success){
                //         this.dataList=res.data.result
                //     }
                // })
            }
        }
    }
</script>

<style scoped lang="scss">
   /deep/ .el-dialog__header{
        display: flex;
    }
   /deep/ .el-dialog__body{
        display: flex;
    }
</style>
