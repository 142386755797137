<template>
	<div class="wrap">

		<div class="content-wrap">
			<div class="left-wrap">
				<div class="user-info">
					<span class="title">基础信息</span>
					<div class="content">
						<div class="row">
							<div class="col">
								<span class="label">姓名:</span>
								<span class="value">{{userInfo.name}}</span>
							</div>
							<div class="col">
								<span class="label">身份证号:</span>
								<span class="value">{{userInfo.cardId}}</span>
							</div>

						</div>
						<div class="row">
							<div class="col">
								<span class="label">性别:</span>
								<span class="value">{{userInfo.sex}}</span>
							</div>
							<div class="col">
								<span class="label">学习形式:</span>
								<span class="value">{{userInfo.learnType}}</span>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<span class="label">报考学校:</span>
								<span class="value">{{userInfo.toSchool}}</span>
							</div>
							<div class="col">
								<span class="label">专业:</span>
								<span class="value">{{userInfo.major}}</span>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<span class="label">层次:</span>
								<span class="value">{{userInfo.level}}</span>
							</div>
							<div class="col">
								<span class="label">批次:</span>
								<span class="value">{{userInfo.batch}}</span>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<span class="label">学号:</span>
								<span class="value">{{userInfo.stuNo}}</span>
							</div>
							<div class="col">
								<span class="label">班级:</span>
								<span class="value">{{userInfo.className}}</span>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<span class="label">指导老师:</span>
								<span class="value">{{userInfo.zhidaoTeacherName}}</span>
							</div>
							<div class="col">
								<span class="label">签名文件:</span>
								<a style="color:#4e8fe8"  :href="'/api/sys/common/download/'+userInfo.qianmingPdf" class="value">查看预览</a>
							</div>

						</div>
					</div>
				</div>
				<div class="document-wrap">
					<div class="document-download">
						<span class="title">文档下载</span>
						<div class="content">
							<div class="item" v-for="(item,index) of data.documentFiles">
								<!-- <span class="label">{{item.name}}</span> -->
								<el-popover class="label"
								    placement="top-start"
								    width="200"
								    trigger="hover"
								    :content="item.name">
								    <span slot="reference">{{item.name}}</span>
								  </el-popover>
								<a :href="'/api/sys/common/download/'+item.content" target="_blank">下载</a>
							</div>
						</div>
					</div>
					<div class="document-remark">
						<span class="title">通知说明</span>
						<div class="content">
							<div class="item" v-for="(item,index)  of data.noticeList"
								@click="showContent(item.name,item.content)">
								<!-- <span class="label">{{item.name}}</span> -->
								<el-popover class="label"
								    placement="top-start"
								    width="200"
								    trigger="hover"
								    :content="item.name">
								    <span slot="reference">{{item.name}}</span>
								  </el-popover>
								<span class="time">{{parseTime(item.createTime)}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right-wrap">
				<div class="top">
					<span class="title">当前阶段</span>
					<div class="body">
						<div class="row">
							<span class="tag">论文标题</span>
							<span class="normal-value" v-if="data.lunwenXuanti">{{data.lunwenXuanti.content}}</span>
							<span class="normal-value" v-else>无</span>
						</div>
						<div class="row">
							<span class="tag">阶段</span>
							<template v-if="data.currentStepInfo&&data.currentStepInfo.stepName">
								<span class="value">[{{data.currentStepInfo.stepName}}]</span>
								<span class="a" style="margin-left: 10px;"
									@click="nodeClick(data.currentStepInfo.node)">进入<i style="font-weight:bold"
										class="el-icon-arrow-right"></i></span>
							</template>

						</div>
						<div class="row">
							<span class="tag">状态</span>
							<template v-if="data.currentStepInfo&&data.currentStepInfo.stepName">
								<span class="tag primary" v-if="data.currentStepInfo.checkFlag=='0'">待审核</span>
								<span class="tag success" v-else-if="data.currentStepInfo.checkFlag=='1'">审核通过</span>
								<span class="tag error" v-else-if="data.currentStepInfo.checkFlag=='2'">审核驳回</span>
								<span class="tag error" v-else>{{data.currentStepInfo.checkFlag}}</span>
							</template>
							<!-- <span class="tag success">通过</span> -->
							<!-- <span class="tag error">驳回</span> -->
						</div>
						<div class="row" style="align-items: flex-start;">
							<span class="tag">审核日志</span>
							<div class="log-content" v-if="data.lunwenLogVo">
								<div class="log">
									<span class="log-label">审核阶段：</span>
									<!-- <span class="log-value">{{data.lunwenLogVo.node}}</span> -->
									<el-tag size="mini" style="margin-right: 4px;"
										effect="plain">{{data.lunwenLogVo.stepName}}</el-tag>
									<el-tag v-if="data.lunwenLogVo.replyType==0" type="primary" size="mini">待审核</el-tag>
									<el-tag v-if="data.lunwenLogVo.replyType==1" type="success" size="mini">通过</el-tag>
									<el-tag v-if="data.lunwenLogVo.replyType==2" type="danger" size="mini">驳回</el-tag>
								</div>
								<div class="log">
									<span class="log-label">审核意见：</span>
									<span class="log-value">{{data.lunwenLogVo.teacherReply||'无'}}</span>
								</div>
								<!-- <div class="log">
									<span class="log-label">审核老师：</span>
									<span class="log-value">{{data.lunwenLogVo.checkTeacher||'无'}}</span>
								</div> -->
								<div class="log">
									<span class="log-label">提交内容：</span>
									<span v-if="checkIsFile(data.lunwenLogVo.content)"></span>

									<span class="log-value"
										v-if="data.lunwenLogVo.node=='node1'">{{data.lunwenLogVo.content||'未知'}}</span>
									<template v-else>
										<a v-if="checkIsFile(data.lunwenLogVo.content)" style="color:#4e8fe8"
											target="_blank"
											:href="paseOfficeUrl(domain+'/api/sys/common/download/'+data.lunwenLogVo.content)">预览</a>
										<a v-else style="color:#4e8fe8" target="_blank"
											:href="domain+'/api/sys/common/download/'+data.lunwenLogVo.content">下载</a>
									</template>
								</div>
								<div class="log">
									<span class="log-label">审核时间：</span>
									<span class="log-value">{{data.lunwenLogVo.createTime}}</span>
								</div>
								<div class="log more">
									<a @click="showMoreLog">查看更多</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bottom">
					<div class="title">论文时间流程 <span v-if="data.luwenTime">（{{data.luwenTime.beginDate}} ~
							{{data.luwenTime.endDate}} ）</span></div>
						<el-table class="body" border :data="data.lunwenSteps" style="width: 100%">
							<el-table-column prop="stepName" label="阶段"  align="center">
								<template slot-scope="scope">
									<span class="table-col-span">
										{{scope.row.stepName}}
									</span>
								</template>
							</el-table-column>
							<el-table-column label="时间"  align="center"  width="320">
								<template slot-scope="scope">
									<span class="time">{{scope.row.openTime}} ~ {{scope.row.closeTime}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="commitFlag" label="提交状态" align="center" width="100">
								<template slot-scope="scope">
									<el-tag type="success" v-if="scope.row.commitFlag==='1'">已提交</el-tag>
									<el-tag type="info" v-else>未提交</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="checkFlag" label="审核状态" align="center" width="100">
								<template slot-scope="scope">
									<el-tag  v-if="scope.row.checkFlag==='0'">待审核</el-tag>
									<el-tag type="success"  v-else-if="scope.row.checkFlag==='1'">已通过</el-tag>
									<el-tag type="danger" v-else-if="scope.row.checkFlag==='2'">被驳回</el-tag>
									<el-tag type="info" v-else>{{scope.row.checkFlag}}</el-tag>

								</template>
							</el-table-column>
						</el-table>
						<!-- <div class="row">
							<span class="tag">阶段</span>
							<span class="time">提交时间</span>
							<span class="time">审核状态</span>
							<span class="time">提交状态</span>
						</div>
						<div class="row" v-for="(item,index) of data.lunwenSteps">
							<span class="tag">{{item.stepName}}</span>
							<span class="time">{{item.openTime}} ~ {{item.closeTime}}</span>
							
							<span class="tag plain-primary" v-if="item.checkFlag==='0'">进行中</span>
							<span class="tag plain-success" v-else-if="item.checkFlag==='1'">已完成</span>
							<span class="tag plain-error" v-else-if="item.checkFlag==='2'">被驳回</span>
							<span class="tag plain-info" v-else>{{item.checkFlag}}</span>
							
							<span class="tag plain-success" v-if="item.commitFlag==='1'">已提交</span>
							<span class="tag plain-info" v-else>未提交</span>
						</div> -->
				</div>
			</div>
		</div>


		<el-dialog center :title="title" :visible="visible" @close="visible=false">
			<div v-html="content"></div>
		</el-dialog>
		<LunwenLogDialog ref="LunwenLogDialog" />
	</div>
</template>

<script>
	import {
		getLunwenHomeInfo
	} from '@/api/lunwen'
	import LunwenLogDialog from '@/components/common/LunwenLogDialog.vue'
	export default {
		components: {
			LunwenLogDialog
		},
		data() {
			return {
				userInfo: {},
				cardStyle: {
					width: "300px"
				},
				title: '',
				content: '',
				visible: false,
				data: {

				},
				domain: ''
			}
		},
		created() {
			let userInfo = sessionStorage.getItem("userInfo")
			if (!userInfo) {
				this.$router.push({
					name: 'login'
				})
			} else {
				this.userInfo = JSON.parse(userInfo)
			}

			let sex = this.userInfo.sex
			let level = this.userInfo.level
			let learnType = this.userInfo.learnType
			if (sex == 1) {
				this.userInfo.sex = "男"
			} else if (sex == 2) {
				this.userInfo.sex = "女"
			}

			if (level == 1) {
				this.userInfo.level = "专科"
			} else if (level == 2) {
				this.userInfo.level = "本科"
			}
			if (learnType == 1) {
				this.userInfo.learnType = "成教"
			} else if (learnType == 2) {
				this.userInfo.learnType = "网教"
			}

			let stepId = this.userInfo.stepId
			let sysStudentId = this.userInfo.sysStudentId
			getLunwenHomeInfo({
				stepId,
				sysStudentId
			}).then(res => {
				if (res.data.code == 0) {
					this.data = res.data.result
				}
			})

			this.domain = window.location.origin
		},
		methods: {
			parseTime(d){
				let date=new Date(d)
				let m=date.getMonth()+1
				let day=date.getDate()
				if(m<10){
					m='0'+m;
				}
				if(day<10){
					day='0'+day;
				}
				return date.getFullYear()+'-'+m+'-'+day
			},
			paseOfficeUrl(url) {
				url = encodeURIComponent(url + "?_t=" + new Date().getTime())
				return `https://view.officeapps.live.com/op/embed.aspx?src=${url}&amp;wdPrint=0&amp;wdEmbedCode=0&amp;wdStartOn=1`
			},
			checkIsFile(content) {
				if (content.lastIndexOf(".docx") > 0) {
					return true
				}
				return false;

			},
			nodeClick(node) {
				this.$emit("nodeClick", node)
			},
			showMoreLog() {
				this.$refs.LunwenLogDialog.showAll()
			},
			showContent(title, content) {
				this.title = title
				this.content = content
				this.visible = true
			}
		}
	};
</script>
<style>
	.content::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		background-color: transparent;
	}

	.body::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		background-color: transparent;
	}
</style>
<style lang="scss" scoped>
	
	.table-col-span{
		color: #2c3e50;
		font-weight: bold;
	}
	.wrap {
		width: 100%;
		min-height: 650px;

		.content-wrap {
			display: flex;
			min-height: 650px;

			.title {
				display: flex;
				align-items: center;
				color: #2c3e50;

				&:before {
					margin-right: 8px;
					content: ' ';
					display: inline-block;
					height: 20px;
					width: 5px;
					background-color: #2c3e50;
					border-radius: 5px;
				}
			}

			.left-wrap {
				width: 800px;
				display: flex;
				flex-direction: column;
				border: 5px solid #f1f1f1;
				border-radius: 5px;

				.user-info {
					padding: 10px;

					.content {
						margin-top: 20px;
						display: flex;
						flex-direction: column;
						font-size: 14px;

						.row {
							display: flex;
							margin-bottom: 12px;

							.col {
								flex: 1;

								.label {
									color: #666;
								}
							}
						}
					}

				}

				.document-wrap {
					border-top: 5px solid #f1f1f1;

					display: flex;
					flex: 1;

					.document-download {
						flex: 1;
						padding: 10px;
						border-right: 5px solid #f1f1f1;

						.content {
							margin-top: 20px;
							max-height: 350px;
							overflow-y: scroll;

							.item {
								display: flex;
								justify-content: space-between;
								border-bottom: 1px solid #f1f1f1;
								font-size: 14px;
								margin-bottom: 4px;
								padding-bottom: 4px;

								span.label {
									color: #666;
									display: block;
									width: 250px;
									text-overflow: ellipsis;
									overflow: hidden;
									white-space: nowrap;
									flex:1;
								}

								a {
									color: #4e8fe8;
									cursor: pointer;

									&:hover {
										text-decoration: underline;
									}
								}
							}
						}

					}

					.document-remark {
						flex: 1;
						padding: 10px;

						.content {
							margin-top: 20px;
							max-height: 350px;
							overflow-y: scroll;

							.item {
								color: #666;
								border-bottom: 1px solid #f1f1f1;
								font-size: 14px;
								margin-bottom: 4px;
								padding-bottom: 4px;
								text-overflow: ellipsis;
								width: 98%;
								overflow: hidden;
								cursor: pointer;
								display: flex;
								align-items: center;

								span.label {
									color: #333;
									display: block;
									width: 250px;
									text-overflow: ellipsis;
									overflow: hidden;
									white-space: nowrap;
									flex:1;
									&:hover {
										text-decoration: underline;
									}
								}

								.time {
									font-size: 12px;
								}
							}
						}
					}
				}
			}

			.right-wrap {
				flex: 1;
				display: flex;
				flex-direction: column;
				border: 5px solid #f1f1f1;
				border-radius: 5px;
				margin-left: 10px;

				.top {
					padding: 10px;

					.body {
						margin-top: 10px;

						.row {
							&:nth-last-child(1) {
								margin-bottom: 0 !important;
							}

							display: flex;
							align-items: center;
							font-size: 14px;
							margin-bottom: 15px;

							.tag {
								background-color: #2c3e50;
								color: #fff;
								padding: 2px 4px;
								border-radius: 5px;
								width: 60px;
								text-align: center;
							}

							.primary {
								background-color: #4e8fe8;
								margin-left: 5px;
							}

							.success {
								background-color: #67C23A;
								margin-left: 5px;
							}

							.error {
								background-color: #F56C6C;
								margin-left: 5px;
							}

							.value {
								font-size: 18px;
								margin-left: 4px;
								margin-right: 4px;
								font-weight: bold;
								color: #333;
							}

							.normal-value {
								font-size: 14px;
								margin-left: 4px;
								margin-right: 4px;
								color: #666;

							}

							.a {
								font-size: 18px;
								color: #4e8fe8;
								cursor: pointer;
								// &:hover{
								// 	border-bottom: 1px solid #4e8fe8;
								// }
							}

							.log-content {
								margin-left: 5px;

								.log {
									margin-bottom: 5px;

									.log-label {
										color: #666;
									}
								}

								.more {
									a {
										color: #4e8fe8;
										cursor: pointer;

										&:hover {
											text-decoration: underline;
										}
									}
								}
							}
						}
					}
				}

				.bottom {
					padding: 10px;
					border-top: 5px solid #f1f1f1;
					flex: 1;

					.body {
						margin-top: 20px;
						max-height: 280px;
						overflow-y: scroll;

						.row {
							&:nth-last-child(1) {
								margin-bottom: 0 !important;
							}

							display: flex;
							align-items: center;
							font-size: 14px;
							margin-bottom: 15px;

							.tag {
								background-color: #2c3e50;
								color: #fff;
								padding: 2px 4px;
								border-radius: 5px;
								width: 80px;
								text-align: center;
							}

							.time {
								margin: 0 10px;
								width: 240px;
							}

							.plain-success {
								background-color: #fff !important;
								color: #67C23A;
							}

							.plain-primary {
								background-color: #fff !important;
								color: #4e8fe8;
							}

							.plain-error {
								background-color: #fff !important;
								color: #F56C6C;
							}

							.plain-info {
								background-color: #fff !important;
								color: #666;
							}
						}
					}
				}
			}
		}


		.btn-wrap {
			margin-top: 40px;
			text-align: center;
		}

		.table {
			margin: 0 auto;
			margin-top: 20px;
			border-collapse: collapse;
			padding: 20px;
			border-spacing: 10px 50px;
			text-align: center;
			width: 80%;
			border-color: #aaa;
			border-radius: 10px;

			tr {
				td {
					color: #303133;

					&:nth-child(odd) {
						color: #909399;
					}
				}
			}

		}


	}
</style>