<template>
    <el-dialog title="图片上传" :visible="visible" width="500px" @close="visible=false">
        <div class="dialog-wrap">
            <div class="remark-wrap" style=" margin-top: 0;margin-bottom: 10px">
                <span>图片头:</span>
                <el-input class="input" placeholder="请输入" v-model="header"/>
            </div>
            <div class="image-wrap" v-if="!!imageSrc">
                <img alt="未知图片" :src="imageSrc"/>
                <i class="el-icon-close delete-img" @click="deleteImage"></i>
            </div>
            <input accept="image/png, image/jpeg, image/jpg" class="file" type="file" ref="file" value="图片上传" @change="change($event)"/>

            <div class="remark-wrap">
                <span>图片说明:</span>
                <el-input class="input" placeholder="请输入" v-model="remark"/>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>


</template>

<script>
    export default {
        name: "RichTextImage",
        data() {
            return {
                visible: false,
                imageSrc: '',
                remark: '',
                header: '',
                action:'add'
            }
        },
        methods: {
            // 确认回调
            confirm(){
                let flag=this.checkData()
                if(!flag){
                    return;
                }
                this.$emit("ok",this.imageSrc,this.remark,this.action,this.header)
                this.visible=false
                this.clear()
            },
            checkData(){
                let imageSrc=this.imageSrc
                let remark=this.remark
                if (!imageSrc || !remark) {
                    this.$message.warning({
                        type: 'warning',
                        message: '图片未选择或图片说明为空！'
                    })
                    return false;
                }
                return true;
            },
            // 显示上传图片框
            show() {
                this.imageSrc=''
                this.remark=''
                this.header=''
                this.visible = true
                this.action='add'
            },
            // 显示上传图片框
            edit(content,remark,header='') {
                this.imageSrc=content
                this.remark=remark
                this.header=header
                this.visible = true
                this.action='edit'
            },
            //  删除图片
            deleteImage() {
                this.clear()
            },
            clear(){
                this.imageSrc = ''
                this.$refs.file.value=''
            },
            // 图片上传
            change(e) {
                let file = e.target.files[0];
                // 2M
                let limitSize=1000 * 1000 * 2
                let size=file.size
                if (size > limitSize) {
                    this.$message({
                        type: 'warning',
                        message: '图片大小不能大于2M！'
                    });
                    this.clear()
                    return ;
                }

                let fileReader = new FileReader()
                let that = this
                fileReader.readAsDataURL(file)
                fileReader.onload = function (e) {
                    that.imageSrc = e.currentTarget.result
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .dialog-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .image-wrap {

            position: relative;

            .delete-img {
                position: absolute;
                top: 0;
                right: -10px;
                color: #dd6161;
                font-weight: bold;

                &:hover {
                    background: #dd6161;
                    color: #ffffff;
                    cursor: pointer;
                }
            }

            img {
                width: 100px;
                object-fit: contain;
                margin-right: 10px;

            }
        }

        .file {
            margin-top: 10px;
        }

        .remark-wrap {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 30px;
            span {
                width: 80px;
            }
            .input{
                flex:1;
            }
        }

    }

</style>
