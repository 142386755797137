import axios from '@/axios'

/* 验证登陆 */
export function handleLogin (data) {
  return axios({
    url: '/lunwen/login',
    params:data,
    method: 'get',

  })
}
/* 选择指定用户后登录 */
export function multiSelectLogin (data,params) {
  return axios({
    url: '/lunwen/multiSelectLogin',
    data:data,
    params:params,
    method: 'post',

  })
}