<template>
    <el-dialog width="1000px" title="添加表格" :visible="visible" @close="visible=false">
        <div class="param-wrap">
            <div class="item">
                <span>行数：</span>
                <el-input-number v-model="row" :min="1"/>
            </div>
            <div class="item">
                <span>列数：</span>
                <el-input-number v-model="column" :min="1"/>
            </div>
        </div>
        <div class="table-desc">
            <span>表头</span>
            <el-input class="remark" v-model="header" placeholder="请输入表头"/>
        </div>
        <table :border="1" class="table-wrap">

            <tr v-for="(item,index) of dataList" :key="index">
                <td v-for="(subItem,subIndex) of item.columns" :key="subIndex+'_'+index">
                    <el-input v-model="subItem.value" placeholder="请输入"></el-input>
                </td>
            </tr>
        </table>
        <div class="table-desc">
            <span>说明</span>
            <el-input class="remark" v-model="remark" placeholder="请输入说明"/>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "RichTextTable",
        data() {
            return {
                row: 1,
                column: 1,
                visible: false,
                dataList: [],
                action:'add',
                remark:'',
                // 表头
                header:''
            }
        },
        watch: {
            row: function () {
                this.dataList = this.tableDataList;
            },
            column: function () {
                this.dataList = this.tableDataList;
            }
        },
        created(){
            // this.tableDataList()
        },
        computed: {
            tableDataList: function () {
                let row = this.row
                let column = this.column
                let dataList = []

                // 获取原始数据
                let originalDataList=this.dataList
                if(row===0){
                    return []
                }
                for (let i = 0; i < row; i++) {
                    let columns = []
                    let originalData=originalDataList[i]|| Object.assign({},{columns:[]})

                    for (let j = 0; j < column; j++) {
                        // 判断原始数据中是否已经存在数据了，存在的话，就添加为原先数据，不存在就赋值为空
                        let originalDataColumn=originalData.columns[j]||Object.assign({value:''})
                        if (originalDataColumn) {
                            columns.push({value: originalDataColumn.value});

                        }else{
                            columns.push({value: ''});

                        }
                    }
                    dataList.push({columns})

                }

                return dataList;
            }
        },
        methods: {
            genData(){
                let row = this.row
                let column = this.column
                let dataList = []
                if (row === 0) {
                    return []
                }

                for (let i = 0; i < row; i++) {
                    let columns = []
                    for (let j = 0; j < column; j++) {
                        columns.push({value: ''})
                    }
                    dataList.push({columns})

                }
                return dataList;
            },

            // 数据确认回调
            confirm() {
                // 校验数据是否为空！
                let flag=this.checkData()
                if (!flag) {
                    this.$message({
                        type: 'warning',
                        message: '存在内容为空！'
                    });
                    return ;
                }
                // 第2个参数为数据参数
                // 第3个参数为remark参数
                // 第4个参数何种操作 ，添加或编辑
                // 第5个参数为表头
                this.$emit("ok", this.dataList,this.remark,this.action,this.header);
                this.visible=false
                this.clear()
            },
            clear(){
              this.dataList=[]
              this.column=0
              this.row=0
            },
            // 数据校验
            checkData() {
                let tableDataList = this.tableDataList
                for (let i = 0; i < tableDataList.length; i++) {
                    let columns = tableDataList[i].columns
                    let list = columns.filter(a => !a.value)
                    if (list.length > 0) {
                        return false;
                    }
                }
                return true;
            },
            show() {
                this.dataList = []
                this.visible = true
                this.action='add'
                this.header=''
                this.remark=''
                this.row=0
                this.column=0
            },
            edit(content,remark,header='') {
                this.dataList = []
                this.visible = true
                this.action='edit'

                this.remark=remark
                this.header=header

                this.dataList=JSON.parse(JSON.stringify(content))

                this.row=this.dataList.length

                if (this.dataList.length > 0) {
                    this.column=this.dataList[0].columns.length
                }


            }
        }
    }
</script>

<style scoped lang="scss">
    .param-wrap {
        display: flex;

        .item {
            &:nth-child(1) {
                margin-right: 10px;
            }
        }
    }

    .table-desc{
        display: flex;
        margin-top: 20px;
        align-items: center;
        span{
            margin-right: 10px;
        }
        .remark{
            width: 400px;

        }

    }
    .table-wrap {
        border-collapse: collapse;
        margin-top: 30px;

        tr {
            td {
                padding: 0 10px;
            }
        }

        /deep/ .el-input__inner {
            border: none;
            border-bottom: 1px solid #dcdfe6;
            border-radius: 0;
            padding-left: 0px;

        }

    }
</style>
