<template>


    <!--
       这里是海洋大学的 表格的通用组件

     -->
    <div>
        <table :border="1" class="table-wrap">
            <tr>
                <td colspan="4" align="center" >起止时间</td>
                <td colspan="4" align="center" >阶段内容</td>
            </tr>
            <tr v-for="(item,index) of dataList" :key="index">
                <td colspan="4" align="center" style="width: 200px">
                    <span class="input">{{item.time}}</span>
                </td>
                <td colspan="4" align="center" >
                    <div style="display:flex;align-items:center">
                        <el-input class="input" v-model="item.content" placeholder="请输入内容"></el-input>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        name: "CommonWorkTableList",
        props:{
            contentList:{
                type:Array,
                default:[
                ],
            }
        },
        data(){
            return {
                dataList:[]
            }
        },
        created(){
            this.dataList=this.contentList
        },
        methods:{
            setData(dataList){
                this.dataList=dataList
            },
            getData(){
                return this.dataList
            }
        }
    }
</script>

<style scoped lang="scss">
    .table-wrap {
        border-collapse: collapse;
        margin-top: 30px;
        width: 100%;

        tr {

            td {
                padding: 10px 10px;
            }
        }

        /deep/ .el-input__inner {
            border: none;
            border-bottom: 1px solid #dcdfe6;
            border-radius: 0;
            padding-left: 0px;

        }

    }
</style>
