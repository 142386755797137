<template>
    <div class="wrap">


        <div class="top-wrap">
            <div class="header">
                开放时间：{{openTime}}
                <el-tag v-if="!opening" size="small" type="danger">已关闭</el-tag>

            </div>
            <div class="submit-data" v-if="alreadySubmitData">
                <common-tip :result="result" @reSubmit="reSubmit"/>
            </div>
            <div class="wrap-btn" v-else>
                <common-action-button v-if="!alreadySubmitData&&opening" :load-stash="handleLoadStash"
                                      :save-stash="handleSaveStash" :commit="handleOk"/>
            </div>
        </div>

        <div >
            <div style="width: 100%;text-align: center">
                <div class="title">杭州师范大学教育学院</div>
                <div class="title">本科函授生毕业论文开题报告</div>
            </div>
            <div class="content">
                <div class="row">
                    <span class="label">论文题目：</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.title}}</span>
                    <el-input v-else v-model="title" placeholder="请输入题目"></el-input>
                </div>
                <div class="row">
                    <span class="label">学院:</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.college}}</span>
                    <el-input v-else v-model="college" placeholder="请输入学院"></el-input>
                </div>
                <div class="row">
                    <span class="label">专业:</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.major}}</span>
                    <el-input v-else v-model="major" placeholder="请输入专业"></el-input>
                </div>
                <div class="row">
                    <span class="label">学号:</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.stuNo}}</span>
                    <el-input v-else v-model="stuNo" placeholder="请输入学号"></el-input>
                </div>
                <div class="row">
                    <span class="label">姓名:</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.name}}</span>
                    <el-input v-else v-model="name" placeholder="请输入姓名"></el-input>
                </div>

                <div class="row">
                    <span class="label">职称:</span>
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.job}}</span>
                    <el-input v-else v-model="job" placeholder="请输入职称"></el-input>
                </div>



            </div>
        </div>
        <div>
            <div class="content-border">
                <div class="row">
                    <span class="label">一、选题背景与意义（说明所选课题的历史背景、国内外研究现状和发展趋势）</span>
                    <!--                    <span class="textarea" v-if="alreadySubmitData">{{alreadySubmitData.content1}}</span>-->
                    <div v-if="alreadySubmitData" class="content-list">
                        <div class="paragraph" v-for="(item,index) of getContentList(alreadySubmitData.content1)"
                             :key="index">
                            <span> {{item}}</span>

                        </div>
                    </div>
                    <div v-else class="content-list">
                        <div class="paragraph" v-for="(item,index) of content1" :key="index">
                            <span> {{item}}</span>
                            <div class="actions">
                                <i class="el-icon-edit edit-paragraph"
                                   @click="editParagraph('content1',index,item)">修改</i>
                                <i class="el-icon-close del-paragraph" @click="delParagraph('content1',index)">删除</i>
                            </div>
                        </div>
                        <el-button class="add-btn" type="primary" @click="addParagraph('content1')">添加段落</el-button>
                    </div>

                </div>
                <div class="row">
                    <span class="label">二、研究的基本内容和拟解决的主要问题：</span>
                    <div v-if="alreadySubmitData" class="content-list">
                        <div class="paragraph" v-for="(item,index) of  getContentList(alreadySubmitData.content2)"
                             :key="index">
                            <span> {{item}}</span>
                        </div>
                    </div>
                    <div v-else class="content-list">
                        <div class="paragraph" v-for="(item,index) of content2" :key="index">
                            <span> {{item}}</span>
                            <div class="actions">
                                <i class="el-icon-edit edit-paragraph"
                                   @click="editParagraph('content2',index,item)">修改</i>
                                <i class="el-icon-close del-paragraph" @click="delParagraph('content2',index)">删除</i>
                            </div>
                        </div>
                        <el-button class="add-btn" type="primary" @click="addParagraph('content2')">添加段落</el-button>
                    </div>
                </div>
                <div class="row">
                    <span class="label">三、研究方法及措施（拟采取的研究手段及技术路线、实验方案等）：</span>
                    <div v-if="alreadySubmitData" class="content-list">
                        <div class="paragraph" v-for="(item,index) of  getContentList(alreadySubmitData.content3)"
                             :key="index">
                            <span> {{item}}</span>
                        </div>
                    </div>
                    <div v-else class="content-list">
                        <div class="paragraph" v-for="(item,index) of content3" :key="index">
                            <span> {{item}}</span>
                            <div class="actions">
                                <i class="el-icon-edit edit-paragraph"
                                   @click="editParagraph('content3',index,item)">修改</i>
                                <i class="el-icon-close del-paragraph" @click="delParagraph('content3',index)">删除</i>
                            </div>
                        </div>
                        <el-button class="add-btn" type="primary" @click="addParagraph('content3')">添加段落</el-button>
                    </div>
                </div>
                <div class="row">
                    <span class="label">四、研究工作的步骤与进度（课题研究在时间和顺序上的安排）：</span>
                    <div v-if="alreadySubmitData" class="content-list">
                        <div class="paragraph" v-for="(item,index) of  getContentList(alreadySubmitData.content4)"
                             :key="index">
                            <span> {{item}}</span>
                        </div>
                    </div>
                    <div v-else class="content-list">
                        <div class="paragraph" v-for="(item,index) of content4" :key="index">
                            <span> {{item}}</span>
                            <div class="actions">
                                <i class="el-icon-edit edit-paragraph"
                                   @click="editParagraph('content4',index,item)">修改</i>
                                <i class="el-icon-close del-paragraph" @click="delParagraph('content4',index)">删除</i>
                            </div>
                        </div>
                        <el-button class="add-btn" type="primary" @click="addParagraph('content4')">添加段落</el-button>
                    </div>
                </div>
                <div class="row">
                    <span class="label" style="margin-bottom: 4px">五、主要参考文献：</span>
                    <div class="example-wrap">
                        <div class="tip-wrap">
                            <el-tag v-for="(item,index) of tipList" :key="index" size="mini" type="danger" class="tip">
                                {{item}}
                            </el-tag>
                        </div>
                        <div class="example-item-wrap">
                            <span> 例子：</span>
                            <div v-for="(item,index) in examples" :key="index">{{item}}</div>
                        </div>
                        <div style="padding:10px 20px;color:#dd6161;font-weight: bold;font-size: 20px;width:600px">
                            注意：请不要在开头添加序号，系统自动生成！一次添加一条！
                        </div>
                    </div>
                    <div v-if="alreadySubmitData" class="content-list">
                        <div class="paragraph" v-for="(item,index) of alreadySubmitData.content5" :key="index">
                            <span>[{{index+1}}] {{item.value}}</span>
                        </div>
                    </div>
                    <div v-show="alreadySubmitData==null" class="content-list">
                        <common-line-text ref="commonLineText" :content-list="content5"/>

                    </div>
                </div>
            </div>

        </div>

        <rich-text-input :show-dialog="true" ref="richTextInput" @ok="inputOk"/>
    </div>
</template>

<script>
    import {
        saveLunwenContent,
        getCurrentLunwenContent,
        getLunwenStashContent,
        stashLunwenContent
    } from "@/api/lunwen.js";
    import CommonActionButton from "../../common/CommonActionButton";
    import RichTextInput from "../../common/RichTextInput";
    import CommonLineText from "../../common/CommonLineText";
    import CommonTip from "../../common/CommonTip";

    export default {
        name: "haiyang",
        components: {CommonTip, CommonLineText, RichTextInput, CommonActionButton},
        props: {
            // 是否在开放时间内
            opening: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                // 论文题目
                title: "",
                // 学院
                college: "继续教育学院",
                // 专业
                major: "",

                // 姓名
                name: "",
                // 学号
                stuNo: "",
                // 职称
                job:"",
                // 指导老师
                // adviser: "",
                // 内容1
                content1: [],
                content2: [],
                content3: [],
                content4: [],
                content5: [],
                tipList: [
                    '期刊文献书写规范：作者，论文篇名，刊物名，出版年，卷（期），论文在刊物中的页码。',
                    '图书文献书写规范：作者，书名，出版地，出版社，出版日期，引用内容所在页。',
                ],
                examples: [
                    '王建华, 雷文, 王远亮. Schiff碱及其配合物的抑菌活性[J]. 化学通报, 2002, 65.',
                    '郑允飞, 陈文纳, 李德昌, 韦善怀, 等. Schiff碱及其配合物的应用研究进展[J]. 化工技术与开发, 2004, 33(4).'
                ],
                // content6: "",
                alreadySubmitData: null,
                openTime: null,
                result: null,
                tabIndex: 'tab1',
                // 内容名称
                contentName: '',
                contentIndex: -1
            };
        },
        created() {
            this.getCheckedData();
        },
        mounted() {
            this.openTime = sessionStorage.getItem("openTime");
        },
        methods: {

            // 文本输入完成
            inputOk(content, remark, action) {

                let contentIndex = this.contentIndex
                switch (this.contentName) {
                    case 'content1':
                        if (action === 'add') {
                            this.content1.push(content)
                        } else if (action === 'edit') {
                            this.content1.splice(contentIndex, 1, content)
                        }
                        break;
                    case 'content2':
                        if (action === 'add') {
                            this.content2.push(content)
                        } else if (action === 'edit') {
                            this.content2.splice(contentIndex, 1, content)
                        }
                        break;
                    case 'content3':
                        if (action === 'add') {
                            this.content3.push(content)
                        } else if (action === 'edit') {
                            this.content3.splice(contentIndex, 1, content)
                        }
                        break;
                    case 'content4':
                        if (action === 'add') {
                            this.content4.push(content)
                        } else if (action === 'edit') {
                            this.content4.splice(contentIndex, 1, content)
                        }
                        break;
                    case 'content5':
                        if (action === 'add') {
                            this.content5.push(content)
                        } else if (action === 'edit') {
                            this.content5.splice(contentIndex, 1, content)
                        }
                        break;
                }
                this.contentName = ''
            },
            editParagraph(contentName, contentIndex, content) {
                this.contentName = contentName
                this.contentIndex = contentIndex
                this.$refs.richTextInput.edit(content)

            },
            // 删除段落
            delParagraph(contentName, index) {
                let _this = this
                this.$confirm('是否删除?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    switch (contentName) {
                        case 'content1':
                            _this.content1.splice(index, 1)
                            break;
                        case 'content2':
                            _this.content2.splice(index, 1)
                            break;
                        case 'content3':
                            _this.content3.splice(index, 1)
                            break;
                        case 'content4':
                            _this.content4.splice(index, 1)
                            break;
                        case 'content5':
                            _this.content5.splice(index, 1)
                            break;
                    }
                    _this.contentName = ''

                })

            },
            // 添加段落
            addParagraph(contentName) {
                this.contentName = contentName
                this.$refs.richTextInput.show()
            },
            //重新提交
            reSubmit() {
                let alreadySubmitData = this.alreadySubmitData
                this.title = this.alreadySubmitData.title
                this.college = this.alreadySubmitData.college
                this.major = this.alreadySubmitData.major
                this.name = this.alreadySubmitData.name
                this.stuNo = this.alreadySubmitData.stuNo
                this.job = this.alreadySubmitData.job
                // this.adviser = this.alreadySubmitData.adviser
                this.content1 = this.getContentList(this.alreadySubmitData.content1)
                this.content2 = this.getContentList(this.alreadySubmitData.content2)
                this.content3 = this.getContentList(this.alreadySubmitData.content3)
                this.content4 = this.getContentList(this.alreadySubmitData.content4)

                this.$nextTick(() => {
                    this.content5 = alreadySubmitData.content5
                    this.$refs.commonLineText.setData(this.content5)
                })

                this.alreadySubmitData = null
            },
            getCheckedData() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    let result = res.data.result;
                    if (result) {
                        let content = result.content;
                        if (content) {
                            let alreadySubmitData = JSON.parse(content);
                            this.$nextTick(() => {
                                let content5 = this.getContentList(alreadySubmitData.content5)

                                alreadySubmitData.content5 = content5
                                this.$refs.commonLineText.setData(content5)
                            })

                            this.alreadySubmitData = alreadySubmitData
                            this.result = result;
                        }
                    }
                });
            },
            // 载入论文草稿
            handleLoadStash() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getLunwenStashContent({studentId, stepSubId}).then(res => {
                    if (res.data.success) {
                        let result = res.data.result;
                        let content = result.content;
                        if (content) {
                            content = JSON.parse(content);
                            this.title = content.title
                            this.college = content.college
                            this.major = content.major
                            this.name = content.name
                            this.stuNo = content.stuNo
                            this.job = content.job

                            this.content1 = this.getContentList(content.content1)
                            this.content2 = this.getContentList(content.content2)
                            this.content3 = this.getContentList(content.content3)
                            this.content4 = this.getContentList(content.content4)


                            this.$nextTick(() => {
                                this.$refs.commonLineText.setData(content.content5)
                            })

                            this.$message({
                                type: "success",
                                message: '载入成功！'
                            });
                        }
                    } else {
                        this.$message({
                            type: "success",
                            message: '载入失败:' + res.data.message
                        });
                    }


                });
            },
            // 这里是为了匹配老的数据是string类型，现在因为改为了数组格式
            getContentList(content) {
                if (typeof content == 'string') {
                    return [content]
                }
                return content
            },
            // 暂存论文数据
            handleSaveStash() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                let content5 = this.$refs.commonLineText.getData()
                let content = {
                    title: this.title,
                    college: this.college,
                    major: this.major,
                    name: this.name,
                    stuNo: this.stuNo,
                    job: this.job,
                    content1: this.content1,
                    content2: this.content2,
                    content3: this.content3,
                    content4: this.content4,
                    content5: content5,
                };

                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content)
                };
                let _this = this;

                stashLunwenContent(form).then(res => {
                    if (res.data.success) {
                        _this.$message({
                            type: "success",
                            message: res.data.message
                        });
                    } else {
                        _this.$message({
                            type: "info",
                            message: res.data.message
                        });
                    }

                });
            },
            handleOk() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                let content5 = this.$refs.commonLineText.getData()
                let content = {
                    title: this.title,
                    college: this.college,
                    major: this.major,
                    name: this.name,
                    stuNo: this.stuNo,
                    job: this.job,
                    content1: this.content1,
                    content2: this.content2,
                    content3: this.content3,
                    content4: this.content4,
                    content5: content5,
                };


                let flag = this.checkData(content);
                if (!flag) {

                    return;
                }

                let node = sessionStorage.getItem("menuNode");
                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),
                    node,
                    docFilePath
                };
                let _this = this;

                this.$confirm("是否提交?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    type: "warning"
                })
                    .then(() => {
                        saveLunwenContent(form).then(res => {
                            if (res.data.success) {
                                _this.$message({
                                    type: "success",
                                    message: res.data.message
                                });
                            } else {
                                _this.$message({
                                    type: "info",
                                    message: res.data.message
                                });
                            }

                            this.getCheckedData();
                        });
                    })
                    .catch(() => {
                    });
            },
            checkData(content) {
                let obj={
                    title:'论文题目',
                    college:'学院',
                    major:'专业',
                    name:'姓名',
                    stuNo:'学号',
                    job:'职称',
                }

                    for (let key in content) {

                    if (!content[key]){
                        this.$message({
                            type: "warning",
                            message: obj[key]+":不能为空！"
                        });

                        return false;
                    }
                }

                let content1=content.content1
                let content2=content.content2
                let content3=content.content3
                let content4=content.content4
                let content5=content.content5


                if (content1.length === 0) {
                    this.$message({
                        type: "warning",
                        message: "选题背景与意义（说明所选课题的历史背景、国内外研究现状和发展趋势）:不能为空！"
                    });
                    return false;
                }


                if (content2.length === 0) {
                    this.$message({
                        type: "warning",
                        message: "研究的基本内容和拟解决的主要问题：不能为空！"
                    });
                    return false;
                }
                if (content3.length === 0) {
                    this.$message({
                        type: "warning",
                        message: "研究方法及措施（拟采取的研究手段及技术路线、实验方案等）：不能为空！"
                    });
                    return false;
                }
                if (content4.length === 0) {
                    this.$message({
                        type: "warning",
                        message: "研究工作的步骤与进度（课题研究在时间和顺序上的安排）：不能为空！"
                    });
                    return false;
                }
                if (content5.length === 0) {
                    this.$message({
                        type: "warning",
                        message: "主要参考文献:不能为空！"
                    });
                    return false;
                }


                return true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    /deep/ .el-input__inner {
        border: none;
        border-bottom: 1px solid #dcdfe6;
        border-radius: 0;
        padding-left: 0px;
    }

    .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .top-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .header {
                /*width: 100%;*/
            }

            .submit-data {
                display: flex;
                /*flex-direction: column;*/
                align-items: center;
                /*margin-bottom: 20px;*/
                /*margin-top: 20px;*/
                /*width: 100%;*/
                text-align: right;

                .time-wrap {
                    color: #aaa;
                    /*margin-top: 10px;*/
                    font-size: 14px;
                    margin-left: 10px;
                    /*width: 200px;*/
                }

                .wrap-btn {
                    width: 100%;
                    text-align: right;
                }

                .reSubmit-tag {
                    display: flex;
                    /*flex-direction: column;*/
                    align-items: center;

                    .tag {
                        /*margin-bottom: 10px;*/
                        margin-right: 10px;
                    }

                    .feedback-wrap {
                        /*margin-bottom: 10px;*/
                        color: #dd6161;
                        text-decoration: underline;
                    }
                }
            }

        }

        .tab-wrap {
            width: 100%;
            margin-top: 10px;
        }

        .header {
            /*width: 100%;*/
            /*text-align: left;*/
        }

        h1 {
            color: #004ea3;
            letter-spacing: 3px;
            align-self: center;
        }

        .title {
            font-weight: bold;
            font-size: 22px;
        }

        .content {
            margin-top: 20px;
            width: 600px;

            .row {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .label {
                    width: 100px;
                }

                .label-gray {
                    color: #aaa;
                }
            }
        }

        .content-border {
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 50px;
            width: 1000px;
            border: 1px solid #f1f1f1;
            padding: 20px;

            .row {
                margin-bottom: 20px;
                padding: 20px;
                border-radius: 4px;

                &:nth-child(odd) {
                    background: #ecf5ff;

                }

                &:nth-child(even) {
                    background: #f1f1f1;

                }

                .label {
                    display: inline-block;
                    margin-bottom: 30px;
                }

                .example-wrap {
                    margin-bottom: 10px;

                    .tip-wrap {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .tip {
                            margin-bottom: 6px;
                        }
                    }

                    .example-item-wrap {
                        background: #f1f1f1;
                        margin-top: 8px;
                        color: #aaaaaa;
                        font-size: 12px;
                        padding: 4px;
                        border-radius: 10px;
                    }
                }

                .content-list {
                    font-size: 14px;

                    .paragraph {
                        color: #82848a;

                        .c {
                            text-indent: 24px;
                        }

                        .actions {

                            .edit-paragraph {
                                color: #3a8ee6;
                                font-weight: bold;

                                &:hover {
                                    background: #3a8ee6;
                                    color: #ffffff;
                                    cursor: pointer;
                                }
                            }

                            .del-paragraph {
                                color: #dd6161;
                                font-weight: bold;
                                margin-left: 10px;

                                &:hover {
                                    background: #dd6161;
                                    color: #ffffff;
                                    cursor: pointer;
                                }
                            }
                        }

                    }

                    .add-btn {
                        margin-top: 10px;
                    }
                }

                .textarea {
                    color: #aaa;
                    display: block;
                }
            }
        }
    }
</style>
