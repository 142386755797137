<template>
    <div class="wrap">
        <div class="top-wrap">
            <div class="header">
                开放时间：{{openTime}}
                <el-tag v-if="!opening" size="small" type="danger">未开始</el-tag>
            </div>
            <div class="submit-data" v-if="alreadySubmitData">
                <slot name="rechecking"></slot>
                <common-tip :result="result" @reSubmit="reSubmit"/>
            </div>
            <div class="wrap-btn" v-else>
				<el-button v-if="opening&&showCancel" @click="getCheckedData">取消</el-button>
                <common-lunwen-template-action-button v-if="!alreadySubmitData&&opening"
                    :commit="handleOk"/>
            </div>
        </div> 

        <div class="body-wrap">

            <div class="btn-upload_wrap" v-if="alreadySubmitData">
                <img src="~@/static/image/word.jpg" style="width: 150px"/>
                <div class="body-header">
                    <span class="label">我的论文：</span>
                    <div class="value" v-if="result">
						<template v-if="result.docFilePath.lastIndexOf('.docx')>0">
							<a style="color:#4e8fe8" :href="paseOfficeUrl(domain+'/api/sys/common/download/'+result.docFilePath)" target="_blank">预览</a>
							<el-divider direction="vertical"/>
						</template>
                        <a style="color:#4e8fe8" :href="'/api/sys/common/download/'+result.docFilePath" target="_blank">下载</a>
                    </div>
                    <div class="value" v-else>无</div>
                </div>
            </div>
            <div class="btn-upload_wrap" v-else>
				<template v-if="opening">
					<img src="~@/static/image/upload.png" style="width: 150px"/>
					<div @click="uploadLunwen" class="upload-btn">上 传</div>
					<div class="tip">注意：只能上传doc/docx文件，且不超过50M</div>
					<!-- <el-tag disable-transitions style="margin-top: 5px" type="danger" text="确认无误后，记得提交哦~" >上传完成后记得提交哦~</el-tag> -->
				</template>
            </div>

            <div class="upload-finish" v-if="docFilePath">
                <a :href="'/api/sys/common/download/'+docFilePath" target="_blank">点击查看论文</a>
                <!-- <div class="notice">若确认无误，请点击 <el-tag effect="dark" type="primary">【右上角提交按钮】</el-tag>，完成论文提交。</div> -->

            </div>

        </div>


        <lunwen-upload-modal @ok="uploadOk" ref="LunwenUploadModal"/>
    </div>
</template>

<script>
    import {saveLunwenContent, getCurrentLunwenContent} from "@/api/lunwen.js";
    import CommonTip from "../../common/CommonTip";
    import LunwenUploadModal from "../../upload/LunwenUploadModal";
    import CommonLunwenTemplateActionButton from "../../common/CommonLunwenTemplateActionButton";
    // 通用论文模板
    export default {
        name: "common_lunwen",
        props:{
            // 是否在开放时间内
            opening:{
                type:Boolean,
                default:false
            },
            node:{
                type:String,

            }
        },
        components: {
            CommonLunwenTemplateActionButton,
            LunwenUploadModal, CommonTip},
        data() {
            return {
                alreadySubmitData: null,
                openTime: null,
                result: null,
                data: '',
                menuNode:'',
                docFilePath:'', // 当前上传的论文路径
				domain:'',
				showCancel:false
            };
        },
        watch:{
          node:function(e){
              this.reset()
              this.getCheckedData();
          }
        },
        created() {
            this.getCheckedData();
			this.domain=window.location.origin
            this.menuNode=this.node
        },
        methods: {
			paseOfficeUrl(url) {
			  url = encodeURIComponent(url+"?_t="+new Date().getTime())
			  return `https://view.officeapps.live.com/op/embed.aspx?src=${url}&amp;wdPrint=0&amp;wdEmbedCode=0&amp;wdStartOn=1`
			},
            // 论文上传完成
            uploadOk(filePath){
                this.docFilePath=filePath
				this.handleOk()
            },
            // 上传论文
            uploadLunwen(){
                this.$refs.LunwenUploadModal.show()
            },
            //重新提交
            reSubmit() {
                this.alreadySubmitData = null;
				this.showCancel=true
            },
            getCheckedData() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                this.openTime = sessionStorage.getItem("openTime");
                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    this.result=res.data.result;
                    if (this.result) {
                        this.alreadySubmitData=res.data.result;
                    }
                });
            },
            reset(){
                this.result=null
                this.alreadySubmitData=null
                this.openTime=null
                this.result=null
                this.data=''
                this.docFilePath=''

            },
            // 数据提交
            handleOk() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let templateName = sessionStorage.getItem("docFtl"); // 这个其实就是模版值
                // let docFilePath = sessionStorage.getItem("docFtl");
                let docFilePath = this.docFilePath; // 这个是上传的文件路径
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                if (!docFilePath) {
                    this.$message.error("请上传论文！");
                    return;
                }

                let node = sessionStorage.getItem("menuNode");
                let form = {
                    studentId,
                    stepSubId,
                    node,
                    docFilePath,
                    templateName
                };
                let _this = this;
				
				
				_this.$notify.info({
				    title: '消息',
				    message: '数据提交中，请稍后...'
				});
				saveLunwenContent(form).then(res => {
				    if (res.data.success) {
				        _this.$message({
				            type: "success",
				            message: res.data.message
				        });
				        _this.docFilePath=null
				    } else {
				        _this.$message({
				            type: "info",
				            message: res.data.message
				        });
				    }
				
				    this.getCheckedData();
				});

                // this.$confirm("是否提交?", "提示", {
                //     confirmButtonText: "是",
                //     cancelButtonText: "否",
                //     type: "warning"
                // })
                //     .then(() => {
                //         _this.$notify.info({
                //             title: '消息',
                //             message: '数据提交中，请稍后...'
                //         });
                //         saveLunwenContent(form).then(res => {
                //             if (res.data.success) {
                //                 _this.$message({
                //                     type: "success",
                //                     message: res.data.message
                //                 });
                //                 _this.docFilePath=null
                //             } else {
                //                 _this.$message({
                //                     type: "info",
                //                     message: res.data.message
                //                 });
                //             }

                //             this.getCheckedData();
                //         });
                //     })
                //     .catch(() => {
                //     });
            },
        }
    };
</script>

<style lang="scss" scoped>
    /*/deep/ .el-input__inner {*/
    /*    border: none;*/
    /*    border-bottom: 1px solid #dcdfe6;*/
    /*    border-radius: 0;*/
    /*    padding-left: 0px;*/
    /*}*/

    .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
        .body-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .body-header{
                align-self: flex-start;
                display: flex;
                .content{

                }
            }
            .btn-upload_wrap{
                display: flex;
                flex-direction: column;
                margin-top: 50px;
                align-items: center;
                text-align: center;
                .upload-btn{
                    background: linear-gradient(#e9ecfd,#c5ccfa,#a6b2f7,#9aa7f6);
                    color:#fff;
                    padding:10px 80px;
                    border-radius: 5px;
                    cursor: pointer;

                }
                .tip{
                    margin-top: 10px;
                    font-size: 14px;
                    color:#999;
                }
            }
            .upload-finish{
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .notice{
                    margin-top: 20px;
                    color:#666;
                }
            }

        }
        .top-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .header {
                /*width: 100%;*/
            }
            .submit-data {
                display: flex;
                /*flex-direction: column;*/
                align-items: center;
                /*margin-bottom: 20px;*/
                /*margin-top: 20px;*/

                .time-wrap {
                    color: #aaa;
                    /*margin-top: 10px;*/
                    font-size: 14px;
                    margin-left:10px;
                }

                .reSubmit-tag {
                    display: flex;
                    /*flex-direction: column;*/
                    align-items: center;

                    .tag {
                        /*margin-bottom: 10px;*/
                        margin-right: 10px;
                    }

                    .feedback-wrap {
                        /*margin-bottom: 10px;*/
                        color: #dd6161;
                        text-decoration: underline;
                    }
                }
            }

        }
        .tab-wrap {
            width: 100%;
            margin-top: 10px;
        }




        h1 {
            color: #004ea3;
            letter-spacing: 3px;
            text-align: center;
        }

        .title {
            // font-weight: bold;
            font-size: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                &:nth-child(1) {
                    font-weight: bold;
                }
            }
        }

        .content {
            margin-top: 20px;
            width: 600px;

            .row {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .label {
                    width: 100px;
                }

                .label-gray {
                    color: #aaa;
                }
            }
        }

        .content-border {
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 50px;
            width: 1200px;
            border: 1px solid #f1f1f1;
            display: flex;



            .keyword-wrap{
                text-align: center;
                padding-bottom: 20px;
                .content{
                    width: 80%;
                    color:#606266;
                }
            }

            .wenxian-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .list {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /*flex-direction: column;*/

                    span {
                        color: #606266;
                        /*margin-bottom: 4px;*/
                    }
                    .delete-icon{
                        font-size: 20px;
                        color:#dd6161;
                        &:hover{
                            cursor: pointer;
                            background: #dd6161;
                            color:#ffffff;
                        }
                    }
                }

                .content-input {
                    display: flex;

                    .input {
                        flex: 1;
                    }
                }
            }


            .content-left {
                flex: 1;
                padding: 20px;

                .main-content-wrap {
                    .level-content-wrap {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                    }
                }

                .tip {
                    margin-bottom: 10px;
                }
            }

            .middle {
                width: 1px;
                background: #f1f1f1;
            }

            .content-right {
                flex: 1;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .content-right-tip {
                    display: flex;
                    color: #82848a;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .fullscreen {
                        cursor: pointer;
                        font-size: 14px;

                        &:hover {
                            color: #409EFF;
                        }
                    }
                }


                .level-content {
                    margin-top: 20px;
                    width: 100%;
                }

                .clear-content {
                    margin-top: 10px;
                    align-self: flex-end;
                }
            }
        }
    }


    .content-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        &:nth-child(even){
            background: #ecf5ff;
            padding-top: 10px;
        }
        .example-wrap{
            .tip-wrap {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .tip {
                    margin-bottom: 6px;
                }
            }

            .example-item-wrap {
                background: #f1f1f1;
                margin-top: 8px;
                color: #aaaaaa;
                font-size: 12px;
                padding: 4px;
                border-radius: 10px;
                margin-bottom: 10px;
            }
        }


        .lable {
            align-self: start;
            // color:#82848a;
        }

        .headline {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 400px;

            .name {
                font-size: 30px;
                font-weight: bold;
            }

            .notice {
                align-self: start;
                font-size: 10px;
                color: #dd6161;
                margin-top: 10px;

                &:before {
                    content: '说明：';
                }
            }
        }
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        color: #303133;
        font-weight: bold;

    }

    .add-level-wrap {
        margin-bottom: 20px;

        .level-input {
            display: flex;
            margin-bottom: 20px;
        }
    }
    .wrap-btn{
        /*width: 100%;*/
        text-align: right;
    }
</style>
