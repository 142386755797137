import axios from 'axios'  //引入axios
import router from '../router'
//下面这两个不一定需要引入，看你项目需要拦截的时候做什么操作，但是一般都需要引入store
// import store from '@/store/index'  //引入store
// import router from '@/router/index'  //引入router


let instance = axios.create({
    // headers: {
    //   'content-type': 'application/x-www-form-urlencoded'
    // },
    baseURL: '/api',
    // baseURL: 'http://localhost:8080/jeecg-boot',
    timeout: 20000,
  })

  // http request 拦截器
instance.interceptors.request.use(
    config => {
      const token = sessionStorage.getItem('token')
      if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
          config.headers.authorization = token  //请求头加上token
      }
      return config
    },
    err => {
      return Promise.reject(err)
    })


    // http response 拦截器
instance.interceptors.response.use(
    response => {
      //拦截响应，做统一处理
      if (response.data.code) {
        switch (response.data.code) {
          case 403:
            // router.replace({
            //   path: 'login',
            //   query: {
            //     redirect: router.currentRoute.fullPath
            //   }
            // })
            router.replace({name:'login'})
            break;
          case 500:{
            alert(response.data.message)
          }
        }
      }
      return response
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
      return Promise.reject(error) // 返回接口返回的错误信息
    })

    export default instance
