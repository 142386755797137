<template>
    <div class="wrap">
        <!--        <div class="header">-->
        <!--            <span class="time">开放时间：{{openTime}}  </span>-->
        <!--            <el-tag v-if="!opening" size="small" type="danger">已关闭</el-tag>-->
        <!--        </div>-->

        <div class="top-wrap">
            <div class="header">
                开放时间：{{openTime}}
                <el-tag v-if="!opening" size="small" type="danger">已关闭</el-tag>
            </div>
            <div class="submit-data" v-if="alreadySubmitData">
                <common-tip :result="result" @reSubmit="reSubmit"/>
            </div>
            <div class="wrap-btn" v-else>
                <common-action-button v-if="!alreadySubmitData&&opening" :load-stash="handleLoadStash" :save-stash="handleSaveStash" :commit="handleOk"/>
            </div>
        </div>


        <h1>浙江工商大学继续教育学院
            毕业论文开题报告
        </h1>



        <table border="1" class="table">
            <tr>
                <td>姓名</td>
                <td >
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.name}}</span>
                    <el-input v-else v-model="name" placeholder="请输入姓名"></el-input>
                </td>
                <td>教学形式</td>
                <td >
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.learnType}}</span>
                    <el-input v-else v-model="learnType" placeholder="教学形式请输入业余或函数"></el-input>
                </td>
                <td>学号</td>
                <td >
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.no}}</span>
                    <el-input v-else v-model="no" placeholder="请输入学号"></el-input>
                </td>
                <td>专业</td>
                <td >
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.major}}</span>
                    <el-input v-else v-model="major" placeholder="请输入专业"></el-input>
                </td>
            </tr>
            <tr>
                <td>论文题目:</td>
                <td colspan="5">
                    <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.lunwenTitle}}</span>
                    <el-input v-else v-model="lunwenTitle" placeholder="请输入论文题目"></el-input>
                </td>
            </tr>
            <tr>
                <td colspan="8">
                    <div>
                        <h3>一、选题背景与意义</h3>
                        <span>
              <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.content1}}</span>
              <el-input v-else type="textarea" rows="5" v-model="content1" placeholder="请输入内容"></el-input>
            </span>
                    </div>
                    <div>
                        <h3>二、文献综述</h3>
                        <span>
              <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.content2}}</span>
              <el-input v-else type="textarea" rows="5" v-model="content2" placeholder="请输入内容"></el-input>
            </span>
                    </div>
                    <div>
                        <h3>三、研究的主要内容与方法</h3>
                        <span>
              <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.content3}}</span>
              <el-input v-else type="textarea" rows="5" v-model="content3" placeholder="请输入内容" ></el-input>
            </span>
                    </div>
                    <div>
                        <h3>四、论文的结构</h3>
                        <span>
              <span class="label-gray" v-if="alreadySubmitData">{{alreadySubmitData.content4}}</span>
              <el-input v-else type="textarea" rows="5" v-model="content4" placeholder="请输入内容" ></el-input>
            </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="8">
                    <div>
                        <span>五、主要参考文献、资料(写清楚参考文献名称、作者、出版单位)：</span>
                        <div style="margin:10px 0;display:flex;flex-direction:column;align-items:flex-start;">
                            <el-tag
                                    size="mini"
                                    style="margin-bottom:10px"
                                    type="danger"
                            >期刊文献书写规范：作者，论文篇名，刊物名，出版年，卷（期），论文在刊物中的页码。
                            </el-tag>
                            <el-tag size="mini" type="danger">图书文献书写规范：作者，书名，出版地，出版社，出版日期，引用内容所在页。</el-tag>
                        </div>
                        <div style="padding:20px">
                            <span>例子：</span>
                            <div>王建华, 雷文, 王远亮. Schiff碱及其配合物的抑菌活性[J]. 化学通报, 2002, 65.</div>
                            <div>郑允飞, 陈文纳, 李德昌, 韦善怀, 等. Schiff碱及其配合物的应用研究进展[J]. 化工技术与开发, 2004, 33(4).</div>

                        </div>
                        <div style="padding:10px 20px;color:#dd6161;font-weight: bold;font-size: 20px;width:600px">
                            注意：请不要在开头添加序号，系统自动生成！一次添加一条！
                        </div>
                        <!-- 这里要多行 -->
                    </div>
                    <div class="content-list" v-if="alreadySubmitData">
                        <div class="input-content" v-for="(item,index) of alreadySubmitData.wenxianList" :key="index">
                            <span class="label-gray">[{{index+1}}]</span>
                            <span class="label-gray">{{item.value}}</span>
                        </div>
                    </div>
                    <div class="content-list" v-else>
                        <div class="input-content" v-for="(item,index) of wenxianList" :key="index">
                            <span>[{{index+1}}]</span>
                            <el-input class="input" v-model="item.value" placeholder="请输入内容"></el-input>
                            <i class="el-icon-close delete" @click="deleteWenxian(index)"></i>
                        </div>
                        <el-button class="add-line" type="primary" @click="addWenxian">添加一行</el-button>
                    </div>
                </td>
            </tr>

        </table>


    </div>
</template>

<script>
    import {
        saveLunwenContent,
        getCurrentLunwenContent,
        getLunwenStashContent,
        stashLunwenContent
    } from "@/api/lunwen.js";
    import CommonActionButton from "../../common/CommonActionButton";
    import CommonTip from "../../common/CommonTip";

    export default {
        name: "gongshang",
        components: {CommonTip, CommonActionButton},
        props: {
            // 是否在开放时间内
            opening: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                // 姓名
                name: "",
                // 学习形式
                learnType: "",
                // 学号
                no: "",
                // 专业
                major: "",
                // 设计（或论文）题目
                lunwenTitle: "",
                // 指导教师姓名
                // tearchName: "",
                // 职称
                // jobTitle: "",
                // 一、选题背景与意义
                content1: "",
                // 二、文献综述
                content2: "",
                // 三、研究的主要内容与方法
                content3: "",
                // 四、论文的结构
                content4: "",

                // 参考文献
                wenxianList: [{value: ""}],
                // 毕业论文（设计）进度安排

                alreadySubmitData: null,
                openTime: null,
                result: null
            };
        },
        created() {
            this.getCheckedData();
        },
        mounted() {
            this.openTime = sessionStorage.getItem("openTime");
        },
        methods: {

            //  添加进度
            addProcess() {
                this.progressList.push({
                    time: '',
                    content: ''
                });
            },
            // 删除毕业设计进度
            deleteProgress(index) {
                this.progressList.splice(index, 1);
            },
            // 删除文献
            deleteWenxian(index) {
                this.wenxianList.splice(index, 1);
            },
            // 添加文献
            addWenxian() {
                let emptyList=this.wenxianList.filter(a=>!a.value)
                if (emptyList.length > 0) {
                    this.$message({
                        type: "warning",
                        message: "参考文献存在数据为空！"
                    });
                    return;
                }

                this.wenxianList.push({
                    value: ""
                });
            },
            //重新提交
            reSubmit() {
                this.name = this.alreadySubmitData.name;
                this.no = this.alreadySubmitData.no;
                this.learnType = this.alreadySubmitData.learnType;
                this.major = this.alreadySubmitData.major;
                this.lunwenTitle = this.alreadySubmitData.lunwenTitle;
                // this.tearchName = this.alreadySubmitData.tearchName;
                // this.jobTitle = this.alreadySubmitData.jobTitle;
                this.content1 = this.alreadySubmitData.content1;
                this.content2 = this.alreadySubmitData.content2;
                this.content3 = this.alreadySubmitData.content3;
                this.content4 = this.alreadySubmitData.content4;
                this.wenxianList = this.alreadySubmitData.wenxianList;
                this.alreadySubmitData = null;
            },
            getCheckedData() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    let result = res.data.result;
                    if (result) {
                        let content = result.content;
                        if (content) {
                            this.alreadySubmitData = JSON.parse(content);
                            this.result = result;
                        }
                    }
                });
            },
            handleOk() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                let content = {
                    name: this.name,
                    learnType: this.learnType,
                    no: this.no,
                    major: this.major,
                    lunwenTitle: this.lunwenTitle,
                    // tearchName: this.tearchName,
                    // jobTitle: this.jobTitle,
                    content1: this.content1,
                    content2: this.content2,
                    content3: this.content3,
                    content4: this.content4,
                    wenxianList: this.wenxianList,
                };
                let flag = this.checkData(content);
                if (!flag) {
                    return;
                }

                let node = sessionStorage.getItem("menuNode");
                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),
                    node,
                    docFilePath
                };
                let _this = this;

                this.$confirm("是否提交?", "提示", {
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    type: "warning"
                })
                    .then(() => {
                        saveLunwenContent(form).then(res => {
                            if (res.data.success) {
                                _this.$message({
                                    type: "success",
                                    message: res.data.message
                                });
                            } else {
                                _this.$message({
                                    type: "info",
                                    message: res.data.message
                                });
                            }

                            this.getCheckedData();
                        });
                    })
                    .catch(() => {
                    });
            },
            // 暂存论文数据
            handleSaveStash() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                // let docFilePath = sessionStorage.getItem("docFtl");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                // 开题报告
                let content = {
                    name: this.name,
                    learnType: this.learnType,
                    no: this.no,
                    major: this.major,
                    lunwenTitle: this.lunwenTitle,
                    content1: this.content1,
                    content2: this.content2,
                    content3: this.content3,
                    content4: this.content4,
                    wenxianList: this.wenxianList,
                };


                let form = {
                    studentId,
                    stepSubId,
                    content: JSON.stringify(content),

                };

                stashLunwenContent(form).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: "success",
                            message: '暂存成功'
                        });
                    } else {
                        this.$message({
                            type: "info",
                            message: res.data.message
                        });
                    }

                });
            },
            // 载入论文草稿
            handleLoadStash() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getLunwenStashContent({studentId, stepSubId}).then(res => {
                    if (res.data.success) {
                        let result = res.data.result;
                        let content = result.content;
                        if (content) {
                            let data = JSON.parse(content);

                            this.name = data.name;
                            this.learnType = data.learnType;
                            this.no = data.no;
                            this.major = data.major;
                            this.lunwenTitle = data.lunwenTitle;
                            this.content1 = data.content1;
                            this.content2 = data.content2;
                            this.content3 = data.content3;
                            this.content4 = data.content4;
                            this.wenxianList = data.wenxianList;

                            this.$message({
                                type: "success",
                                message: '载入成功！'
                            });
                        }
                    } else {
                        this.$message({
                            type: "success",
                            message: '载入失败:' + res.data.message
                        });
                    }


                });
            },
            checkData(content) {
                let totalLength = 0
                let nameList = {
                    name: '姓名',
                    learnType: '教学形式',
                    no: '学号',
                    major: '专业',
                    lunwenTitle: '设计（或论文）题目',
                    // tearchName: '指导教师姓名',
                    // jobTitle: '职称',
                    content1: '选题背景与意义',
                    content2: '文献综述',
                    content3: '研究的主要内容与方法',
                    content4: '论文的结构',
                    wenxianList: '参考文献、资料',
                }
                for (let key in content) {
                    if (!content[key]) {
                        console.log(key)
                        this.$message({
                            type: "warning",
                            message: nameList[key] + ":不能为空！"
                        });
                        return false;

                    }


                    //毕业论文（设计）内容要求
                    if (key == 'content1' || key == 'content2' || key == 'content3' || key == 'content4') {
                        let myContent = content[key]
                        let length = myContent.length
                        totalLength += length;
                    }


                    // 文献资料
                    if (key == 'wenxianList') {

                        let wenxianList = content[key]
                        if (wenxianList.length == 0) {
                            this.$message({
                                type: "warning",
                                message: "参考文献、资料至少添加一行！"
                            });
                            return false;
                        } else {
                            let result = wenxianList.filter(a => !!a.value)
                            if (result == 0) {
                                this.$message({
                                    type: "warning",
                                    message: "参考文献、资料内容不能为空！"
                                });
                                return false;
                            }
                        }
                    }

                }



                return true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    /deep/ .el-input__inner {
        border: none;
        border-bottom: 1px solid #dcdfe6;
        border-radius: 0;
        padding-left: 0px;
    }

    .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        /*.header {*/
        /*    width: 100%;*/
        /*    text-align: left;*/
        /*    display: flex;*/
        /*    align-items: center;*/
        /*}*/
        .top-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .header {
                /*width: 100%;*/
            }

            .submit-data {
                display: flex;
                /*flex-direction: column;*/
                align-items: center;
                /*margin-bottom: 20px;*/
                /*margin-top: 20px;*/

                .time-wrap {
                    color: #aaa;
                    /*margin-top: 10px;*/
                    font-size: 14px;
                    margin-left: 10px;
                }

                .reSubmit-tag {
                    display: flex;
                    /*flex-direction: column;*/
                    align-items: center;

                    .tag {
                        /*margin-bottom: 10px;*/
                        margin-right: 10px;
                    }

                    .feedback-wrap {
                        /*margin-bottom: 10px;*/
                        color: #dd6161;
                        text-decoration: underline;
                    }
                }
            }

        }


        .label-gray {
            color: #aaa;
        }

        .content-list {
            display: flex;
            align-items: center;
            flex-direction: column;

            .add-line {
                margin-top: 10px;
            }

            .input-content {
                display: flex;
                align-items: center;
                width: 100%;

                .input {
                    margin-left: 10px;
                    margin-right: 10px;
                }

                .delete {
                    color: #dd6161;
                    font-weight: bold;

                    &:hover {
                        background: #dd6161;
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }
        }

        .table {
            width: 1000px;
            border-collapse: collapse;
            margin-bottom: 60px;

            tr {
                td {
                    padding: 4px;

                    .delete {
                        color: #dd6161;
                        font-weight: bold;

                        &:hover {
                            background: #dd6161;
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }
            }
        }


        h1 {
            color: #000000;
            align-self: center;
        }

        .title {
            font-weight: bold;
            font-size: 22px;
        }
    }
</style>
