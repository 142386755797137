<template>
	<div class="wrap">
		<div class="grid-content">

			<el-menu v-if="userInfo" :default-active="activeIndex" class="el-menu" mode="horizontal"
				@select="handleSelect">
				<el-menu-item index="-1">欢迎你，{{userInfo.name}}</el-menu-item>
				<!-- <el-menu-item index="1">首页</el-menu-item> -->

				<el-menu-item :index="item.node" v-for="(item,index) of dataList"
					:key="index">{{item.stepName}}</el-menu-item>
				<!-- <el-menu-item index="1">论文选题</el-menu-item>
            <el-menu-item index="2">开题报告</el-menu-item>
            <el-menu-item index="3">初稿</el-menu-item>
            <el-menu-item index="4">复稿</el-menu-item>
            <el-menu-item index="5">终稿</el-menu-item>
            <el-menu-item index="6">定稿</el-menu-item> -->
				<!-- <div class="btn-wrap"> -->
					<el-popconfirm class="btn-wrap" title="确认退出吗？" @confirm="loginOut">
					 <el-button type="danger" slot="reference">退出登录</el-button>
					</el-popconfirm>
				<!-- </div> -->
			</el-menu>

		</div>
		
		
	</div>
</template>

<script>
	import {
		getStepList,getQianmingInfo
	} from '@/api/lunwen'
	export default {
		name: 'Header',
		data() {
			return {
				activeIndex: "-1",
				userInfo: {},
				dataList: [],
			}
		},
		watch: {
			activeIndex: function(node) {
				this.handleSelect(node)
			}
		},
		created() {
			this.getUserInfo()
		},

		methods: {
			// 退出登录
			loginOut() {
				// sessionStorage.removeItem("token")
				sessionStorage.removeItem("userInfo")
				sessionStorage.clear()
				this.$router.push({
					name: 'login'
				})
			},
			getUserInfo() {
				let userInfo = sessionStorage.getItem("userInfo")
				this.userInfo = JSON.parse(userInfo)
				if (this.userInfo) {
					this.getLunwenStepList()
				}
			},
			getLunwenStepList() {
				// let schoolName=this.userInfo.toSchool
				// let learnType=this.userInfo.learnType
				// getStepList({schoolName,learnType}).then(res=>{
				//     if(res.data.success){
				//       this.$nextTick(()=>{
				//          this.dataList=res.data.result
				//           this.activeIndex=sessionStorage.getItem("menuNode")
				//       })
				//
				//     }
				// })

				let sysStudentId = this.userInfo.sysStudentId
				getStepList({
					sysStudentId
				}).then(res => {
					if (res.data.success) {
						this.$nextTick(() => {
							this.dataList = res.data.result
							this.activeIndex = sessionStorage.getItem("menuNode")
						})

					}
				})
			},
			handleSelect(node) {
				let data = this.getData(node);
				let opening = false
				let time = ''

				this.activeIndex=node
				if (data == -1) {
					sessionStorage.setItem("subId", -1)
					sessionStorage.setItem("menuNode", -1)
				} else {
					sessionStorage.setItem("subId", data.id)
					sessionStorage.setItem("menuNode", data.node)
					let openTime = data.openTime
					let closeTime = data.closeTime
					// 论文模版
					let docFtl = data.docFtl
					if (!!openTime && !!closeTime) {
						let currentDate = Number(new Date())
						let openTimeData = new Date(openTime.replace(/-/g, '/')).getTime()
						let closeTimeData = new Date(closeTime.replace(/-/g, '/')).getTime()

						// console.log(openTimeData)
						// console.log(closeTimeData)
						// 如果在指定开放时间内容，则显示对应的内容
						if (currentDate >= openTimeData && currentDate <= closeTimeData) {
							opening = true
						}
						time = this.getTime(openTime) + " - " + this.getTime(closeTime)

					}
					sessionStorage.setItem("menuOpen", opening)
					sessionStorage.setItem("openTime", time)
					sessionStorage.setItem("docFtl", docFtl)
					sessionStorage.setItem("nodeData", JSON.stringify(data))
					this.$store.commit("setNodeData", JSON.stringify(data))
				}
				// 这里注释的是 不根据当前所属阶段显示了
				// this.$emit("select",opening && data.id===this.userInfo.currentStepSubId,time)
				this.$emit("select", opening, time)
			},
			getData(node) {
				let data = this.dataList.filter(a => node == a.node)
				if (data.length == 0) return -1
				else {
					return data[0];
				}
			},
			getTime(data) {
				let time = new Date(data.replace(/-/g, '/'))
				let year = time.getFullYear();
				let month = time.getMonth() + 1;
				let date = time.getDate();

				return year + '年' + month + '月' + date + '日'
			}
		}
	}
</script>

<style scoped lang="scss">
	.wrap {
		width: 100%;

		.grid-content {
			min-height: 60px;
			color: #ffffff;
			padding: 20px;
			text-align: right;


		}

		.el-menu {
			border-radius: 5px;
			display: flex;
			align-items: center;

			.btn-wrap {
				margin-left: auto;
				margin-right: 10px;
			}
		}

	}
</style>