<template>
    <div class="wrap">
        <!-- <el-button type="text" class="list" @click="showList">查看已提交选题</el-button> -->
        <div class="time" v-if="!!openTime">
                    <span>开放时间({{openTime}})</span>
                    <el-tag v-if="!opening" size="small" type="danger" style="margin-left: 10px">已关闭</el-tag>
                    <el-button size="mini" style="margin-left: 10px" icon="el-icon-time" @click="handleLog">审核记录</el-button>
        </div>

        <div class="div-submit" v-if="alreadySubmitData==null">
            <span class="title">论文选题</span>
            <el-input
                    maxlength="100"
                    show-word-limit
                    class="content"
                    v-model="content"
                    placeholder="请输入论文选题"
                    type="textarea"
            ></el-input>
            <el-tag disable-transitions type="warning" style="align-self:start;margin-top:20px">
                注：论文题目自拟，请输入本专业相关的论文题目。
            </el-tag>
            <div class="btn">
                <el-button v-if="opening" style="width:200px" type="primary" @click="handleOk">提交</el-button>
            </div>
        </div>
        <div v-else class="div-data">
            <h2>您的选题是</h2>
            <div class="title">{{alreadySubmitData.content}}</div>
            <el-tag class="tag" v-if="alreadySubmitData.checkFlag==0" type="warning">待检查</el-tag>
            <el-tag class="tag" v-else-if="alreadySubmitData.checkFlag==1" type="success">检查通过</el-tag>
            <div v-else-if="alreadySubmitData.checkFlag==2" class="reSubmit-tag">
                <el-tag class="tag" type="danger">检查驳回</el-tag>
                <div class="feedback-wrap" v-if="!!alreadySubmitData.feedback">
                    驳回原因：{{alreadySubmitData.feedback}}
                </div>
                <el-button v-if="opening" type="primary" @click="alreadySubmitData=null">重新提交</el-button>
            </div>
            <div class="time-wrap">
                <span>提交时间:</span>
                <span>{{alreadySubmitData.createTime}}</span>
            </div>
        </div>

        <el-dialog title="已提交选题" :visible="visible" width="500" @close="visible=false">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="content" label="论文选题" width="180"></el-table-column>
                <el-table-column prop="createTime" label="提交时间"></el-table-column>
                <el-table-column prop="passFlag" label="检查">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.passFlag=='1'" type="success">已通过</el-tag>
                        <el-tag v-if="scope.row.passFlag=='0'" type="info">待检查</el-tag>
                        <el-tag v-if="scope.row.passFlag=='2'" type="info">已驳回</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
            </el-table>
        </el-dialog>
        <lunwen-log-dialog ref="LunwenLogDialog"/>
    </div>
</template>

<script>
    import {saveLunwenContent, getCurrentLunwenContent} from "@/api/lunwen";
    import LunwenLogDialog from "../common/LunwenLogDialog";

    export default {
        name: "Xuanti",
        components: {LunwenLogDialog},
        props: {
            opening: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                content: "",
                visible: false,
                tableData: [
                    {
                        createTime: "2016-05-02",
                        content: "王小虎",
                        passFlag: "1"
                    },
                    {
                        createTime: "2016-05-04",
                        content: "王小虎",
                        passFlag: "2"
                    },
                    {
                        createTime: "2016-05-01",
                        content: "王小虎",
                        passFlag: "0"
                    },
                    {
                        createTime: "2016-05-03",
                        content: "王小虎",
                        passFlag: "1"
                    }
                ],
                alreadySubmitData: null,
                openTime: ''
            };
        },
        created() {
            this.getCheckedData();
            this.openTime = sessionStorage.getItem("openTime")

        },
        methods: {
            // 显示论文提交记录弹框
            handleLog(){
              this.$refs.LunwenLogDialog.show()
            },
            getCheckedData() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;

                getCurrentLunwenContent({studentId, stepSubId}).then(res => {
                    this.alreadySubmitData = res.data.result;
                });
            },
            showList() {
                this.visible = true;
            },
            handleOk() {
                let userInfo = sessionStorage.getItem("userInfo");
                let stepSubId = sessionStorage.getItem("subId");
                let userInfoObj = JSON.parse(userInfo);
                let studentId = userInfoObj.id;
                let content = this.content;
                let node = sessionStorage.getItem("menuNode")
                let form = {
                    studentId,
                    stepSubId,
                    content,
                    node
                };
                let _this = this

                if (!content) {
                    _this.$message({
                        type: 'warning',
                        message: '内容不能为空！'
                    });
                    return;
                }


                this.$confirm('是否提交?', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    saveLunwenContent(form).then(res => {
                        if (res.data.success) {
                            _this.$message({
                                type: 'success',
                                message: res.data.message
                            });
                        } else {
                            _this.$message({
                                type: 'info',
                                message: res.data.message
                            });
                        }

                        this.getCheckedData();
                    });

                }).catch(() => {

                });


            }
        }
    };
</script>

<style lang="scss" scoped>
    .wrap {
        .div-submit {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 500px;

            .list {
                align-self: start;
            }

            .title {
                margin-bottom: 20px;
                font-size: 30px;
            }

            .time {
                margin-bottom: 10px;

            }

            .content {
                font-size: 20px;
            }

            .btn {
                margin-top: 20px;
                text-align: center;
                display: flex;
                flex: 1;
                align-items: flex-end;
            }
        }

        .div-data {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 500px;

            h2 {
                color: #606266;
                align-self: start;
            }

            .title {
                text-align: center;
                font-size: 30px;
                color: #303133;
            }

            .tag {
                margin-top: 20px;
            }

            .reSubmit-tag {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;

                .tag {
                    margin-bottom: 10px;
                }

                .feedback-wrap {
                    padding: 20px;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    color: #dd6161;
                    text-decoration: underline;


                }
            }

            .time-wrap {
                flex: 1;
                align-self: end;
                display: flex;
                align-items: flex-end;

                span {
                    color: #909399;
                }
            }

        }
    }
</style>>

